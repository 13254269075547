import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const SyncOn: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M10.875 22C10.3227 22 9.875 21.5523 9.875 21C9.875 18.0495 11.0471 15.2198 13.1334 13.1334C15.2198 11.0471 18.0495 9.875 21 9.875C21.5523 9.875 22 10.3227 22 10.875C22 11.4273 21.5523 11.875 21 11.875C18.5799 11.875 16.2589 12.8364 14.5477 14.5477C12.8364 16.2589 11.875 18.5799 11.875 21C11.875 21.5523 11.4273 22 10.875 22Z" />
        <path d="M3 22C2.44772 22 2 21.5523 2 21C2 15.9609 4.00178 11.1282 7.56497 7.56497C11.1282 4.00178 15.9609 2 21 2C21.5523 2 22 2.44771 22 3C22 3.55228 21.5523 4 21 4C16.4913 4 12.1673 5.79106 8.97918 8.97918C5.79107 12.1673 4 16.4913 4 21C4 21.5523 3.55228 22 3 22Z" />
        <path d="M19.75 19.875C19.75 19.944 19.806 20 19.875 20C19.944 20 20 19.944 20 19.875C20 19.806 19.944 19.75 19.875 19.75C19.806 19.75 19.75 19.806 19.75 19.875ZM19.875 22C18.7014 22 17.75 21.0486 17.75 19.875C17.75 18.7014 18.7014 17.75 19.875 17.75C21.0486 17.75 22 18.7014 22 19.875C22 21.0486 21.0486 22 19.875 22Z" />
      </svg>
    );
  }
);

SyncOn.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SyncOn.displayName = 'SyncOn';

export default SyncOn;
