import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Read: Icon = forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <g clipPath="url(#clip0_4826_73163)">
          <path
            d="M8.92653 2.5551C9.10059 2.75698 9.09618 3.07933 8.91668 3.27509L3.31437 9.38511C3.13877 9.57663 2.85967 9.57663 2.68406 9.38511L0.137559 6.60783C-0.041935 6.41207 -0.0463443 6.08972 0.12771 5.88784C0.301765 5.68597 0.588372 5.68101 0.767866 5.87677L2.99922 8.31034L8.28638 2.54403C8.46587 2.34827 8.75248 2.35323 8.92653 2.5551Z"
            fill={color}
          />
          <path
            d="M11.872 2.5551C12.0461 2.75698 12.0417 3.07933 11.8622 3.27509L6.2599 9.38511C6.0843 9.57663 5.8052 9.57663 5.6296 9.38511L5.21909 8.97582C5.0396 8.78006 5.03519 8.45771 5.20924 8.25583C5.3833 8.05395 5.66991 8.04899 5.8494 8.24475L5.94475 8.31034L11.2319 2.54403C11.4114 2.34827 11.698 2.35323 11.872 2.5551Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_4826_73163">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Read.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Read.displayName = 'Read';

export default Read;
