import React from 'react';

import { StyledIconButton } from './IconButton.styles';

import { IconWrapper } from '../Button';
import { IconButtonProps } from './types';

export default function IconButton({
  children,
  ...otherProps
}: IconButtonProps) {
  return (
    <StyledIconButton {...otherProps}>
      <IconWrapper>{children}</IconWrapper>
    </StyledIconButton>
  );
}
