import React from 'react';

import CreateBusinessForm from '@shared/form/CreateBusinessForm';
import FeedbackForm from '@shared/form/FeedbackForm';
import Modal from '@shared/Modal';
import ConfirmationModal from '@shared/modals/ConfirmationModal';
import DepositModal from '@shared/modals/DepositModal';
import SubscriptionWallModal from '@shared/modals/SubscriptionWallModal';
import WithdrawFromCreditModal from '@shared/modals/WithdrawFromCreditModal';

function GlobalModals() {
  return (
    <>
      <Modal component={DepositModal} />
      <Modal component={CreateBusinessForm} />
      <Modal component={ConfirmationModal} />
      <Modal component={WithdrawFromCreditModal} />
      <Modal component={FeedbackForm} />
      <Modal component={SubscriptionWallModal} closable={false} />
    </>
  );
}

export default GlobalModals;
