import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Bulb: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <g clipPath="url(#clip0_1169_2861)">
          <path
            d="M3.91199 10.3887C3.20889 9.73924 2.67258 8.92995 2.34847 8.02933C2.02436 7.12871 1.92198 6.16327 2.04999 5.2147C2.17657 4.25288 2.53448 3.33609 3.09305 2.54291C3.65162 1.74973 4.39419 1.10383 5.25711 0.660548C6.12003 0.21727 7.07757 -0.0101573 8.04766 -0.00224163C9.01774 0.00567404 9.97145 0.248697 10.827 0.705997C11.6826 1.1633 12.4145 1.82124 12.9601 2.62342C13.5056 3.42561 13.8485 4.34812 13.9594 5.31188C14.0703 6.27564 13.9458 7.25191 13.5966 8.15702C13.2475 9.06213 12.684 9.86908 11.9547 10.5087C11.485 10.9125 11.1203 11.4243 10.892 12H8.66666V7.2107C9.05527 7.07331 9.39193 6.81921 9.6306 6.48317C9.86927 6.14712 9.99828 5.74554 9.99999 5.33337C9.99999 5.15656 9.92976 4.98699 9.80473 4.86196C9.67971 4.73694 9.51014 4.6667 9.33333 4.6667C9.15652 4.6667 8.98695 4.73694 8.86192 4.86196C8.7369 4.98699 8.66666 5.15656 8.66666 5.33337C8.66666 5.51018 8.59642 5.67975 8.4714 5.80477C8.34637 5.9298 8.1768 6.00003 7.99999 6.00003C7.82318 6.00003 7.65361 5.9298 7.52859 5.80477C7.40356 5.67975 7.33333 5.51018 7.33333 5.33337C7.33333 5.15656 7.26309 4.98699 7.13806 4.86196C7.01304 4.73694 6.84347 4.6667 6.66666 4.6667C6.48985 4.6667 6.32028 4.73694 6.19526 4.86196C6.07023 4.98699 5.99999 5.15656 5.99999 5.33337C6.0017 5.74554 6.13072 6.14712 6.36938 6.48317C6.60805 6.81921 6.94472 7.07331 7.33333 7.2107V12H5.04199C4.78712 11.3871 4.40147 10.8371 3.91199 10.3887ZM5.33333 13.3334V13.54C5.33403 14.1923 5.59344 14.8176 6.05462 15.2787C6.51581 15.7399 7.14111 15.9993 7.79333 16H8.20666C8.85888 15.9993 9.48418 15.7399 9.94536 15.2787C10.4065 14.8176 10.666 14.1923 10.6667 13.54V13.3334H5.33333Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_1169_2861">
            <rect width={size} height={size} fill={color} />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Bulb.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Bulb.displayName = 'Bulb';

export default Bulb;
