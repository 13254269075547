import React from 'react';

import { createTheme } from '@mui/material/styles';

import MUIContainer from '@mui/material/Container';

const theme = createTheme();

function Container(props: any) {
  return <MUIContainer theme={theme} {...props} />;
}

export default Container;
