import React from 'react';
import { CloseButtonProps } from 'react-toastify';

import { X as Close } from '@ui/Icon/outline';
import { StyledContainer } from '@ui/Toast/Toast.styles';

import { ContentProps } from './types';

function CloseButton({ closeToast }: CloseButtonProps) {
  return (
    <button className="Toastify__close-button" onClick={closeToast}>
      <Close size={20} />
    </button>
  );
}

function Content({ message, description }: ContentProps) {
  if (message && description) {
    return (
      <p id="toast-description">
        <strong>{message}</strong>
        &nbsp;
        <span className="Toastify__toast-body-subtitle">{description}</span>
      </p>
    );
  }

  return <p id="toast-description">{message}</p>;
}
function Container() {
  return (
    <StyledContainer
      autoClose={5000}
      draggable={false}
      closeOnClick={true}
      closeButton={CloseButton}
      enableMultiContainer
      containerId="toast-container"
    />
  );
}

export { Container, Content, CloseButton };
