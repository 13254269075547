import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ArrowLeftLine: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M18 12C18 12.5523 17.5523 13 17 13L3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11L17 11C17.5523 11 18 11.4477 18 12Z" />
        <path d="M10.7071 19.7071C10.3166 20.0976 9.68342 20.0976 9.29289 19.7071L2.29289 12.7071C1.90237 12.3166 1.90237 11.6834 2.29289 11.2929L9.29289 4.29289C9.68342 3.90237 10.3166 3.90237 10.7071 4.29289C11.0976 4.68342 11.0976 5.31658 10.7071 5.70711L4.41421 12L10.7071 18.2929C11.0976 18.6834 11.0976 19.3166 10.7071 19.7071Z" />
        <path d="M22 4C22.5523 4 23 4.44772 23 5L23 19C23 19.5523 22.5523 20 22 20C21.4477 20 21 19.5523 21 19L21 5C21 4.44772 21.4477 4 22 4Z" />
      </svg>
    );
  }
);

ArrowLeftLine.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowLeftLine.displayName = 'ArrowLeftLine';

export default ArrowLeftLine;
