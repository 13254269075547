import React, { forwardRef } from 'react';

import { StyledAvatar } from './Avatar.styles';

import { User } from '@ui/Icon/outline';

import type { AvatarProps } from './types';

function Avatar(props: AvatarProps, ref: any) {
  const { name, initials, src, ...otherProps } = props;
  const letters =
    initials ||
    name
      ?.split(' ', 2)
      .map((word) => word[0])
      .join('')
      .toUpperCase();

  if (src) {
    return <StyledAvatar ref={ref} src={src} {...otherProps} />;
  }

  return (
    <StyledAvatar ref={ref} {...otherProps}>
      {letters || <User />}
    </StyledAvatar>
  );
}

export default forwardRef(Avatar);
