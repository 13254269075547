import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Error: Icon = forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.85208 1.5557C7.03832 0.369455 8.9616 0.369455 10.1478 1.5557L14.4436 5.85146C15.6299 7.03771 15.6299 8.96099 14.4436 10.1472L10.1478 14.443C8.9616 15.6292 7.03832 15.6292 5.85208 14.443L1.55631 10.1472C0.370065 8.96099 0.370065 7.03771 1.55631 5.85146L5.85208 1.5557ZM7.99996 4.33268C8.36815 4.33268 8.66663 4.63116 8.66663 4.99935V8.33268C8.66663 8.70087 8.36815 8.99935 7.99996 8.99935C7.63177 8.99935 7.33329 8.70087 7.33329 8.33268V4.99935C7.33329 4.63116 7.63177 4.33268 7.99996 4.33268ZM7.99996 11.3327C8.36815 11.3327 8.66663 11.0342 8.66663 10.666C8.66663 10.2978 8.36815 9.99935 7.99996 9.99935C7.63177 9.99935 7.33329 10.2978 7.33329 10.666C7.33329 11.0342 7.63177 11.3327 7.99996 11.3327Z"
          fill={color}
        />
      </svg>
    );
  }
);

Error.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Error.displayName = 'Error';

export default Error;
