import React, { ForwardedRef, forwardRef } from 'react';

import CurrencyInput from '@ui/CurrencyInput';
import FormControl from '@ui/FormControl';
import Input from '@ui/Input';
import PasswordInput from '@ui/PasswordInput';
import PhoneInput from '@ui/PhoneInput';
import Select from '@ui/Select';

import { TextFieldProps } from './types';

function TextField(
  props: TextFieldProps,
  ref: ForwardedRef<HTMLInputElement | HTMLSelectElement>
) {
  const {
    error,
    label,
    type,
    required,
    id,
    helperText,
    fullWidth,
    hiddenLabel,
  } = props;

  let input;
  switch (type) {
    case 'password':
      input = (
        <PasswordInput {...props} ref={ref as ForwardedRef<HTMLInputElement>} />
      );
      break;
    case 'currency':
      input = (
        <CurrencyInput {...props} ref={ref as ForwardedRef<HTMLInputElement>} />
      );
      break;
    case 'tel':
      input = (
        <PhoneInput {...props} ref={ref as ForwardedRef<HTMLInputElement>} />
      );
      break;
    case 'select':
      input = (
        <Select {...props} ref={ref as ForwardedRef<HTMLSelectElement>} />
      );
      break;
    default:
      input = <Input {...props} ref={ref as ForwardedRef<HTMLInputElement>} />;
      break;
  }
  return (
    <FormControl
      fullWidth={fullWidth}
      id={id}
      error={error}
      label={label}
      required={required}
      helperText={helperText}
      hiddenLabel={hiddenLabel}
    >
      {input}
    </FormControl>
  );
}

export default forwardRef(TextField);
