import { graphql } from 'msw';

export const addSeenFeatureAnnouncementHandler = graphql.mutation(
  'AddSeenFeature',
  (req, res, ctx) => {
    const { featureName } = req.variables;

    return res(
      ctx.data({
        addSeenFeatureAnnouncement: {
          user: {
            id: '47301',
            seenFeatureAnnouncements: [featureName],
            __typename: 'User',
          },
        },
      })
    );
  }
);
