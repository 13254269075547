import styled, { css } from 'styled-components';

import { AvatarProps } from './types';

export const StyledAvatar = styled.div<AvatarProps>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor
      ? `${backgroundColor}${theme.opacityHex[10]}`
      : theme.colors.primary[50]};
  background-image: ${({ src }) => src && `url('${src}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ theme }) => theme.spacing[10]};
  width: ${({ theme }) => theme.spacing[10]};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.neutral[500]};
  border-radius: ${({ theme, squared }) =>
    squared ? theme.spacing[2.5] : '50%'};
  cursor: ${({ onClick }) => onClick && 'pointer'};
  svg {
    height: 50%;
    width: 50%;
  }

  img {
    border-radius: ${({ theme, squared }) => squared && theme.spacing[4]};
    object-position: center;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${({ size }) =>
    size === 'sm' &&
    css`
      height: ${({ theme }) => theme.spacing[8]};
      width: ${({ theme }) => theme.spacing[8]};
    `}

  ${({ size }) =>
    size === 'lg' &&
    css`
      height: ${({ theme }) => theme.spacing[18]};
      width: ${({ theme }) => theme.spacing[18]};
      font-size: ${({ theme }) => theme.fontSize.lg};
    `}
`;
