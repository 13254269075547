import * as Sentry from '@sentry/react';

export function log(error: Error) {
  if (process.env.NODE_ENV === 'production') {
    try {
      Sentry.captureException(error);
    } catch (error: any) {
      console.log('Report Error:', error);
    }
  } else {
    console.log('Report Error:', error);
  }
}

export function logMessage(message: string) {
  if (process.env.NODE_ENV === 'production') {
    try {
      Sentry.captureMessage(message);
    } catch (message: any) {
      console.log('Report Error:', message);
    }
  } else {
    console.log('Report Error:', message);
  }
}

export function resetUser() {
  if (process.env.NODE_ENV === 'production') {
    try {
      Sentry.configureScope((scope) => scope.setUser(null));
    } catch (error: any) {
      console.log('Report Error:', error);
    }
  }
}
