import { graphql } from 'msw';

export const paymentBreakdownHandler = graphql.query(
  'CreditCardChargePaymentBreakdown',
  (req, res, ctx) => {
    return res(
      ctx.data({
        __typename: 'Query',
        creditCardChargePaymentBreakdown: {
          amountToBeChargedCents: 163_342_25,
          amountCents: 160_000_00,
          userTotalAmountCentsWithCommission: 160_000_00,
          clientCommissionCents: 3_342_25,
          userCommissionCents: 3_342_25,
          creditCardConfig: {
            __typename: 'CreditCardConfig',
            maxTransactionAmountLimitCents: 200_000_00,
            totalCommissionRate: 0.5,
          },
        },
      })
    );
  }
);
