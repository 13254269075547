import React from 'react';

import Checkbox from '@ui/Checkbox';

import TableHeaderCell from '../TableHeaderCell';
import TableHeader from './TableHeader';
import type { SelectableTableHeaderProps } from './types';

export default function SelectableTableHeader(
  props: SelectableTableHeaderProps
) {
  const {
    children,
    selectable,
    cancelable,
    selected,
    onSelect,
    tablePosition = 'start',
    ...otherProps
  } = props;

  const handleClickableCell = (event: React.SyntheticEvent) => {
    event.stopPropagation();
  };

  return (
    <TableHeader {...otherProps}>
      {selectable && tablePosition === 'start' && (
        <TableHeaderCell onClick={handleClickableCell}>
          <Checkbox checked={selected} onClick={onSelect} buffer />
        </TableHeaderCell>
      )}

      {children}
      {cancelable && (
        <TableHeaderCell>
          <span></span>
        </TableHeaderCell>
      )}

      {selectable && tablePosition === 'end' && !cancelable && (
        <TableHeaderCell onClick={handleClickableCell}>
          <Checkbox variant="lg" checked={selected} onClick={onSelect} buffer />
        </TableHeaderCell>
      )}
    </TableHeader>
  );
}
