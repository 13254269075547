import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Transaction: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M21 10C21 9.44772 20.5523 9 20 9L4 9C3.44771 9 3 9.44772 3 10C3 10.5523 3.44771 11 4 11L20 11C20.5523 11 21 10.5523 21 10Z" />
        <path d="M20.7071 10.7071C20.3166 11.0976 19.6834 11.0976 19.2929 10.7071L12.2929 3.70711C11.9024 3.31658 11.9024 2.68342 12.2929 2.29289C12.6834 1.90237 13.3166 1.90237 13.7071 2.29289L20.7071 9.29289C21.0976 9.68342 21.0976 10.3166 20.7071 10.7071Z" />
        <path d="M3 14C3 14.5523 3.44772 15 4 15L20 15C20.5523 15 21 14.5523 21 14C21 13.4477 20.5523 13 20 13L4 13C3.44772 13 3 13.4477 3 14Z" />
        <path d="M3.29289 13.2929C3.68342 12.9024 4.31658 12.9024 4.70711 13.2929L11.7071 20.2929C12.0976 20.6834 12.0976 21.3166 11.7071 21.7071C11.3166 22.0976 10.6834 22.0976 10.2929 21.7071L3.29289 14.7071C2.90237 14.3166 2.90237 13.6834 3.29289 13.2929Z" />
      </svg>
    );
  }
);

Transaction.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Transaction.displayName = 'Transaction';

export default Transaction;
