import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { ModalCard } from './Modal.styles';

import Backdrop from '@ui/Backdrop';
import { X as Close } from '@ui/Icon/outline';
import IconButton from '@ui/IconButton';

import type { ModalProps } from './types';

export default function Modal({
  children,
  open,
  container,
  CardProps,
  closeType = 'transparent',
  onClose,
  ...otherProps
}: ModalProps) {
  if (typeof document === 'undefined') {
    return null;
  }

  const containerElement = container && document.querySelector(container);
  const portalContainer = containerElement || document.body;

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onClose) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <Backdrop open={open}>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="modal"
        appear
        mountOnEnter
        unmountOnExit
      >
        <ModalCard {...otherProps} {...CardProps}>
          {children}
          {onClose && (
            <div className="close-button">
              <IconButton
                onClick={handleClose}
                variant={closeType}
                color="neutral"
                rounded
              >
                <Close />
              </IconButton>
            </div>
          )}
        </ModalCard>
      </CSSTransition>
    </Backdrop>,
    portalContainer
  );
}
