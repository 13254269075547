import styled, { ColorDict } from 'styled-components';

interface OptionProps {
  $color: keyof ColorDict;
}

export const Form = styled.form`
  width: ${({ theme }) => theme.spacing[112]};
`;

export const Emoji = styled.span`
  font-size: ${({ theme }) => theme.fontSize['3xl']};
  line-height: 1;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: ${({ theme }) => theme.spacing[20]};
  height: ${({ theme }) => theme.spacing[24]};
  padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[5]};
  transition: background-color 200ms ease-in-out;
  border-radius: ${({ theme }) => theme.spacing[2]};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors.neutral[300]};
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const Input = styled.input`
  position: absolute;
  top: ${({ theme }) => theme.spacing[6]};
  left: ${({ theme }) => theme.spacing[9]};
  z-index: -1;
`;

export const Option = styled.div<OptionProps>`
  position: relative;
  color: ${({ theme }) => theme.colors.neutral[500]};

  ${Input}:checked + ${Label} {
    background-color: ${({ theme, $color }) =>
      theme.colors[$color][$color === 'neutral' ? 100 : 50]};

    color: ${({ theme, $color }) =>
      theme.colors[$color][$color === 'neutral' ? 600 : 500]};
  }
`;
