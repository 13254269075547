import React from 'react';

import { StyledIcon } from './Icon.styles';

import * as outlined from './outline';
import * as solid from './solid';
import type { IconProps, OutlinedIconName, SolidIconName } from './types';

function Icon({
  variant = 'outlined',
  name,
  color,
  colorStep,
  onClick,
  ...otherProps
}: IconProps) {
  const FeatherIcon: any =
    variant === 'solid'
      ? solid[name as SolidIconName]
      : outlined[name as OutlinedIconName];
  return (
    <StyledIcon
      {...otherProps}
      as={FeatherIcon}
      $color={color}
      $colorStep={colorStep}
      onClick={onClick}
    />
  );
}

export default Icon;
