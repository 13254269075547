import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const FilePlus: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <g clipPath="url(#clip0_5051_44012)">
          <path
            d="M11.666 5.83333V0.383333C12.4369 0.674446 13.1371 1.12609 13.7202 1.70833L16.6235 4.61333C17.2064 5.19573 17.6584 5.89575 17.9493 6.66667H12.4993C12.2783 6.66667 12.0664 6.57887 11.9101 6.42259C11.7538 6.26631 11.666 6.05435 11.666 5.83333ZM18.3327 8.7375V15.8333C18.3314 16.938 17.8919 17.997 17.1108 18.7782C16.3297 19.5593 15.2707 19.9987 14.166 20H5.83268C4.72802 19.9987 3.66898 19.5593 2.88787 18.7782C2.10675 17.997 1.66734 16.938 1.66602 15.8333V4.16667C1.66734 3.062 2.10675 2.00296 2.88787 1.22185C3.66898 0.440735 4.72802 0.00132321 5.83268 0L9.59518 0C9.73102 0 9.86518 0.0108333 9.99935 0.02V5.83333C9.99935 6.49637 10.2627 7.13226 10.7316 7.6011C11.2004 8.06994 11.8363 8.33333 12.4993 8.33333H18.3127C18.3218 8.4675 18.3327 8.60167 18.3327 8.7375ZM13.3327 14.1667C13.3327 13.9457 13.2449 13.7337 13.0886 13.5774C12.9323 13.4211 12.7204 13.3333 12.4993 13.3333H10.8327V11.6667C10.8327 11.4457 10.7449 11.2337 10.5886 11.0774C10.4323 10.9211 10.2204 10.8333 9.99935 10.8333C9.77834 10.8333 9.56637 10.9211 9.41009 11.0774C9.25381 11.2337 9.16602 11.4457 9.16602 11.6667V13.3333H7.49935C7.27834 13.3333 7.06637 13.4211 6.91009 13.5774C6.75381 13.7337 6.66602 13.9457 6.66602 14.1667C6.66602 14.3877 6.75381 14.5996 6.91009 14.7559C7.06637 14.9122 7.27834 15 7.49935 15H9.16602V16.6667C9.16602 16.8877 9.25381 17.0996 9.41009 17.2559C9.56637 17.4122 9.77834 17.5 9.99935 17.5C10.2204 17.5 10.4323 17.4122 10.5886 17.2559C10.7449 17.0996 10.8327 16.8877 10.8327 16.6667V15H12.4993C12.7204 15 12.9323 14.9122 13.0886 14.7559C13.2449 14.5996 13.3327 14.3877 13.3327 14.1667Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_5051_44012">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

FilePlus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FilePlus.displayName = 'FilePlus';

export default FilePlus;
