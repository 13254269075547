import styled from 'styled-components';

import { TableHeaderCellProps } from './types';

export const StyledTableHeaderCell = styled.th<TableHeaderCellProps>`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.neutral[500]};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: inherit;
  position: relative;
  .th-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  }

  &::after {
    content: '';
    position: absolute;
    width: ${({ theme }) => theme.spacing.px};
    height: ${({ theme }) => theme.spacing[5]};
    background-color: ${({ theme }) => theme.colors.neutral[900]};
    right: 0;
    transform: translateY(-50%);
    opacity: ${({ theme }) => theme.opacity[10]};
    top: 50%;
  }
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.spacing[2]};
  }
  &:last-child {
    border-top-right-radius: ${({ theme }) => theme.spacing[2]};
    &::after {
      content: none;
    }
  }
`;
