import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Suitcase: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
        {...rest}
        stroke="none"
        strokeWidth="0"
      >
        <g clipPath="url(#clip0_3888_64159)">
          <path
            d="M15.8333 3.33333H14.9167C14.7233 2.39284 14.2115 1.54779 13.4677 0.940598C12.7239 0.333408 11.7935 0.0012121 10.8333 0L9.16667 0C8.2065 0.0012121 7.2761 0.333408 6.53229 0.940598C5.78848 1.54779 5.27675 2.39284 5.08333 3.33333H4.16667C3.062 3.33466 2.00296 3.77407 1.22185 4.55518C0.440735 5.3363 0.00132321 6.39534 0 7.5L0 10H20V7.5C19.9987 6.39534 19.5593 5.3363 18.7782 4.55518C17.997 3.77407 16.938 3.33466 15.8333 3.33333ZM6.82 3.33333C6.99174 2.84758 7.30936 2.42674 7.72942 2.12841C8.14948 1.83007 8.65145 1.6688 9.16667 1.66667H10.8333C11.3485 1.6688 11.8505 1.83007 12.2706 2.12841C12.6906 2.42674 13.0083 2.84758 13.18 3.33333H6.82Z"
            fill={color}
          />
          <path
            d="M10.8333 12.5003C10.8333 12.7213 10.7455 12.9333 10.5893 13.0896C10.433 13.2459 10.221 13.3337 10 13.3337C9.77898 13.3337 9.56702 13.2459 9.41074 13.0896C9.25446 12.9333 9.16666 12.7213 9.16666 12.5003V11.667H0V15.8337C0.00132321 16.9383 0.440735 17.9974 1.22185 18.7785C2.00296 19.5596 3.062 19.999 4.16667 20.0003H15.8333C16.938 19.999 17.997 19.5596 18.7781 18.7785C19.5593 17.9974 19.9987 16.9383 20 15.8337V11.667H10.8333V12.5003Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_3888_64159">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Suitcase.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Suitcase.displayName = 'Suitcase';

export default Suitcase;
