import { graphql } from 'msw';

export const collectedReceivablesTableHandler = graphql.query(
  'CollectedReceivablesTable',
  (req, res, ctx) =>
    res(
      ctx.data({
        receivables: {
          edges: [
            {
              node: {
                id: 1,
                client: {
                  business: {
                    name: 'Socoda SA de CV',
                  },
                },
                invoice: {
                  id: '09387423',
                },
                folio: '02312',
                emittedAt: '2022-03-12T00:00:00',
                collectedAt: '2022-05-10T00:00:00',
                pendingTotalCents: 32492,
                totalCents: 32492,
                status: 'pending',
                __typename: 'Receivable',
              },
            },
            {
              node: {
                id: 2,
                client: {
                  business: {
                    name: 'Higo SA de CV',
                  },
                },
                invoice: {
                  id: '09387422',
                },
                folio: '02312',
                emittedAt: '2022-03-12T00:00:00',
                collectedAt: '2022-05-10T00:00:00',
                pendingTotalCents: 32492,
                totalCents: 32492,
                status: 'pending',
                __typename: 'Receivable',
              },
            },
            {
              node: {
                id: 3,
                client: {
                  business: {
                    name: 'Concentradora SA de CV',
                  },
                },
                invoice: {
                  id: '09387421',
                },
                folio: '02312',
                emittedAt: '2022-03-12T00:00:00',
                collectedAt: '2022-05-10T00:00:00',
                pendingTotalCents: 32492,
                totalCents: 32492,
                status: 'pending',
                __typename: 'Receivable',
              },
            },
          ],
          pageInfo: {
            startCursor: 'mw',
            endCursor: 'MQ',
            hasNextPage: false,
            hasPreviousPage: false,
          },
          totalCount: 1,
        },
      })
    )
);
