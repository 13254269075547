import styled, { css } from 'styled-components';

import Button from '@ui/Button';
import { IconWrapper } from '@ui/Button/Button.styles';
import { MenuItem } from '@ui/Menu';

type DropdownItemProps = {
  selected?: boolean;
  dataDropdownItem?: boolean;
  disabled?: boolean;
};

type DropdownWrapperProps = {
  fullWidth?: boolean;
};

type DropdownButtonProps = {
  open?: boolean;
  fullWidth?: boolean;
  error?: boolean;
};

export const DropdownWrapper = styled.div<DropdownWrapperProps>`
  display: inline-block;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  min-width: 250px;
`;

export const DropdownItem = styled(MenuItem)<DropdownItemProps>`
  font-weight: ${({ selected, theme }) =>
    selected ? theme.fontWeight.bold : theme.fontWeight.light};
  margin: ${({ theme }) => theme.spacing[2]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  background-color: ${({ theme, disabled }) =>
    disabled && theme.colors.neutral[100]};
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[50]};
  }
`;

export const DropdownButton = styled(Button)<DropdownButtonProps>`
  width: ${({ theme, fullWidth }) => (fullWidth ? '100%' : theme.spacing[52])};
  position: relative;
  height: auto;
  min-height: ${({ theme }) => theme.spacing[11]};
  text-align: left;
  padding: 0 ${({ theme }) => theme.spacing[4]};

  ${({ error, theme }) =>
    error &&
    css`
      box-shadow: inset 0 0 0 1px ${theme.colors.danger[500]};
    `}

  ${IconWrapper} {
    height: ${({ theme }) => theme.spacing[6]};
    width: ${({ theme }) => theme.spacing[6]};
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  ${DropdownItem} {
    height: auto;
    padding: 0;
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    &:after {
      content: none;
    }
  }

  .button-body {
    justify-content: flex-start;
  }

  .button-label {
    flex-grow: 1;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  border: none;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 1px #c3c3c7;
  padding: 0 ${({ theme }) => theme.spacing[4]};
  min-height: ${({ theme }) => theme.spacing[11]};
`;

export const DropdownInput = styled.input<DropdownButtonProps>`
  width: ${({ theme, fullWidth }) => (fullWidth ? '100%' : theme.spacing[52])};
  position: relative;
  height: auto;
  text-align: left;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: Apercu, sans-serif;
  background: none;
  color: #101121;
  outline: none;

  ${({ error, theme }) =>
    error &&
    css`
      box-shadow: inset 0 0 0 1px ${theme.colors.danger[500]};
    `}

  ${IconWrapper} {
    height: ${({ theme }) => theme.spacing[6]};
    width: ${({ theme }) => theme.spacing[6]};
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  ${DropdownItem} {
    height: auto;
    padding: 0;
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    &:after {
      content: none;
    }
  }

  .button-body {
    justify-content: flex-start;
  }

  .button-label {
    flex-grow: 1;
  }

  ::placeholder {
    font-weight: 500;
    color: #abacb1;
    margin: 0;
    font-size: 0.875rem;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
