import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Factoring: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        {...rest}
      >
        <path
          d="M19.871 15.6918C18.8116 17.6816 17.1098 19.2561 15.0415 20.16C12.9732 21.0639 10.6596 21.2444 8.47569 20.672C6.29178 20.0997 4.36557 18.8081 3.00928 17.0067C1.65298 15.2053 0.9461 12.9996 1.00321 10.7471C1.06032 8.49462 1.87806 6.32741 3.32391 4.59672C4.76975 2.86604 6.75895 1.67334 8.96907 1.21193C11.1792 0.750522 13.4807 1.04746 15.5005 2.0546C17.5204 3.06175 19.1402 4.72008 20.0974 6.7608"
          stroke={color}
          strokeWidth="1.5"
          fill="white"
        />
        <path
          d="M10.957 5.26031V17.1522"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.6656 7.42389H9.60294C9.10011 7.42389 8.61788 7.62321 8.26233 7.97801C7.90678 8.33281 7.70703 8.81402 7.70703 9.31578C7.70703 9.81754 7.90678 10.2988 8.26233 10.6535C8.61788 11.0083 9.10011 11.2077 9.60294 11.2077H12.3114C12.8142 11.2077 13.2964 11.407 13.652 11.7618C14.0075 12.1166 14.2073 12.5978 14.2073 13.0996C14.2073 13.6013 14.0075 14.0825 13.652 14.4373C13.2964 14.7921 12.8142 14.9915 12.3114 14.9915H7.70703"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.5829 16.6755C24.5745 16.6755 26.9998 14.2554 26.9998 11.2701C26.9998 8.28477 24.5745 5.86469 21.5829 5.86469C18.5912 5.86469 16.166 8.28477 16.166 11.2701C16.166 14.2554 18.5912 16.6755 21.5829 16.6755Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="blue"
        />
        <path
          d="M21.5828 9.10785L19.416 11.27L21.5828 13.4322"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.7495 11.2691H19.416"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

Factoring.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Factoring.displayName = 'Factoring';

export default Factoring;
