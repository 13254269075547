import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './ConfirmationModal.styles';

import { Button, Text } from '@ui';
import { ButtonColor } from '@ui/Button/types';
import useModal from '@src/hooks/useModal';
import { FlexColumnContainer, FlexContainer, Spacer } from '@shared/layouts';

import Error from './img/error.svg';

type ConfirmationModalProps = {
  title: string;
  text: string;
  onConfirm: (payload?: any) => void;
  icon?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  onCancel?: (payload?: any) => void;
  loading?: boolean;
  compact?: boolean;
  submitButtonColor?: ButtonColor;
};

export function ConfirmationModal({
  title,
  text,
  confirmText,
  cancelText,
  icon,
  onConfirm,
  onCancel,
  loading,
  compact,
  submitButtonColor = 'danger',
}: ConfirmationModalProps) {
  const intl = useIntl();
  const { close } = useModal(ConfirmationModal);

  const _onClose = () => {
    close();
    if (onCancel) {
      onCancel();
    }
  };

  const _onConfirm = () => {
    close();
    onConfirm();
  };

  if (compact) {
    return (
      <S.DangerContainer>
        <S.Header as="h5">{title}</S.Header>
        <Spacer margin="4" />
        <Text color="neutral" colorStep="600">
          {text}
        </Text>
        <Spacer margin="6" />
        <FlexContainer justifyContent="flex-end">
          <Button
            onClick={_onClose}
            disabled={loading}
            variant="outlined"
            color="neutral"
          >
            {cancelText ||
              intl.formatMessage({
                defaultMessage: 'Cancelar',
                id: 'nZLeaQ',
              })}
          </Button>
          <Spacer margin="2" direction="horizontal" />
          <Button
            color={submitButtonColor}
            onClick={_onConfirm}
            loading={loading}
          >
            {confirmText ||
              intl.formatMessage({
                defaultMessage: 'Sí',
                id: '0HwljF',
              })}
          </Button>
        </FlexContainer>
      </S.DangerContainer>
    );
  }

  return (
    <S.Container>
      {icon || <Error />}
      <Spacer margin="5" />
      <S.Header as="h5">{title}</S.Header>
      <Spacer margin="3" />
      <Text color="neutral" colorStep="600">
        {text}
      </Text>
      <Spacer margin="6" />
      <FlexColumnContainer>
        <Button onClick={_onConfirm} loading={loading}>
          {confirmText ||
            intl.formatMessage({
              defaultMessage: 'Sí',
              id: '0HwljF',
            })}
        </Button>
        <Spacer margin="2" />
        <Button variant="transparent" onClick={_onClose} disabled={loading}>
          {cancelText ||
            intl.formatMessage({
              defaultMessage: 'No',
              id: 'oUWADl',
            })}
        </Button>
      </FlexColumnContainer>
    </S.Container>
  );
}

ConfirmationModal.displayName = 'ConfirmationModal';

export default ConfirmationModal;
