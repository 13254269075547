/* eslint-disable no-var */
import posthog from 'posthog-js';

import * as Sentry from '@sentry/react';
import { Events } from '@src/lib/analytics/events';

import { CustomWindow } from '../../../custom.window';

const { NEXT_PUBLIC_POSTHOG_API_KEY } = process.env;

declare var window: CustomWindow;

type AnalyticsUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  businessId?: string;
  businessName?: string;
};

export const isPosthogEnabled = !!NEXT_PUBLIC_POSTHOG_API_KEY;

export function setUser(user?: AnalyticsUser) {
  if (user) {
    const isTeam = /higo.io\s*$/.test(user.email);

    const userAttributes = {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      company_id: user.businessId,
      company_name: user.businessName,
      is_team: isTeam,
      version: 'beta',
    };

    if (typeof window !== 'undefined' && window.analytics) {
      window.analytics.identify(user.id, userAttributes);
    }
    if (isPosthogEnabled) {
      posthog.identify(user.id, userAttributes);
      posthog.group('company', `id:${user.businessId}`, {
        company_name: user.businessName,
        id: user.businessId,
      });
    }

    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: `${user.firstName} ${user.lastName}`,
      businessId: user.businessId,
      businessName: user.businessName,
    });
  }
}

export function trackEvent<T extends keyof Events>(
  ...args: Events[T] extends undefined
    ? [event: T, properties?: Events[T]]
    : [event: T, properties: Events[T]]
) {
  const [event, properties] = args;
  if (typeof window !== 'undefined' && window.analytics) {
    window.analytics.track(event, properties || {});
  }
  if (isPosthogEnabled) {
    posthog.capture(event, properties || {});
  }
}

export function trackGroup(
  groupId: string,
  properties?: { [key: string]: any }
) {
  if (typeof window !== 'undefined' && window.analytics) {
    window.analytics.group(groupId, properties || {});
  }
}

export function trackPageView(
  name: string,
  category?: string,
  properties?: { [key: string]: any }
) {
  if (typeof window !== 'undefined' && window.analytics) {
    if (category && properties) {
      window.analytics.page(category, name, properties);
    } else if (category) {
      window.analytics.page(category, name);
    } else {
      window.analytics.page(name);
    }
  }
}
