import { gql } from '@apollo/client';

const SHOW_STP_APPLICANT_KYB = gql`
  query showStpApplicantKyb($taxpayerIdentifier: String!) {
    showStpApplicantKyb(taxpayerIdentifier: $taxpayerIdentifier) {
      approvalStatus
      uuid
      approvedAt
      clabeOld
    }
  }
`;

export interface ShowSTPApplicantKYBInput {
  taxpayerIdentifier: string;
}

export interface ShowSTPApplicantKYBReturn {
  showStpApplicantKyb: {
    approvalStatus: 'PENDING' | 'REJECTED' | 'APPROVED' | 'IN_PROCESS';
    uuid: string;
    approvedAt: string;
    clabeOld: string;
  };
}

export default SHOW_STP_APPLICANT_KYB;
