import React, { forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const CommentInfo: Icon = forwardRef(
  ({ color = '#101121', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <g clipPath="url(#clip0_4958_3057)">
          <path
            d="M8.55742 0.0185357C7.41446 -0.061437 6.26765 0.105033 5.1946 0.50668C4.12155 0.908328 3.1473 1.53578 2.33776 2.34659C1.52822 3.15739 0.902286 4.13262 0.502313 5.2063C0.102341 6.27997 -0.0623393 7.42703 0.0194167 8.56987C0.313417 12.8052 4.05475 15.9999 8.72208 15.9999H12.6668C13.5505 15.9988 14.3977 15.6473 15.0226 15.0224C15.6475 14.3975 15.999 13.5503 16.0001 12.6665V8.22654C16.025 6.1715 15.2715 4.18313 13.891 2.66065C12.5104 1.13817 10.6051 0.194294 8.55742 0.0185357ZM8.00008 3.3332C8.2653 3.3332 8.51965 3.43856 8.70719 3.6261C8.89473 3.81363 9.00008 4.06799 9.00008 4.3332C9.00008 4.59842 8.89473 4.85277 8.70719 5.04031C8.51965 5.22785 8.2653 5.3332 8.00008 5.3332C7.73487 5.3332 7.48051 5.22785 7.29298 5.04031C7.10544 4.85277 7.00008 4.59842 7.00008 4.3332C7.00008 4.06799 7.10544 3.81363 7.29298 3.6261C7.48051 3.43856 7.73487 3.3332 8.00008 3.3332ZM9.33342 11.9999C9.33342 12.1767 9.26318 12.3463 9.13815 12.4713C9.01313 12.5963 8.84356 12.6665 8.66675 12.6665C8.48994 12.6665 8.32037 12.5963 8.19535 12.4713C8.07032 12.3463 8.00008 12.1767 8.00008 11.9999V7.99987H7.33342C7.15661 7.99987 6.98704 7.92963 6.86201 7.80461C6.73699 7.67958 6.66675 7.51001 6.66675 7.3332C6.66675 7.15639 6.73699 6.98682 6.86201 6.8618C6.98704 6.73677 7.15661 6.66654 7.33342 6.66654H8.00008C8.35371 6.66654 8.69284 6.80701 8.94289 7.05706C9.19294 7.30711 9.33342 7.64625 9.33342 7.99987V11.9999Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_4958_3057">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

CommentInfo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CommentInfo.displayName = 'CommentInfo';

export default CommentInfo;
