import React from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import * as S from './TaxpayerDropdown.styles';

import Dropdown, { DropdownItem } from '@ui/Dropdown';
import { Plus } from '@ui/Icon/outline';
import IconButton from '@ui/IconButton';
import useModal from '@src/hooks/useModal';
import useSettings from '@src/hooks/useSettings';
import CreateBusinessForm from '@shared/form/CreateBusinessForm';

import TaxpayerItem from './TaxpayerItem';

interface TaxpayerDropdownProps {
  fullWidth?: boolean;
}

function TaxpayerDropdown({ fullWidth }: TaxpayerDropdownProps) {
  const { business, currentUser, setCurrentBusinessId } = useSettings();
  const intl = useIntl();
  const router = useRouter();
  const { open: openModal } = useModal(CreateBusinessForm);

  const handleOnClick = ({ businessId }: { businessId: string }) => {
    setCurrentBusinessId(businessId);
    router.push('/');
  };

  const handleNewTaxpayerClick = () => {
    openModal({});
  };

  return (
    <S.TaxPayerDropdownContainer fullWidth={fullWidth}>
      <Dropdown
        rows={5}
        fullWidth
        selectedNode={
          <TaxpayerItem
            imageUrl={business?.imageUrl}
            name={business?.name}
            strong
          />
        }
      >
        {currentUser?.businesses?.map((option, index) => {
          return (
            <DropdownItem
              key={index}
              onClick={() => handleOnClick({ businessId: option.id })}
            >
              <TaxpayerItem
                imageUrl={option.imageUrl}
                name={option.name}
                selected={option.name === business?.name}
              />
            </DropdownItem>
          );
        })}
        <DropdownItem onClick={handleNewTaxpayerClick}>
          <S.TaxPayerOption>
            <IconButton variant="ghosted" compact>
              <Plus />
            </IconButton>
            <p>
              {intl.formatMessage({
                defaultMessage: 'Agregar razón social',
                id: 'jtpj5M',
              })}
            </p>
          </S.TaxPayerOption>
        </DropdownItem>
      </Dropdown>
    </S.TaxPayerDropdownContainer>
  );
}

export default TaxpayerDropdown;
