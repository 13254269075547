import { CustomWindow } from '../../custom.window';

declare const window: CustomWindow;

const useCustomerSupportChat = () => {
  return () => {
    if (window.Intercom) {
      window.Intercom('show', {
        api_base: 'https://api-iam.intercom.io',
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      });
    }
  };
};

export default useCustomerSupportChat;
