import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const FileXML: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path
          fillRule="evenodd"
          d="M6.10511 23H18.8958C20.0261 23 20.9423 22.0837 20.9423 20.9535V7.1907C20.9423 6.6652 20.7402 6.15984 20.3778 5.77931L16.4309 1.63512C16.0447 1.22955 15.509 1 14.949 1H6.10511C4.97485 1 4.05859 1.91625 4.05859 3.04651V20.9535C4.05859 22.0837 4.97485 23 6.10511 23ZM19.2265 6.19121L15.7511 2.71577C15.59 2.55462 15.3144 2.66875 15.3144 2.89666V6.37209C15.3144 6.51338 15.4289 6.62791 15.5702 6.62791H19.0457C19.2736 6.62791 19.3877 6.35236 19.2265 6.19121ZM7.0238 12.3953H5.80723L7.1568 14.6279L5.77235 16.8605H7.002L7.83921 15.4346H7.87409L8.7113 16.8605H9.94967L8.55868 14.6279L9.90607 12.3953H8.69822L7.87409 13.8059H7.83921L7.0238 12.3953ZM10.4642 16.8605H11.5151V14.1025H11.5521L12.6248 16.8321H13.2963L14.369 14.1177H14.4061V16.8605H15.4569V12.3953H14.1205L12.9867 15.1599H12.9344L11.8007 12.3953H10.4642V16.8605ZM19.0669 16.8605H16.1301V12.3953H17.2093V15.984H19.0669V16.8605Z"
        />
      </svg>
    );
  }
);

FileXML.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FileXML.displayName = 'FileXML';

export default FileXML;
