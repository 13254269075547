import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const SyncOff: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M10.0908 20.6151C9.53854 20.6151 9.09082 20.1673 9.09082 19.6151C9.09082 17.3943 9.80479 15.2472 11.1056 13.4807C11.433 13.0359 12.059 12.9409 12.5037 13.2683C12.9485 13.5958 13.0435 14.2218 12.7161 14.6665C11.6667 16.0916 11.0908 17.8237 11.0908 19.6151C11.0908 20.1673 10.6431 20.6151 10.0908 20.6151ZM14.1806 11.8227C13.9222 11.3346 14.1085 10.7294 14.5966 10.4711C16.0744 9.68886 17.734 9.26905 19.4368 9.26905C19.9891 9.26905 20.4368 9.71676 20.4368 10.269C20.4368 10.8213 19.9891 11.269 19.4368 11.269C18.0632 11.269 16.7245 11.6076 15.5322 12.2387C15.0441 12.4971 14.439 12.3108 14.1806 11.8227Z" />
        <path d="M2.82129 20.6151C2.269 20.6151 1.82129 20.1673 1.82129 19.6151C1.82129 15.3746 3.35026 11.2918 6.10291 8.10369C6.46384 7.68566 7.09531 7.63937 7.51334 8.0003C7.93137 8.36123 7.97765 8.9927 7.61673 9.41073C5.17665 12.2368 3.82129 15.8561 3.82129 19.6151C3.82129 20.1673 3.37357 20.6151 2.82129 20.6151ZM9.14135 6.53543C8.82645 6.08171 8.93899 5.45863 9.39271 5.14374C12.3217 3.11092 15.8217 1.99993 19.4364 1.99993C19.9887 1.99993 20.4364 2.44765 20.4364 2.99993C20.4364 3.55221 19.9887 3.99993 19.4364 3.99993C16.2322 3.99993 13.1295 4.98473 10.533 6.78679C10.0793 7.10169 9.45624 6.98915 9.14135 6.53543Z" />
        <path d="M20.1143 21.707C19.7238 22.0975 19.0906 22.0975 18.7001 21.707L2.11462 5.12152C1.7241 4.73099 1.7241 4.09783 2.11462 3.7073C2.50514 3.31678 3.13831 3.31678 3.52883 3.7073L20.1143 20.2928C20.5049 20.6833 20.5049 21.3165 20.1143 21.707Z" />
      </svg>
    );
  }
);

SyncOff.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SyncOff.displayName = 'SyncOff';

export default SyncOff;
