import styled from 'styled-components';

import Box, { BaseBoxProps } from '../Box/Box';

type CenteredFlexContainerProps = BaseBoxProps & {
  direction?: 'row' | 'column';
};

const CenteredFlexContainer = styled(Box)<CenteredFlexContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  justify-content: center;
  align-items: center;
`;

export default CenteredFlexContainer;
