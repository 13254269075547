import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Warning: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM12 6.54545C12.6025 6.54545 13.0909 7.03387 13.0909 7.63636V12C13.0909 12.6025 12.6025 13.0909 12 13.0909C11.3975 13.0909 10.9091 12.6025 10.9091 12V7.63636C10.9091 7.03387 11.3975 6.54545 12 6.54545ZM10.9091 16.3636C10.9091 15.7611 11.3975 15.2727 12 15.2727H12.0109C12.6134 15.2727 13.1018 15.7611 13.1018 16.3636C13.1018 16.9661 12.6134 17.4545 12.0109 17.4545H12C11.3975 17.4545 10.9091 16.9661 10.9091 16.3636Z"
          fill={color}
        />
      </svg>
    );
  }
);

Warning.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Warning.displayName = 'Warning';

export default Warning;
