import React from 'react';

import * as S from './Loader.styles';

interface LoaderProps {
  fullWidth?: boolean;
}

function Loader({ fullWidth }: LoaderProps) {
  return (
    <S.Container fullWidth={fullWidth}>
      <S.Image src="/img/higo_loader.gif" alt="loading" />
    </S.Container>
  );
}

export default Loader;
