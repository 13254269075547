import { graphql } from 'msw';

export const reminderScheduleNotificationsHandler = graphql.query(
  'ReminderScheduleNotifications',
  (_, res, ctx) =>
    res(
      ctx.data({
        reminderScheduleNotifications: {
          edges: [
            {
              node: {
                reminderScheduleId: '1',
                sentAt: '2022-06-08T18:00:16-05:00',
                seenAt: '2022-07-12T09:00:16-05:00',
                relatedReceivablesIds: [
                  { id: '1', invoice: { id: 249880 } },
                  { id: '2', invoice: { id: 249881 } },
                ],
                notificationType: 'before_expiration_date',
              },
            },
            {
              node: {
                reminderScheduleId: '2',
                sentAt: '2022-08-02T18:00:16-05:00',
                seenAt: undefined,
                relatedReceivablesIds: [
                  { id: '3', invoice: { id: 249880 } },
                  { id: '4', invoice: { id: 249881 } },
                ],
                notificationType: 'on_expiration_date',
              },
            },
            {
              node: {
                reminderScheduleId: '3',
                sentAt: '2022-07-30T18:00:16-05:00',
                seenAt: undefined,
                relatedReceivablesIds: [
                  { id: '4', invoice: { id: 249880 } },
                  { id: '5', invoice: { id: 249881 } },
                ],
                notificationType: 'after_expiration_date',
              },
            },
          ],
          pageInfo: {
            startCursor: 'mw',
            endCursor: 'MQ',
            hasNextPage: false,
            hasPreviousPage: false,
          },
          totalCount: 1,
        },
      })
    )
);
