import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import { Content } from '@ui/Snackbar/Snackbar';
import { Appear } from '@ui/Snackbar/Snackbar.styles';

export type SnackFunction = (
  message: string | React.ReactNode,
  action?: {
    label: string;
    onClick: () => void;
  } | null,
  options?: ToastOptions
) => void;

const snack: SnackFunction = (message, action, options = {}) =>
  toast.info(<Content message={message} action={action} />, {
    ...options,
    transition: Appear,
    theme: 'colored',
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_CENTER,
    icon: false,
    containerId: 'snackbar-container',
  });

export default snack;
