import { graphql } from 'msw';

export const verifyLoginHandler = graphql.mutation(
  'VerifyLogin',
  (req, res, ctx) => {
    const { verificationCode } = req.variables;

    return res(
      ctx.data({
        verifyLogin: {
          token: '43128100349',
        },
      })
    );
  }
);
