import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';

import authLink from './authLink';
import cache from './cache';
import errorLink from './errorLink';

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL + '/v2/gastown/graphql',
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
});

export default client;
