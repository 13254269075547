import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

import classicStylePagination from './classicStylePagination';

const merge = (_: any, incoming: any) => {
  return incoming;
};

const cache = new InMemoryCache({
  typePolicies: {
    Order: {
      keyFields: ['hashId'],
    },
    Query: {
      fields: {
        businessRelationshipsAsClient: relayStylePagination(['businessId']),
        businessUsers: relayStylePagination(['businessId', 'status']),
        clientRelationships: relayStylePagination([
          'businessId',
          'search',
          'before',
          'after',
        ]),
        clients: {
          keyArgs: [
            'businessId',
            'search',
            'active',
            'before',
            'after',
            'first',
            'last',
          ],
        },
        codatCompany: { keyArgs: ['businessId'] },
        creditAccount: {
          keyArgs: ['businessId'],
        },
        financingRequests: {
          keyArgs: [
            'businessId',
            'creditAccountId',
            'search',
            'active',
            'before',
            'after',
            'first',
            'last',
          ],
          merge,
        },
        invoicesEmitted: relayStylePagination([
          'businessId',
          'receiverTaxpayerIdentifier',
        ]),
        invoicesReceived: classicStylePagination(),
        invoiceStatusCount: relayStylePagination(['businessId']),
        providers: relayStylePagination(['businessId']),
        providerRelationships: {
          keyArgs: [
            'businessId',
            'search',
            'active',
            'before',
            'after',
            'first',
            'last',
          ],
          merge,
        },
        receivables: classicStylePagination(),
        invoiceIssueIntents: classicStylePagination(),
        receivablesCollectedAmount: {
          keyArgs: ['businessId', 'startDate', 'endDate'],
        },
        receivablesOverdueAmount: {
          keyArgs: ['businessId', 'startDate', 'endDate'],
        },
        receivablesPendingCollectionAmount: {
          keyArgs: ['businessId', 'startDate', 'endDate'],
        },
        receivedCreditNotes: relayStylePagination([
          'businessId',
          'supplierHashId',
          'uncredited',
          'approvalStatus',
        ]),
        supplierRelationship: relayStylePagination([
          'businessId',
          'supplierHashId',
        ]),
        taxPayerInfos: relayStylePagination(['businessId']),
        wallet: relayStylePagination(['businessId']),
        walletEntries: {
          keyArgs: [
            'sortBy',
            'before',
            'after',
            'pending',
            'first',
            'last',
            'createdAtFrom',
            'createdAtTo',
            'status',
            'search',
          ],
          merge,
        },
      },
    },
  },
  possibleTypes: {
    CountryTaxPayerInfos: ['MexicanTaxPayerInfo'],
    Eventable: [
      'CommentEvent',
      'CostCenterEvent',
      'InvoiceStatusEvent',
      'InvoiceManualPaymentEvent',
      'InvoiceSTPPaymentEvent',
    ],
    ProviderContact: ['User', 'Contact'],
    BusinessContact: ['Contact', 'User'],
    Entryable: [
      'DepositEntry',
      'InvoicePaymentEntry',
      'WithdrawalEntry',
      'RefundEntry',
    ],
    Refundable: ['InvoicePayment', 'STPPayment'],
  },
  dataIdFromObject: (object) => {
    const _id = object.id || object.hashId;

    if (_id) {
      return `${object.__typename}:${_id}`;
    }

    return undefined;
  },
});

export default cache;
