import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Columns: Icon = forwardRef(
  ({ size = 24, color = 'black' }, ref: ForwardedRef<SVGSVGElement>) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="3"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M8.47098 1.41125L8.47098 22.5877"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M15.529 1.41125L15.529 23.9995"
          stroke={color}
          strokeWidth="2"
        />
      </svg>
    );
  }
);

Columns.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Columns.displayName = 'Columns';

export default Columns;
