import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ErrorDiamond: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.31531 1.94535C8.79811 0.462551 11.2022 0.462551 12.685 1.94535L18.0547 7.31506C19.5375 8.79787 19.5375 11.202 18.0547 12.6848L12.685 18.0545C11.2022 19.5373 8.79811 19.5373 7.31531 18.0545L1.9456 12.6848C0.462795 11.202 0.462795 8.79787 1.9456 7.31506L7.31531 1.94535ZM10.0002 5.41658C10.4604 5.41658 10.8335 5.78968 10.8335 6.24992V10.4166C10.8335 10.8768 10.4604 11.2499 10.0002 11.2499C9.53992 11.2499 9.16683 10.8768 9.16683 10.4166V6.24992C9.16683 5.78968 9.53992 5.41658 10.0002 5.41658ZM10.0002 14.1666C10.4604 14.1666 10.8335 13.7935 10.8335 13.3333C10.8335 12.873 10.4604 12.4999 10.0002 12.4999C9.53992 12.4999 9.16683 12.873 9.16683 13.3333C9.16683 13.7935 9.53992 14.1666 10.0002 14.1666Z"
          fill={color}
        />
      </svg>
    );
  }
);

ErrorDiamond.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ErrorDiamond.displayName = 'ErrorDiamond';

export default ErrorDiamond;
