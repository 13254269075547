import { graphql } from 'msw';

export const creditCardConfigHandler = graphql.query(
  'CreditCardConfig',
  (req, res, ctx) => {
    return res(
      ctx.data({
        __typename: 'Query',
        creditCardConfig: {
          __typename: 'CreditCardConfig',
          id: '1',
          maxTransactionAmountLimitCents: 200_000_000,
          clientCommissionRate: 0.016,
          userCommissionRate: 0.02,
          totalCommissionRate: 0.036,
          active: true,
        },
      })
    );
  }
);
