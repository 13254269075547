import styled from 'styled-components';

export const TransferInfo = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.colors.neutral[500]};
`;

export const OldCLABE = styled.div`
  padding: 10px;
  color: #3478dc;
  display: flex;
  gap: 20px;
  background-color: #ebf2fc;
  border-radius: 10px;
  border: solid 1.5px #3478dc;
  justify-content: center;
  align-items: center;
`;

export const NewCLABE = styled.div<{ hasNewCLABE: boolean }>`
  padding: 10px;
  background-color: ${({ hasNewCLABE }) => (hasNewCLABE ? '#d4fff0' : 'none')};
  border-radius: 10px;
  border: ${({ hasNewCLABE }) =>
    hasNewCLABE ? 'solid 1.5px #37e398' : 'none'};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  gap: 10px;
`;

export const Wrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Chip = styled.div`
  background-color: #8aeedb;
  border-radius: 20px;
  font-size: 10px;
  padding: 1px 15px;
  font-weight: bold;
`;
