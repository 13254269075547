import { FieldFunctionOptions } from '@apollo/client';

import { PageInfo } from '@src/generated/graphql';

type CacheEntry = {
  __ref: string;
};

type Edge = {
  node: CacheEntry;
  __typename: string;
};

type Incoming = {
  edges: Edge[];
  pageInfo: PageInfo;
  totalCount: number;
  pendingTotal: number;
  __typename: string;
};

type Args = FieldFunctionOptions & {
  variables?: {
    first?: number;
    last?: number;
    page?: number;
    after?: number;
    before?: number;
    tabIndex?: number;
  };
};

type ReadReturn = Incoming | undefined;

type Existing = Record<
  string,
  {
    edges: Edge[];
    pageInfo: Record<number, PageInfo>;
    pendingTotal: number;
    totalCount: number;
    __typename: string;
  }
>;

type CacheVariables = Record<string, any>;

function getCacheKey({
  key,
  variables = {},
}: {
  key: string;
  variables?: CacheVariables;
}) {
  const {
    first,
    last,
    page = 1,
    after,
    before,
    tabIndex,
    ...filters
  } = variables;
  /**
   * This is the way apollo hashes the variables, we could use `args.storeFieldName`
   * but for some reason it doesn't work, but JSON.stringify(filters) it's a better approach,
   * because with `args.storeFieldName` if in the future we add more filters or variables, we would
   * need to add them mannually to the keyArgs array. With JSON.stringify(filters) will work automatically
   * thanks to the destructuring.
   */
  const hash = JSON.stringify(filters);
  const limit = first || last;
  const isUnpaginated = !limit;
  /**
   * to avoid issues, even when two requests are made with the same variables, when one request is paginated
   * and the other is not, we need to store the result in a different key.
   */
  const cacheKey = isUnpaginated
    ? `${key}:unpaginated:${hash}`
    : `${key}:${hash}`;
  return cacheKey;
}

function classicStylePagination() {
  return {
    keyArgs: false as const,
    merge(existing: Existing, incoming: Incoming, args: Args): Existing {
      const variables = args.variables || {};
      const { first, page = 1, last } = variables;

      const cacheKey = getCacheKey({
        key: args.fieldName,
        variables: variables,
      });

      const incomingEdges = incoming?.edges || [];
      const limit = first || last;
      // if the query is unpaginated.
      if (limit === undefined) {
        return {
          ...existing,
          [cacheKey]: {
            ...incoming,
            edges: incomingEdges,
            pageInfo: {
              ...existing?.[cacheKey]?.pageInfo,
              [page]: {
                ...incoming.pageInfo,
              },
            },
          },
        };
      }

      /**
       * We're faking a offset pagination here [https://www.apollographql.com/docs/react/pagination/offset-based/].
       * The `offset` argument indicate where in the list the new items should be inserted.
       * The `limit` argument indicate how many items should be inserted. This is the same as the `first` or `last` argument.
       * If you're moving to the next page, first will have a value and last will be undefined. If you're moving to the previous page,
       * first will be undefined and last will have a value, but both variables have the same value.
       */
      const offset = page === 1 ? 0 : (page - 1) * limit;

      const mergedEdges = existing?.[cacheKey]
        ? existing[cacheKey].edges?.slice(0)
        : [];
      /**
       * Update the existing cache with the new incoming values.
       */
      incomingEdges.forEach((incomingEdge, index) => {
        mergedEdges[offset + index] = incomingEdge;
      });
      /**
       * The structure of the cache is:
       * {
       *  [cacheKey]: {
       *   edges: [...],
       *   pageInfo: {
       *    [page]: {...}
       *  }
       * Where the cacheKey is the key that we use to hash the variables.
       * The edges array is the list of items that we have in the cache.
       * The pageInfo object is a map of page numbers to pageInfo objects.
       */
      return {
        ...existing,
        [cacheKey]: {
          ...incoming,
          edges: mergedEdges,
          pageInfo: {
            ...existing?.[cacheKey]?.pageInfo,
            [page]: {
              ...incoming.pageInfo,
            },
          },
        },
      };
    },
    read(existing: Existing, args: Args): ReadReturn {
      const variables = args.variables || {};
      const { first, page = 1, last } = variables;

      const cacheKey = getCacheKey({
        key: args.fieldName,
        variables: variables,
      });

      // A read function should always return undefined if existing is
      // undefined. Returning undefined signals that the field is
      // missing from the cache, which instructs Apollo Client to
      // fetch its value from your GraphQL server.
      if (!cacheKey || !existing || !existing[cacheKey]) {
        return undefined;
      }

      const pageInfo = existing?.[cacheKey].pageInfo[page];
      const limit = first || last;
      // if the query is unpaginated.
      if (limit === undefined) {
        const edges = existing?.[cacheKey].edges;

        return {
          ...existing[cacheKey],
          edges,
          pageInfo,
        };
      }

      const offset = page === 1 ? 0 : (page - 1) * limit;

      /**
       * We get the edges based on the offset and limit.
       * We need to filter the edges to remove the undefined values because the merge function
       * sometimes adds undefined values to the edges array.
       */
      const edges = existing?.[cacheKey].edges
        ?.slice(offset, offset + limit)
        .filter(Boolean);
      // if the edges array is empty, we return undefined so Apollo Client will fetch the data from the server
      if (edges.length === 0) {
        return undefined;
      }

      return {
        ...existing[cacheKey],
        edges,
        pageInfo,
      };
    },
  };
}

export default classicStylePagination;
