import styled from 'styled-components';

interface ContainerProps {
  fullWidth?: boolean;
}

export const TaxPayerDropdownContainer = styled.div<ContainerProps>`
  width: ${({ theme, fullWidth }) => (fullWidth ? '100%' : theme.spacing[80])};
  height: ${({ theme }) => theme.spacing[12]};

  button {
    padding: 0 ${({ theme }) => theme.spacing[4]};
    .button-body {
      justify-content: space-between;
    }
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}px) {
    width: ${({ theme }) => theme.spacing[52]};
  }
`;

export const TaxPayerOption = styled.div<{ strong?: boolean }>`
  display: flex;
  align-items: center;
  p {
    margin: 0 ${({ theme }) => theme.spacing[2]};
    font-weight: ${({ strong, theme }) =>
      strong ? theme.fontWeight.medium : theme.fontWeight.light};
  }
  .taxpayer-avatar {
    flex-shrink: 0;
  }
`;
