import { gql } from '@apollo/client';

import {
  InvoiceApprovalStatus,
  InvoiceGlobalStatus,
  InvoiceMailboxStatus,
  InvoicePaymentStatus,
  InvoiceVoucherType,
} from '@src/types/enums';
import { PaginatedResponse, Sort } from '@src/types/utils';

import {
  INVOICE_LIST_ATTRIBUTES,
  InvoiceListAttributes,
} from '../fragments/invoiceListAttributes';
export const INVOICES_RECEIVED_QUERY_NAME = 'InvoicesReceived';

export const INVOICES_RECEIVED_QUERY = gql`
  query ${INVOICES_RECEIVED_QUERY_NAME}(
    $businessId: ID!
    $approvalStatus: [InvoiceApprovalStatusEnum!]
    $mailboxStatus: [InvoiceMailboxStatusEnum!]
    $globalStatus: [InvoiceGlobalStatusEnum!]
    $paymentStatus: [InvoicePaymentStatusEnum!]
    $fiscalIdentifier: String
    $archived: Boolean
    $expiryDateFrom: DateTime
    $expiryDateTo: DateTime
    $invoicingDateFrom: DateTime
    $invoicingDateTo: DateTime
    $paymentDateFrom: DateTime
    $paymentDateTo: DateTime
    $voucherType: [InvoiceVoucherTypeEnum!]
    $businessEmitterId: [ID!]
    $search: String
    $first: Int
    $last: Int
    $before: String
    $after: String
    $sortBy: InvoicesSortingParams
    $fromActiveProvidersOnly: Boolean
    $invoiceId: [ID!]
    $emitterTaxpayerIdentifier: [String!]
    $paymentForm: InvoicePaymentFormEnum
    $folio: String
    $deductionStatus: InvoiceDeductionStatusEnum
  ) {
    invoicesReceived(
      businessId: $businessId
      fiscalIdentifier: $fiscalIdentifier
      approvalStatus: $approvalStatus
      mailboxStatus: $mailboxStatus
      paymentStatus: $paymentStatus
      archived: $archived
      globalStatus: $globalStatus
      expiryDateFrom: $expiryDateFrom
      expiryDateTo: $expiryDateTo
      invoicingDateFrom: $invoicingDateFrom
      invoicingDateTo: $invoicingDateTo
      paymentDateFrom: $paymentDateFrom
      paymentDateTo: $paymentDateTo
      voucherType: $voucherType
      search: $search
      businessEmitterId: $businessEmitterId
      fromActiveProvidersOnly: $fromActiveProvidersOnly
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: $sortBy
      invoiceId: $invoiceId
      emitterTaxpayerIdentifier: $emitterTaxpayerIdentifier
      paymentForm: $paymentForm
      folio: $folio
      deductionStatus:$deductionStatus
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          ...InvoicesListAttributes
        }
      }
    }
  }
  ${INVOICE_LIST_ATTRIBUTES}
`;

export type InvoicesReceivedQueryVariables = {
  businessId?: string;
  approvalStatus?: Array<keyof typeof InvoiceApprovalStatus>;
  mailboxStatus?: Array<keyof typeof InvoiceMailboxStatus>;
  globalStatus?: Array<keyof typeof InvoiceGlobalStatus>;
  paymentStatus?: Array<keyof typeof InvoicePaymentStatus>;
  fiscalIdentifier?: string;
  archived?: boolean;
  expiryDateFrom?: string;
  expiryDateTo?: string;
  invoicingDateFrom?: string;
  invoicingDateTo?: string;
  paymentDateFrom?: string;
  paymentDateTo?: string;
  voucherType?: Array<keyof typeof InvoiceVoucherType>;
  businessEmitterId?: [string];
  search?: string;
  first?: number;
  last?: number;
  before?: string;
  after?: string;
  sortBy?: Sort<
    'ID' | 'CREATED_AT' | 'INVOICING_DATE' | 'EXPIRY_DATE' | 'PAYMENT_DATE'
  >;
  fromActiveProvidersOnly?: boolean;
  invoiceId?: string[];
};

export type InvoicesReceivedQueryReturn = {
  invoicesReceived: PaginatedResponse<InvoiceListAttributes>;
};
