import { gql } from '@apollo/client';

import { WALLET_ENTRY_LIST_ATTRIBUTES } from '@src/graphql/fragments';

export const TRANSACTIONS_LIST_QUERY_NAME = 'TransactionsList';
export const TRANSACTIONS_LIST_QUERY = gql`
    query ${TRANSACTIONS_LIST_QUERY_NAME}(
      $businessId: ID!
      $pending: Boolean
      $status: [WalletEntryStatusEnum!]
      $search: String
      $first: Int
      $last: Int
      $before: String
      $after: String
      $createdAtFrom: DateTime
      $createdAtTo: DateTime
      $providerId: ID
    ) {
      walletEntries(
        businessId: $businessId
        pending: $pending
        first: $first
        last: $last
        after: $after
        before: $before
        status: $status
        search: $search
        providerId: $providerId
        createdAtFrom: $createdAtFrom
        createdAtTo: $createdAtTo
        sortBy: { field: CREATED_AT, direction: DESC }
      ) {
        edges {
          node {
            ...WalletEntryAttributes
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
    ${WALLET_ENTRY_LIST_ATTRIBUTES}
  `;
