import { createGlobalStyle } from 'styled-components';

const FontStyles = createGlobalStyle`
      @font-face {
      font-family: Apercu;
      src: url('/fonts/Apercu-Light.otf');
      font-style: normal;
      font-weight: 300;
      }

      @font-face {
      font-family: Apercu;
      src: url('/fonts/Apercu.otf');
      font-style: normal;
      font-weight: 400;
      }

      @font-face {
      font-family: Apercu;
      src: url('/fonts/Apercu-Medium.otf');
      font-style: normal;
      font-weight: 500;
      }

      @font-face {
      font-family: Apercu;
      src: url('/fonts/Apercu-Bold.otf');
      font-style: normal;
      font-weight: 700;
      }

      @font-face {
      font-family: Apercu;
      src: url('/fonts/Apercu-LightItalic.otf');
      font-style: italic;
      font-weight: 300;
      }

      @font-face {
      font-family: Apercu;
      src: url('/fonts/Apercu-Italic.otf');
      font-style: italic;
      font-weight: 400;
      }

      @font-face {
      font-family: Apercu;
      src: url('/fonts/Apercu-MediumItalic.otf');
      font-style: italic;
      font-weight: 500;
      }

      @font-face {
      font-family: Apercu;
      src: url('/fonts/Apercu-BoldItalic.otf');
      font-style: italic;
      font-weight: 700;
      }
`;

export default FontStyles;
