import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import qs from 'qs';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import { Container as SnackbarContainer } from '@ui/Snackbar/Snackbar';
import { Container as ToastContainer } from '@ui/Toast/Toast';
import useQuerySilentChange from '@src/hooks/useQuerySilentChange';
import { IntlProvider } from '@src/components/i18n';
import RuntimeError from '@src/components/pages/RuntimeError';
import SettingsProvider from '@src/components/SettingsProvider';
import SubscriptionWall from '@src/components/shared/SubscriptionWall';
import GeneralAlertsWrapper from '@src/components/ui/GeneralAlertsWrapper';
import GlobalModals from '@shared/modals/GlobalModals';
import * as Sentry from '@sentry/react';
import { ModalProvider } from '@src/context/modalContext';
import client from '@src/lib/apollo/client';
import { set } from '@src/lib/auth';
import GlobalStyles from '@src/styles/globalStyles';
import theme from '@src/styles/theme';

import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/high-res.css';
import 'react-datepicker/dist/react-datepicker.css';

if (
  process.env.NEXT_PUBLIC_USE_MOCKS === 'true' &&
  typeof window !== 'undefined'
) {
  const { worker } = require('@src/mocks/browser');
  worker.start();
}

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { pop } = useQuerySilentChange();

  useEffect(() => {
    // Init PostHog
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY!, {
      api_host: 'https://app.posthog.com',
    });

    // Track page views
    const handleRouteChange = () => posthog.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  // Temporary logic for migration.
  if (typeof window !== 'undefined' && window.location.search) {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
      comma: true,
    });
    if (query.token) {
      set(query.token as string);
      pop('token', query.token as string);
    }
  }

  return (
    <Sentry.ErrorBoundary fallback={<RuntimeError />}>
      <Head>
        <title>Higo</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <ModalProvider>
        <ApolloProvider client={client}>
          <SettingsProvider>
            <IntlProvider>
              <ThemeProvider theme={theme}>
                <GlobalStyles />
                <SubscriptionWall>
                  <GeneralAlertsWrapper>
                    <Component {...pageProps} />
                  </GeneralAlertsWrapper>
                </SubscriptionWall>
                <ToastContainer />
                <SnackbarContainer />
                <GlobalModals />
              </ThemeProvider>
            </IntlProvider>
          </SettingsProvider>
        </ApolloProvider>
      </ModalProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
