import React from 'react';

import { Container, HelperText, Label } from './FormControl.styles';

import { FormControlProps } from './types';

function FormControl(props: FormControlProps) {
  const {
    label,
    hiddenLabel,
    error,
    required,
    helperText,
    children,
    id,
    fullWidth,
  } = props;

  return (
    <Container fullWidth={fullWidth}>
      {label || hiddenLabel ? (
        <Label error={error} htmlFor={id} hiddenLabel={hiddenLabel}>
          {label || hiddenLabel} {required && '*'}
        </Label>
      ) : null}
      {children}
      {helperText && (
        <HelperText role={error ? 'alert' : undefined} error={error}>
          {helperText}
        </HelperText>
      )}
    </Container>
  );
}

export default FormControl;
