import styled, { css } from 'styled-components';

interface ContainerProps {
  fullWidth?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 9999;
    `}
`;

export const Image = styled.img`
  width: 2.5rem;
`;
