import styled from 'styled-components';

import UICard from '@ui/Card';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  width: ${({ theme }) => theme.spacing[112]};

  &.drawer-appear {
    transform: translateX(${({ theme }) => theme.spacing[112]});
  }
  &.drawer-appear-active {
    transform: translateX(0);
    transition: transform 200ms;
  }
  &.drawer-appear-done {
    transform: translateX(0);
  }
  &.drawer-exit {
  }
  &.drawer-exit-active {
    transform: translateX(${({ theme }) => theme.spacing[112]});
    transition: transform 200ms;
  }
  &.drawer-exit-done {
    transform: translateX(-${({ theme }) => theme.spacing[112]});
  }
`;

export const Card = styled(UICard)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const CloseButton = styled.div`
  position: absolute;
  transform: translate(25%, -25%);
  top: ${({ theme }) => theme.spacing[8]};
  right: ${({ theme }) => theme.spacing[8]};
  z-index: 100;
`;
