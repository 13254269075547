import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Headphones: Icon = forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99967 2.66732C6.58519 2.66732 5.22863 3.22922 4.22844 4.22941C3.22824 5.22961 2.66634 6.58616 2.66634 8.00065V12.0007C2.66634 12.3688 2.36786 12.6673 1.99967 12.6673C1.63148 12.6673 1.33301 12.3688 1.33301 12.0007V8.00065C1.33301 6.23254 2.03539 4.53685 3.28563 3.28661C4.53587 2.03636 6.23156 1.33398 7.99967 1.33398C9.76779 1.33398 11.4635 2.03636 12.7137 3.28661C13.964 4.53685 14.6663 6.23254 14.6663 8.00065V12.0007C14.6663 12.3688 14.3679 12.6673 13.9997 12.6673C13.6315 12.6673 13.333 12.3688 13.333 12.0007V8.00065C13.333 6.58616 12.7711 5.22961 11.7709 4.22941C10.7707 3.22922 9.41416 2.66732 7.99967 2.66732Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.33301 9.33268C1.33301 8.96449 1.63148 8.66602 1.99967 8.66602H3.99967C4.53011 8.66602 5.03882 8.87673 5.41389 9.2518C5.78896 9.62688 5.99967 10.1356 5.99967 10.666V12.666C5.99967 13.1964 5.78896 13.7052 5.41389 14.0802C5.03882 14.4553 4.53011 14.666 3.99967 14.666H3.33301C2.80257 14.666 2.29387 14.4553 1.91879 14.0802C1.54372 13.7052 1.33301 13.1964 1.33301 12.666V9.33268ZM2.66634 9.99935V12.666C2.66634 12.8428 2.73658 13.0124 2.8616 13.1374C2.98663 13.2624 3.1562 13.3327 3.33301 13.3327H3.99967C4.17649 13.3327 4.34605 13.2624 4.47108 13.1374C4.5961 13.0124 4.66634 12.8428 4.66634 12.666V10.666C4.66634 10.4892 4.5961 10.3196 4.47108 10.1946C4.34605 10.0696 4.17649 9.99935 3.99967 9.99935H2.66634ZM11.9997 9.99935C11.8229 9.99935 11.6533 10.0696 11.5283 10.1946C11.4032 10.3196 11.333 10.4892 11.333 10.666V12.666C11.333 12.8428 11.4032 13.0124 11.5283 13.1374C11.6533 13.2624 11.8229 13.3327 11.9997 13.3327H12.6663C12.8432 13.3327 13.0127 13.2624 13.1377 13.1374C13.2628 13.0124 13.333 12.8428 13.333 12.666V9.99935H11.9997ZM10.5855 9.2518C10.9605 8.87673 11.4692 8.66602 11.9997 8.66602H13.9997C14.3679 8.66602 14.6663 8.96449 14.6663 9.33268V12.666C14.6663 13.1964 14.4556 13.7052 14.0806 14.0802C13.7055 14.4553 13.1968 14.666 12.6663 14.666H11.9997C11.4692 14.666 10.9605 14.4553 10.5855 14.0802C10.2104 13.7052 9.99967 13.1964 9.99967 12.666V10.666C9.99967 10.1356 10.2104 9.62687 10.5855 9.2518Z"
          fill={color}
        />
      </svg>
    );
  }
);

Headphones.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Headphones.displayName = 'Headphones';

export default Headphones;
