import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import * as S from './WalletDropdown.styles';

import Dropdown, { DropdownItem } from '@ui/Dropdown';
import { Receipt, Wallet } from '@ui/Icon/solid';
import useBalance from '@src/hooks/useBalance';
import { Currency } from '@src/components/i18n/Currency';

interface WalletItemProps {
  balance?: number;
  icon: React.ReactNode;
  label: string;
}

function WalletItem({ label, balance, icon }: WalletItemProps) {
  return (
    <S.WalletItem>
      <S.Icon>{icon}</S.Icon>
      <div>
        <S.Balance>
          <Currency value={balance ?? 0} />
        </S.Balance>
        <S.Name>{label}</S.Name>
      </div>
    </S.WalletItem>
  );
}

type Option = 'credit' | 'balance';

interface WalletDropdownProps {
  defaultValue?: Option;
  value?: Option;
  disabled?: boolean;
  fullWidth?: boolean;
}

function WalletDropdown({
  value,
  disabled,
  defaultValue = 'balance',
  fullWidth,
}: WalletDropdownProps) {
  const [selected, setSelected] = useState(defaultValue);
  const { walletCents, creditCents } = useBalance();
  const intl = useIntl();

  const options = useMemo(() => {
    const defaultOptions: {
      credit?: React.ReactNode;
      balance: React.ReactNode;
    } = {
      balance: (
        <WalletItem
          balance={walletCents}
          icon={<Receipt size={14} />}
          label={intl.formatMessage({
            defaultMessage: 'Mi saldo disponible',
            id: 'F2eJEZ',
          })}
        />
      ),
    };

    if (typeof creditCents === 'number') {
      defaultOptions.credit = (
        <WalletItem
          balance={creditCents}
          icon={<Wallet size={16} />}
          label={intl.formatMessage({
            defaultMessage: 'Mi crédito disponible',
            id: 'at0zC8',
          })}
        />
      );
    }
    return defaultOptions;
  }, [walletCents, creditCents]);

  return (
    <S.TaxPayerDropdownContainer fullWidth={fullWidth}>
      <Dropdown
        onChange={setSelected}
        rows={5}
        fullWidth
        disabled={disabled}
        selectedNode={options[value || selected]}
      >
        {Object.keys(options).map((option) => (
          <DropdownItem key={option} value={option}>
            {options[option as Option]}
          </DropdownItem>
        ))}
      </Dropdown>
    </S.TaxPayerDropdownContainer>
  );
}

export default WalletDropdown;
