import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ArrowDown: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <g clipPath="url(#clip0_2575_9335)">
          <path
            d="M19 0H5C3.67441 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.67441 0 5L0 19C0.00158786 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24H19C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19V5C23.9984 3.67441 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0V0ZM17.707 14.535L14.121 18.121C13.8424 18.3996 13.5117 18.6207 13.1477 18.7715C12.7837 18.9222 12.3935 18.9999 11.9995 18.9999C11.6055 18.9999 11.2153 18.9222 10.8513 18.7715C10.4873 18.6207 10.1566 18.3996 9.878 18.121L6.293 14.535L6.269 14.51C6.17368 14.4164 6.09807 14.3047 6.04664 14.1815C5.9952 14.0582 5.96898 13.9259 5.96951 13.7923C5.97005 13.6588 5.99734 13.5267 6.04976 13.4038C6.10219 13.281 6.17869 13.1699 6.27476 13.0771C6.37082 12.9843 6.4845 12.9117 6.60909 12.8636C6.73368 12.8154 6.86664 12.7927 7.00014 12.7968C7.13364 12.8009 7.26497 12.8317 7.38637 12.8874C7.50778 12.9431 7.6168 13.0225 7.707 13.121L11 16.414L10.993 6C10.993 5.73478 11.0984 5.48043 11.2859 5.29289C11.4734 5.10536 11.7278 5 11.993 5C12.2582 5 12.5126 5.10536 12.7001 5.29289C12.8876 5.48043 12.993 5.73478 12.993 6L13 16.413L16.293 13.121C16.3852 13.0255 16.4956 12.9493 16.6176 12.8969C16.7396 12.8445 16.8708 12.8169 17.0036 12.8157C17.1364 12.8146 17.2681 12.8399 17.391 12.8902C17.5138 12.9405 17.6255 13.0147 17.7194 13.1086C17.8133 13.2025 17.8875 13.3141 17.9378 13.437C17.9881 13.5599 18.0134 13.6916 18.0123 13.8244C18.0111 13.9572 17.9835 14.0884 17.9311 14.2104C17.8787 14.3324 17.8025 14.4428 17.707 14.535Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_2575_9335">
            <rect width="24" height="24" fill={color} />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

ArrowDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowDown.displayName = 'ArrowDown';

export default ArrowDown;
