import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f6f8;
  color: #030730;
  background-image: url('~@src/assets/img/bg_errors.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
`;

export const Container = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;

  button {
    margin-top: 2rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem;
  font-weight: 300;
`;

export const Error = styled.div`
  position: relative;

  label {
    font-size: 9.8rem;
    font-weight: bold;
  }

  span {
    position: absolute;
    font-size: 40px;
    margin-left: -1rem;
    margin-top: 1.5rem;
  }
`;
