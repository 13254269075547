import { clientListClientsHandler } from './clientListClients';
import { clientOverviewHandler } from './clientOverview';
import { collectableReceivablesTableHandler } from './collectableReceivablesTable';
import { collectedReceivablesTableHandler } from './collectedReceivablesTable';
import { creditCardConfigHandler } from './creditCardConfig';
import { invoiceViewInvoiceHandler } from './invoiceViewInvoice';
import { paymentBreakdownHandler } from './paymentBreakdown';
import { pendingInvoiceIssueIntentsListHandler } from './pendingInvoiceIssueIntentsList';
import { privateClientOverviewHandler } from './privateClientOverview';
import { receivablesCollectedAmountHandler } from './receivablesCollectedAmount';
import { receivablesOverdueAmountHandler } from './receivablesOverdueAmount';
import { receivablesPendingCollectionAmountHandler } from './receivablesPendingCollectionAmount';
import { reminderScheduleNotificationsHandler } from './reminderScheduleNotifications';
import { reminderSchedulesHandler } from './reminderSchedules';
import { termLoanInstallmentsHandler } from './termLoanInstallments';
import { transactionsListHandler } from './transactionsList';

const queries = [
  transactionsListHandler,
  receivablesPendingCollectionAmountHandler,
  receivablesOverdueAmountHandler,
  receivablesCollectedAmountHandler,
  creditCardConfigHandler,
  collectableReceivablesTableHandler,
  collectedReceivablesTableHandler,
  invoiceViewInvoiceHandler,
  paymentBreakdownHandler,
  pendingInvoiceIssueIntentsListHandler,
  privateClientOverviewHandler,
  termLoanInstallmentsHandler,
  clientListClientsHandler,
  clientOverviewHandler,
  reminderSchedulesHandler,
  reminderScheduleNotificationsHandler,
];

export default queries;
