import React from 'react';

import { StyledTableHeaderCell } from './TableHeaderCell.styles';

import { TableHeaderCellProps } from './types';

export default function TableHeaderCell(props: TableHeaderCellProps) {
  const { children, action, ...otherProps } = props;

  return (
    <StyledTableHeaderCell {...otherProps}>
      <div className="th-content">
        {children}
        {action && action}
      </div>
    </StyledTableHeaderCell>
  );
}
