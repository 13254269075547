import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';

import * as S from './RuntimeError.styles';

import Button from '@ui/Button';
import Page from '@shared/Page';

const RuntimeError = () => {
  const router = useRouter();
  return (
    <Page title="404">
      <S.PageContainer>
        <S.Container>
          <S.Error>
            <label>Pff</label>
            <span role="img" aria-label="worried-face">
              😰
            </span>
          </S.Error>
          <h1>
            <FormattedMessage
              id="Va4cfr"
              defaultMessage="Ocurrió un error inesperado."
            />
          </h1>
          <S.Description>
            <FormattedMessage
              id="XASk/7"
              defaultMessage="Estamos teniendo dificultades técnicas. Intenta más tarde."
            />
          </S.Description>
          <Button onClick={() => router.replace('/')}>
            <FormattedMessage id="0jrhNQ" defaultMessage="Regresar al inicio" />
          </Button>
        </S.Container>
      </S.PageContainer>
    </Page>
  );
};

export default RuntimeError;
