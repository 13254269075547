import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

import theme from '@src/styles/theme';

const Notification: Icon = forwardRef(
  (
    { color = theme.colors.neutral[900], size = 20, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <g clipPath="url(#clip0_4853_2524)">
          <path
            d="M18.7958 11.3851L17.2125 5.6884C16.7484 4.01951 15.7398 2.55364 14.3469 1.52379C12.9541 0.493945 11.257 -0.0407771 9.52537 0.00462298C7.79375 0.0500231 6.12699 0.67294 4.79004 1.77436C3.45308 2.87578 2.52266 4.39249 2.14665 6.0834L0.920818 11.5959C0.785414 12.205 0.788545 12.8368 0.929979 13.4445C1.07141 14.0522 1.34754 14.6205 1.73797 15.1072C2.1284 15.5939 2.62317 15.9868 3.18574 16.2567C3.74832 16.5266 4.36434 16.6668 4.98832 16.6667H5.91665C6.10791 17.6086 6.61893 18.4555 7.3631 19.0637C8.10728 19.672 9.03885 20.0042 9.99998 20.0042C10.9611 20.0042 11.8927 19.672 12.6369 19.0637C13.381 18.4555 13.8921 17.6086 14.0833 16.6667H14.7817C15.424 16.6668 16.0576 16.5183 16.6331 16.2329C17.2086 15.9476 17.7103 15.533 18.099 15.0216C18.4877 14.5103 18.753 13.9159 18.874 13.2851C18.995 12.6543 18.9685 12.004 18.7966 11.3851H18.7958ZM9.99998 18.3334C9.48477 18.3313 8.98279 18.17 8.56274 17.8717C8.14268 17.5733 7.82505 17.1525 7.65332 16.6667H12.3467C12.1749 17.1525 11.8573 17.5733 11.4372 17.8717C11.0172 18.17 10.5152 18.3313 9.99998 18.3334ZM16.7717 14.0126C16.5394 14.3206 16.2386 14.5703 15.893 14.7417C15.5474 14.9131 15.1666 15.0016 14.7808 15.0001H4.98832C4.61397 15 4.24442 14.9159 3.90694 14.7539C3.56945 14.5919 3.27266 14.3562 3.03845 14.0641C2.80425 13.7721 2.63862 13.4312 2.55379 13.0666C2.46895 12.702 2.46709 12.323 2.54832 11.9576L3.77332 6.44423C4.06861 5.11607 4.79941 3.92474 5.84953 3.05961C6.89966 2.19448 8.20884 1.70521 9.56896 1.66958C10.9291 1.63395 12.2621 2.054 13.3561 2.86296C14.4501 3.67192 15.2422 4.82336 15.6067 6.13423L17.19 11.8309C17.2946 12.202 17.3113 12.5925 17.2386 12.9712C17.166 13.3499 17.0061 13.7064 16.7717 14.0126Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_4853_2524">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Notification.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Notification.displayName = 'Notification';

export default Notification;
