import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { Overlay, Wrapper } from './Backdrop.styles';

import { BackdropProps } from './types';

const enableScroll = () => {
  if (typeof document !== 'undefined') {
    document.body.style.overflowY = 'auto';
    document.body.style.position = 'static';
  }
};

const blockScroll = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    if (document.body.scrollHeight > window.innerHeight) {
      document.body.style.overflowY = 'scroll';
    }
    document.body.style.position = 'fixed';
  }
};

export default function Backdrop({
  children,
  open,
  invisible,
  backgroundColor = 'neutral',
  backgroundColorStep = '900',
  backgroundOpacity = '40',
  ...otherProps
}: BackdropProps) {
  useEffect(() => {
    if (open) {
      blockScroll();
    } else {
      enableScroll();
    }
  }, [open]);

  return (
    <CSSTransition
      appear
      mountOnEnter
      unmountOnExit
      in={open}
      timeout={{
        enter: 0,
        appear: 0,
        exit: 400,
      }}
      classNames="backdrop"
    >
      <Wrapper {...otherProps}>
        <CSSTransition
          appear
          in={open}
          timeout={{
            appear: 300,
            enter: 100,
            exit: 300,
          }}
          classNames="overlay"
        >
          <Overlay
            invisible={invisible}
            backgroundColor={backgroundColor}
            backgroundColorStep={backgroundColorStep}
            backgroundOpacity={backgroundOpacity}
          />
        </CSSTransition>
        {children}
      </Wrapper>
    </CSSTransition>
  );
}
