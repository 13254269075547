import React from 'react';
import { useIntl } from 'react-intl';

import { Divider, Tag, Tooltip } from '@ui';
import { Divide, Info, X } from '@ui/Icon/outline';
import { Currency } from '@src/components/i18n';
import { FlexContainer } from '@shared/layouts';

interface CreditInterestTagProps {
  interestRateAmount: number;
  interestRateAmountWithTaxes: number;
  annualInterestRate: number;
  ivaInterestAmount?: number;
  moratoryInterestAmount?: number;
  annualMoratoryRate?: number;
}

function CreditInterestTag({
  interestRateAmount,
  interestRateAmountWithTaxes,
  annualInterestRate,
  ivaInterestAmount,
  moratoryInterestAmount,
  annualMoratoryRate,
}: CreditInterestTagProps) {
  const intl = useIntl();

  return (
    <Tooltip
      place="top"
      effect="solid"
      width={300}
      titleElement={
        <div>
          <div>
            <FlexContainer justifyContent="space-between">
              <p>
                {intl.formatMessage({
                  defaultMessage: 'Tasa de interés',
                  id: 'SyXZeV',
                })}{' '}
                *
              </p>
              <p>
                <Currency
                  maximumFractionDigits={2}
                  value={interestRateAmount}
                />
              </p>
            </FlexContainer>
            {!!moratoryInterestAmount && (
              <FlexContainer justifyContent="space-between">
                <p>
                  {intl.formatMessage({
                    defaultMessage: 'Tasa de interés moratorio',
                    id: 'Mi8/+D',
                  })}{' '}
                  **
                </p>
                <p>
                  <Currency
                    maximumFractionDigits={2}
                    value={moratoryInterestAmount}
                  />
                </p>
              </FlexContainer>
            )}
            <FlexContainer justifyContent="space-between">
              <p>IVA (16%)</p>
              <p>
                <Currency
                  maximumFractionDigits={2}
                  value={
                    !!ivaInterestAmount
                      ? ivaInterestAmount
                      : interestRateAmount * 0.16
                  }
                />
              </p>
            </FlexContainer>
            <Divider margin="2" />
            <FlexContainer justifyContent="space-between">
              <p>Total</p>
              <p>
                <Currency
                  maximumFractionDigits={2}
                  value={interestRateAmountWithTaxes}
                />
              </p>
            </FlexContainer>
          </div>
          <br />
          <p>
            *
            {intl.formatMessage({
              defaultMessage: 'La tasa de interés se calcula a partir del',
              id: 'PjXOiT',
            })}
            <br />
            <em>
              {intl.formatMessage({
                defaultMessage: 'Monto a financiar',
                id: '+MADWp',
              })}{' '}
              <X size={10} /> ( {annualInterestRate}% <Divide size={10} />{' '}
              {intl.formatMessage(
                {
                  defaultMessage: '{days} días',
                  id: 'aNRWjR',
                },
                {
                  days: 365,
                }
              )}{' '}
              ) <X size={10} />{' '}
              {intl.formatMessage({
                defaultMessage: 'Términos del crédito',
                id: 'USPtju',
              })}
            </em>
          </p>
          {!!moratoryInterestAmount && !!annualMoratoryRate && (
            <p>
              *
              {intl.formatMessage({
                defaultMessage: 'La tasa de interés se calcula a partir del',
                id: 'PjXOiT',
              })}
              <br />
              <em>
                {intl.formatMessage({
                  defaultMessage: 'Monto a financiar',
                  id: '+MADWp',
                })}{' '}
                <X size={10} /> ( {annualMoratoryRate}% <Divide size={10} />{' '}
                {intl.formatMessage(
                  {
                    defaultMessage: '{days} días',
                    id: 'aNRWjR',
                  },
                  {
                    days: 365,
                  }
                )}{' '}
                ) <X size={10} />{' '}
                {intl.formatMessage({
                  defaultMessage: 'Días de atraso',
                  id: 'fAL/Bq',
                })}
              </em>
            </p>
          )}
        </div>
      }
      id="financing-rate-tooltip"
    >
      <Tag
        label={`${annualInterestRate}% e.a.`}
        compact
        color="gray"
        endIcon={<Info />}
      />
    </Tooltip>
  );
}

export default CreditInterestTag;
