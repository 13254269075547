import { graphql } from 'msw';

export const payInstallmentHandler = graphql.mutation(
  'PayInstallment',
  (req, res, ctx) => {
    return res(
      ctx.data({
        id: '1',
      })
    );
  }
);
