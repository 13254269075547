import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Wallet: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path d="M16.5156 3C19.9644 3 22 4.98459 22 8.3818H17.7689V8.41647C15.8052 8.41647 14.2133 9.96849 14.2133 11.883C14.2133 13.7975 15.8052 15.3495 17.7689 15.3495H22V15.6615C22 19.0154 19.9644 21 16.5156 21H7.48444C4.03556 21 2 19.0154 2 15.6615V8.33847C2 4.98459 4.03556 3 7.48444 3H16.5156ZM21.2533 9.87241C21.6657 9.87241 22 10.1983 22 10.6004V13.131C21.9952 13.5311 21.6637 13.8543 21.2533 13.8589H17.8489C16.8548 13.872 15.9855 13.2084 15.76 12.2643C15.6471 11.6783 15.8056 11.0736 16.1931 10.6122C16.5805 10.1509 17.1573 9.88007 17.7689 9.87241H21.2533ZM18.2489 11.0424H17.92C17.7181 11.0401 17.5236 11.1166 17.38 11.255C17.2364 11.3934 17.1556 11.5821 17.1556 11.779C17.1555 12.1921 17.4964 12.5282 17.92 12.533H18.2489C18.6711 12.533 19.0133 12.1993 19.0133 11.7877C19.0133 11.3761 18.6711 11.0424 18.2489 11.0424ZM12.3822 6.89119H6.73778C6.31903 6.89116 5.9782 7.2196 5.97333 7.62783C5.97333 8.04087 6.31415 8.37705 6.73778 8.3818H12.3822C12.8044 8.3818 13.1467 8.04812 13.1467 7.6365C13.1467 7.22487 12.8044 6.89119 12.3822 6.89119Z" />
      </svg>
    );
  }
);

Wallet.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Wallet.displayName = 'Wallet';

export default Wallet;
