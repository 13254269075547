import React from 'react';

import * as S from './Dropdown.styles';

import type { DropdownItemProps } from './types';

function DropdownItem({
  selected,
  value,
  children,
  disabled,
  onChange,
  onClick,
  ...otherProps
}: DropdownItemProps) {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      return;
    }

    if (!selected && onChange && value) {
      onChange(value);
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <S.DropdownItem
      onClick={handleClick}
      selected={selected}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </S.DropdownItem>
  );
}

export default DropdownItem;
