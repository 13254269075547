import styled from 'styled-components';

import { Card, Header as UiHeader } from '@ui';

export const Container = styled(Card)`
  text-align: center;
  max-width: 527px;
`;

export const Header = styled(UiHeader)`
  margin: 0 auto;
  max-width: 400px;
`;

export const DangerContainer = styled(Card)`
  width: ${({ theme }) => theme.spacing[96]};
`;
