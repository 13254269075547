import React from 'react';

import { StyledTableRow } from './TableRow.styles';

import { TableRowProps } from './types';

export default function TableRow(props: TableRowProps) {
  const { children, ...otherProps } = props;

  return <StyledTableRow {...otherProps}>{children}</StyledTableRow>;
}
