import React, { ForwardedRef, forwardRef } from 'react';

import * as S from './FeedbackForm.styles';

interface FeedbackOptionProps {
  emoji: string;
  label: string;
  value: string | number;
  name: string;
  required?: boolean;
  color: 'neutral' | 'danger' | 'warning' | 'info' | 'success';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
}

function FeedbackOption(
  {
    emoji,
    label,
    value,
    name,
    color,
    required,
    onChange,
    onClick,
  }: FeedbackOptionProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <S.Option $color={color} onClick={onClick}>
      <S.Input
        required={required}
        type="radio"
        id={name + value}
        name={name}
        value={value}
        ref={ref}
        onChange={onChange}
      />
      <S.Label htmlFor={name + value}>
        <S.Emoji>{emoji}</S.Emoji>
        <span>{label}</span>
      </S.Label>
    </S.Option>
  );
}

export default forwardRef(FeedbackOption);
