import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Verified: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill={color}
        viewBox="0 0 16 16"
        {...rest}
      >
        <path d="M7.39442 0.460829C7.75223 0.188546 8.24777 0.188546 8.60558 0.46083L9.08804 0.827971C9.32589 1.00897 9.63224 1.07409 9.92316 1.00548L10.5132 0.866315C10.9509 0.763106 11.4036 0.964659 11.6197 1.35894L11.9111 1.89057C12.0548 2.15267 12.3082 2.33676 12.6018 2.39241L13.1975 2.50528C13.6393 2.58899 13.9708 2.95724 14.0079 3.40534L14.0579 4.00954C14.0825 4.30742 14.2391 4.57866 14.4848 4.74894L14.983 5.09433C15.3526 5.35049 15.5057 5.82177 15.3573 6.2462L15.1572 6.8185C15.0586 7.10065 15.0913 7.41213 15.2465 7.66761L15.5612 8.1858C15.7946 8.57011 15.7428 9.06293 15.4346 9.39032L15.019 9.83176C14.8141 10.0494 14.7174 10.3473 14.7552 10.6437L14.8319 11.2451C14.8888 11.6912 14.641 12.1203 14.2263 12.294L13.6671 12.5283C13.3915 12.6438 13.1819 12.8765 13.0959 13.1628L12.9213 13.7434C12.7919 14.174 12.391 14.4652 11.9415 14.4553L11.3354 14.4418C11.0366 14.4352 10.7504 14.5626 10.5554 14.7891L10.1598 15.2485C9.86645 15.5892 9.38174 15.6923 8.97514 15.5003L8.42689 15.2415C8.1566 15.1139 7.8434 15.1139 7.57311 15.2415L7.02486 15.5003C6.61826 15.6923 6.13355 15.5892 5.84018 15.2485L5.44459 14.7891C5.24957 14.5626 4.96345 14.4352 4.66463 14.4418L4.05851 14.4553C3.60899 14.4652 3.20809 14.174 3.07867 13.7434L2.90415 13.1628C2.81811 12.8765 2.60854 12.6438 2.33286 12.5283L1.77367 12.294C1.35897 12.1203 1.1112 11.6912 1.1681 11.2451L1.24482 10.6437C1.28265 10.3473 1.18586 10.0494 0.98099 9.83176L0.565429 9.39032C0.257236 9.06293 0.205439 8.57011 0.43883 8.1858L0.753529 7.66761C0.908678 7.41213 0.941415 7.10065 0.842773 6.8185L0.642689 6.2462C0.494301 5.82177 0.64743 5.35049 1.01696 5.09433L1.51522 4.74894C1.76086 4.57866 1.91746 4.30742 1.94211 4.00954L1.9921 3.40534C2.02917 2.95724 2.36075 2.58899 2.80251 2.50528L3.39818 2.39241C3.69185 2.33676 3.94523 2.15267 4.0889 1.89057L4.38032 1.35893C4.59645 0.964658 5.04914 0.763106 5.48676 0.866315L6.07684 1.00548C6.36776 1.07409 6.67411 1.00897 6.91196 0.82797L7.39442 0.460829Z" />
        <path
          d="M10.6666 6.40002L6.99998 9.60002L5.33331 8.14548"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

Verified.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Verified.displayName = 'Verified';

export default Verified;
