import * as React from 'react';
import styled from 'styled-components';

import SwitchUnstyled, {
  switchUnstyledClasses,
  SwitchUnstyledProps,
} from '@mui/core/SwitchUnstyled';

const Root = styled.span`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: ${({ theme }) => theme.spacing[12]};
  min-width: ${({ theme }) => theme.spacing[12]};
  height: ${({ theme }) => theme.spacing[6]};
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: ${({ theme }) => theme.colors.neutral[300]};
    border-radius: ${({ theme }) => theme.spacing[3]};
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: ${({ theme }) => theme.spacing[5]};
    height: ${({ theme }) => theme.spacing[5]};
    top: ${({ theme }) => theme.spacing[0.5]};
    left: ${({ theme }) => theme.spacing[0.5]};
    border-radius: ${({ theme }) => theme.spacing[2.5]};
    background-color: ${({ theme }) => theme.colors.neutral[50]};
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${({ theme }) => theme.colors.neutral[50]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 26px;
      background-color: ${({ theme }) => theme.colors.neutral[50]};
    }

    .${switchUnstyledClasses.track} {
      background: ${({ theme }) => theme.colors.primary[500]};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

function Switch(props: SwitchUnstyledProps) {
  return <SwitchUnstyled component={Root} {...props} />;
}

export default Switch;
