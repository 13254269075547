import React from 'react';
import { ReactNode } from 'react';
import Head from 'next/head';

type PageProps = {
  className?: string;
  title?: string | React.ReactNode;
  children: ReactNode;
};

export default function Page({ className, children, title }: PageProps) {
  return (
    <div className={className}>
      <Head>
        <title>{title || null}</title>
      </Head>
      {children}
    </div>
  );
}
