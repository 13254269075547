import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Edit2: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <path
          d="M2.48284 14.9308C1.95999 15.4535 1.66617 16.1625 1.66602 16.9018L1.66602 18.3326H3.09686C3.83617 18.3324 4.54515 18.0386 5.06784 17.5158L14.3673 8.21634L11.7823 5.63135L2.48284 14.9308Z"
          fill={color}
        />
        <path
          d="M17.7987 2.20193C17.629 2.03203 17.4275 1.89726 17.2057 1.8053C16.9838 1.71335 16.746 1.66602 16.5059 1.66602C16.2658 1.66602 16.028 1.71335 15.8061 1.8053C15.5843 1.89726 15.3828 2.03203 15.213 2.20193L12.7695 4.64614L15.3545 7.23114L17.7987 4.78762C17.9686 4.6179 18.1034 4.41637 18.1954 4.19453C18.2873 3.9727 18.3347 3.73491 18.3347 3.49477C18.3347 3.25463 18.2873 3.01685 18.1954 2.79501C18.1034 2.57318 17.9686 2.37164 17.7987 2.20193V2.20193Z"
          fill={color}
          fillOpacity="0.4"
        />
      </svg>
    );
  }
);

Edit2.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Edit2.displayName = 'Edit2';

export default Edit2;
