import React, { ForwardedRef, forwardRef, useState } from 'react';

import Icon from '@ui/Icon';
import Input from '@ui/Input';

import type { PasswordInputProps } from './types';

function PasswordInput(
  props: PasswordInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [type, setType] = useState<string>('password');

  return (
    <Input
      {...props}
      ref={ref}
      type={type}
      endIcon={
        <Icon
          size={16}
          name={type === 'password' ? 'Eye' : 'EyeOff'}
          onClick={() => {
            setType(type === 'password' ? 'text' : 'password');
          }}
        />
      }
    />
  );
}

export default forwardRef(PasswordInput);
