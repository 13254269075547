import styled, { Color, ColorStep, css } from 'styled-components';

interface HeaderProps {
  as?: string;
  colorStep?: ColorStep;
  color?: Color;
}

const Header = styled.h1<HeaderProps>`
  font-size: ${({ theme }) => theme.fontSize['4xl']};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme, color = 'neutral', colorStep = '900' }) =>
    theme.colors[color][colorStep]};

  ${({ as }) =>
    as === 'h1' &&
    css`
      font-size: ${({ theme }) => theme.fontSize['4xl']};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    `}

  ${({ as }) =>
    as === 'h2' &&
    css`
      font-size: ${({ theme }) => theme.fontSize['3xl']};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    `}

  ${({ as }) =>
    as === 'h3' &&
    css`
      font-size: ${({ theme }) => theme.fontSize['2xl']};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    `}

  ${({ as }) =>
    as === 'h4' &&
    css`
      font-size: ${({ theme }) => theme.fontSize.xl};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    `}

  ${({ as }) =>
    as === 'h5' &&
    css`
      font-size: ${({ theme }) => theme.fontSize.lg};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    `}

  ${({ as }) =>
    as === 'h6' &&
    css`
      font-size: ${({ theme }) => theme.fontSize.lg};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    `}
`;

export default Header;
