import styled, { css } from 'styled-components';

import { CircledIconProps } from './types';

type ContainerProps = Pick<
  CircledIconProps,
  'backgroundColor' | 'backgroundColorStep' | 'backgroundOpacity' | 'size'
>;

export const Container = styled.div<ContainerProps>`
  color: ${({ theme, backgroundColor = 'primary' }) =>
    theme.colors[backgroundColor][500]};
  background-color: ${({
    theme,
    backgroundColor = 'primary',
    backgroundColorStep = '50',
    backgroundOpacity = '100',
  }) =>
    theme.colors[backgroundColor][backgroundColorStep] +
    theme.opacityHex[backgroundOpacity]};

  ${({ backgroundColor, theme, backgroundColorStep }) =>
    backgroundColor === 'neutral' &&
    !backgroundColorStep &&
    css`
      background-color: ${theme.colors.neutral[100]};
    `}

  width: ${({ theme, size = '12' }) => theme.spacing[size]};
  height: ${({ theme, size = '12' }) => theme.spacing[size]};

  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
