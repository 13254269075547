import React from 'react';

import { createTheme } from '@mui/material/styles';

import MUIGrid from '@mui/material/Grid';

const theme = createTheme();

function Grid(props: any) {
  return <MUIGrid theme={theme} {...props} />;
}

export default Grid;
