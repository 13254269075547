import React, { forwardRef, useEffect, useRef } from 'react';

import { CheckboxLabeled, CheckboxOnly } from './Checkbox.styles';

import { Check, Minus } from '@ui/Icon/outline';

import type { CheckboxProps } from './types';

function Checkbox(
  {
    id,
    label,
    variant,
    indeterminate = false,
    buffer,
    checked,
    disabled = false,
    ...otherProps
  }: CheckboxProps,
  forwardedRef: any
) {
  const input = useRef<HTMLInputElement>(null);
  const ref = forwardedRef || input;

  useEffect(() => {
    if (ref?.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate, checked]);

  if (label) {
    return (
      <CheckboxLabeled variant={variant} disabled={disabled}>
        <div>
          <input
            type="checkbox"
            id={id}
            ref={ref}
            checked={checked}
            {...otherProps}
            disabled={disabled}
          />
          <Check className="checkmark" />
          <Minus className="indeterminate" />
        </div>
        <label htmlFor={id}>{label}</label>
      </CheckboxLabeled>
    );
  }

  return (
    <CheckboxOnly variant={variant} buffer={buffer}>
      <label htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          ref={ref}
          checked={checked}
          {...otherProps}
        />
        <Check className="checkmark" />
        <Minus className="indeterminate" />
      </label>
    </CheckboxOnly>
  );
}

export default forwardRef(Checkbox);
