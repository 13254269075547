import qs from 'qs';

// This hooks uses browser history to replace URL without re-rendering.
const useQuerySilentChange = () => {
  const push = (key: string, value: string) => {
    if (typeof window !== 'undefined') {
      const search = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        comma: true,
      });
      const params = search?.[key] as string | string[] | undefined;
      const nextSearch = { ...search };
      nextSearch[key] = Array.isArray(params) ? [...params, value] : [value];
      const nextQueryString = qs.stringify(nextSearch, {
        skipNulls: true,
        arrayFormat: 'comma',
        encode: false,
      });
      window.history.replaceState(
        {},
        '',
        window.location.pathname + '?' + nextQueryString
      );
    }
  };

  const pop = (key: string, value: string) => {
    if (typeof window !== 'undefined') {
      const search = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        comma: true,
      });
      const params = search?.[key] as string | string[] | undefined;
      const nextSearch = { ...search };
      nextSearch[key] = Array.isArray(params)
        ? params.filter((param) => param !== value)
        : [];
      const nextQueryString = qs.stringify(nextSearch, {
        skipNulls: true,
        arrayFormat: 'comma',
        encode: false,
      });
      window.history.replaceState(
        {},
        '',
        window.location.pathname + '?' + nextQueryString
      );
    }
  };

  const replace = (key: string, value: string) => {
    if (typeof window !== 'undefined') {
      const search = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        comma: true,
      });
      const nextSearch = { ...search };
      nextSearch[key] = value;
      const nextQueryString = qs.stringify(nextSearch, {
        skipNulls: true,
        arrayFormat: 'comma',
        encode: false,
      });
      window.history.replaceState(
        {},
        '',
        window.location.pathname + '?' + nextQueryString
      );
    }
  };

  return {
    push,
    pop,
    replace,
  };
};

export default useQuerySilentChange;
