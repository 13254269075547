import enMessages from '@src/translations/locales/en.json';
import esMessages from '@src/translations/locales/es.json';

import 'intl';

export type SupportedLanguage = 'es' | 'en';

// load polyfill data in case Intl does not exist
if (typeof window !== 'undefined' && !window.Intl) {
  import(
    /* webpackChunkName: 'intlPolyfillLocaleData.[request]' */
    'intl/locale-data/jsonp/es.js' as any
  );
  import(
    /* webpackChunkName: 'intlPolyfillLocaleData.[request]' */
    'intl/locale-data/jsonp/en.js' as any
  );
}

export const SUPPORTED_LOCALES = ['es', 'en'];

export const DEFAULT_LOCALE = (process.env.NEXT_PUBLIC_DEFAULT_LOCALE ||
  'es') as SupportedLanguage;

export const MESSAGES = {
  es: esMessages,
  en: enMessages,
};

export class I18N {
  static getLanguageFromLocale(locale: string): string {
    const regionCodeSeparator = /[_-]+/;
    const [language] = locale.split(regionCodeSeparator);
    return language;
  }

  static getLocale(locale?: string): SupportedLanguage {
    if (!locale) {
      let browserLanguage;
      if (typeof navigator !== 'undefined') {
        browserLanguage =
          (navigator.languages && navigator.languages[0]) ||
          navigator.language ||
          //@ts-ignore
          navigator.userLanguage;
      }

      return SUPPORTED_LOCALES.includes(browserLanguage)
        ? browserLanguage
        : DEFAULT_LOCALE;
    }

    const language = I18N.getLanguageFromLocale(locale);

    if (SUPPORTED_LOCALES.includes(locale)) {
      return locale as SupportedLanguage;
    } else if (SUPPORTED_LOCALES.includes(language)) {
      return language as SupportedLanguage;
    } else {
      return DEFAULT_LOCALE;
    }
  }

  static getMessages(locale: string) {
    const language = I18N.getLanguageFromLocale(locale) as SupportedLanguage;

    if (SUPPORTED_LOCALES.includes(locale)) {
      return MESSAGES[locale as SupportedLanguage];
    }

    return MESSAGES[language] || MESSAGES[DEFAULT_LOCALE];
  }

  static getTimeZone(timeZone?: string): string {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return timeZone || browserTimeZone;
  }
}
