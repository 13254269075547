import { gql } from '@apollo/client';

import { Invoice } from '@src/types/models';

export const INVOICE_LIST_ATTRIBUTES = gql`
  fragment InvoicesListAttributes on Invoice {
    id
    approvalStatus
    businessEmitter {
      id
      name
      hashId
    }
    businessRelationship {
      id
      active
      paymentTerms
      providerAlias
      providerHashId
      bankingInfos {
        id
        bankId
        recipient
        bankName
        clabe
        bankLogoUrl
        validationStatus
        alias
        visibility
      }
      providerContacts {
        defaultContact
        visibility
        contactable {
          email
        }
      }
    }
    costCenter {
      id
      name
    }
    codatSyncedAt
    createdAt
    currency
    emitterTaxpayerName
    emitterTaxpayerIdentifier
    expiryDate
    foreignTotal
    globalStatus
    invoicingDate
    mailboxStatus
    outstandingBalance
    paymentDate
    paymentStatus
    receiverCountryTaxPayerInfo {
      __typename
      ... on MexicanTaxPayerInfo {
        folio
      }
    }
    satVerified
    total
    voucherType
    factoringOffer {
      acceptedAt
    }
    paymentForm
    invoicePayments {
      deducted
      daysToGetPaymentComplement
      deductionStatus
    }
  }
`;

export type InvoiceListAttributes = Pick<
  Invoice,
  | 'id'
  | 'approvalStatus'
  | 'businessEmitter'
  | 'businessRelationship'
  | 'codatSyncedAt'
  | 'costCenter'
  | 'createdAt'
  | 'currency'
  | 'emitterTaxpayerName'
  | 'emitterTaxpayerIdentifier'
  | 'expiryDate'
  | 'foreignTotal'
  | 'globalStatus'
  | 'invoicingDate'
  | 'mailboxStatus'
  | 'outstandingBalance'
  | 'paymentDate'
  | 'paymentStatus'
  | 'receiverCountryTaxPayerInfo'
  | 'satVerified'
  | 'total'
  | 'voucherType'
  | 'factoringOffer'
  | 'paymentForm'
>;
