import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Smartphone: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <g clipPath="url(#clip0_3133_9626)">
          <path
            d="M15 0H9C7.67441 0.00158786 6.40356 0.528882 5.46622 1.46622C4.52888 2.40356 4.00159 3.67441 4 5V16H20V5C19.9984 3.67441 19.4711 2.40356 18.5338 1.46622C17.5964 0.528882 16.3256 0.00158786 15 0V0Z"
            fill={color}
          />
          <path
            d="M4 19C4.00159 20.3256 4.52888 21.5964 5.46622 22.5338C6.40356 23.4711 7.67441 23.9984 9 24H15C16.3256 23.9984 17.5964 23.4711 18.5338 22.5338C19.4711 21.5964 19.9984 20.3256 20 19V18H4V19ZM12 20C12.1978 20 12.3911 20.0586 12.5556 20.1685C12.72 20.2784 12.8482 20.4346 12.9239 20.6173C12.9996 20.8 13.0194 21.0011 12.9808 21.1951C12.9422 21.3891 12.847 21.5673 12.7071 21.7071C12.5673 21.847 12.3891 21.9422 12.1951 21.9808C12.0011 22.0194 11.8 21.9996 11.6173 21.9239C11.4346 21.8482 11.2784 21.72 11.1685 21.5556C11.0586 21.3911 11 21.1978 11 21C11 20.7348 11.1054 20.4804 11.2929 20.2929C11.4804 20.1054 11.7348 20 12 20Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_3133_9626">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Smartphone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Smartphone.displayName = 'Smartphone';

export default Smartphone;
