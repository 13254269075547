import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Input from 'react-phone-input-2';

import * as S from './PhoneInput.styles';

import type { PhoneInputProps } from './types';

// FIXME: Correct ForwardRef type
function PhoneInput(props: PhoneInputProps, ref: any) {
  const {
    fullWidth,
    onChange,
    onBlur,
    name,
    error,
    defaultValue,
    autoFocus,
    ...otherProps
  } = props;
  const inputContainer = useRef<HTMLDivElement>();
  const [value, setValue] = useState<string>(`${defaultValue}`);
  useEffect(() => {
    // Workaround to set ref. Library doesn't support refs on the root element.
    if (inputContainer?.current && ref) {
      const input = inputContainer?.current?.querySelector('input');
      input && ref(input);
    }
  }, [inputContainer]);

  const handleChange = (
    value: string,
    country: string,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    const normalizedEvent: any = { ...event };
    if (onChange) {
      if (!normalizedEvent.target) {
        normalizedEvent.target = {};
      }
      if (value) {
        normalizedEvent.target.value = formattedValue;
      }
      onChange(normalizedEvent);
    }
    setValue(value);
    return normalizedEvent;
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const normalizedEvent: any = { ...event };
    if (onBlur) {
      if (!normalizedEvent.target) {
        normalizedEvent.target = {};
      }
      onBlur(normalizedEvent);
    }
    return normalizedEvent;
  };

  return (
    <S.Container
      fullWidth={fullWidth}
      ref={inputContainer as any}
      error={error}
    >
      <Input
        value={value}
        country="mx"
        containerClass="phone-input-container"
        inputClass="phone-input-input"
        buttonClass="phone-input-button"
        dropdownClass="phone-input-dropdown"
        searchClass="phone-input-search"
        preferredCountries={['mx']}
        enableSearch
        disableSearchIcon
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          name,
          'data-testid': 'phone-input',
          autoFocus,
        }}
        {...otherProps}
      />
    </S.Container>
  );
}

export default forwardRef(PhoneInput);
