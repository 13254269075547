import { graphql } from 'msw';

export const reminderSchedulesHandler = graphql.query(
  'ReminderSchedules',
  (_, res, ctx) => {
    return res(
      ctx.data({
        reminderSchedules: [
          {
            id: 1,
            name: 'Colección general',
            lastNotification: {
              client: {
                taxName: 'Cliente 1',
              },
              sentAt: '2022-06-08T18:00:16-05:00',
              seenAt: '2022-07-12T09:00:16-05:00',
            },
            nextNotification: {
              client: {
                taxName: 'Cliente 1',
              },
              scheduledAt: '2022-07-22T09:00:16-05:00',
            },
            beforeExpirationDays: 7,
            afterExpirationDays: 1,
            onExpirationDate: true,
            default: true,
            active: true,
            notificationChannels: ['email'],
            clients: [
              {
                id: '11',
                taxName: 'Socoda SA de CV',
                notificationsSentCount: 20,
                lastReminderNotification: {
                  seenAt: '2022-07-11T09:00:16-05:00',
                  sentAt: '2022-07-10T09:00:16-05:00',
                },
              },
            ],
          },
        ],
      })
    );
  }
);
