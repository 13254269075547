import React from 'react';

import { StyledBadge } from './Badge.styles';

import { BadgeProps } from './types';

export default function Badge(props: BadgeProps) {
  const { label, ...otherProps } = props;
  const isCircular =
    typeof label === 'number' ? label < 10 : label?.length === 1;

  return (
    <StyledBadge circular={isCircular} {...otherProps}>
      {label}
    </StyledBadge>
  );
}
