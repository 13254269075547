import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Notification: Icon = forwardRef(
  (
    { color = 'black', size = 20, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <g opacity="0.9" clipPath="url(#clip0_5202_5216)">
          <path
            d="M2.71199 12H13.1493C13.4705 12 13.787 11.9226 14.072 11.7745C14.357 11.6263 14.6022 11.4116 14.7866 11.1486C14.9711 10.8857 15.0896 10.5822 15.1319 10.2637C15.1743 9.94532 15.1393 9.62138 15.03 9.31933L13.1287 4.06267C12.8039 2.89447 12.1055 1.86479 11.1402 1.13111C10.1749 0.397433 8.99581 0.000141386 7.78332 6.78208e-10C6.52114 -1.97282e-05 5.29669 0.430392 4.31213 1.22017C3.32757 2.00995 2.64179 3.11187 2.36799 4.344L0.801324 9.41067C0.709084 9.7098 0.688348 10.0264 0.740783 10.335C0.793219 10.6436 0.917363 10.9356 1.10323 11.1875C1.28909 11.4394 1.53148 11.6441 1.8109 11.7852C2.09032 11.9264 2.39896 11.9999 2.71199 12Z"
            fill={color}
          />
          <path
            d="M4.73332 13.3333C4.88633 14.0869 5.29514 14.7643 5.89049 15.2509C6.48583 15.7375 7.23109 16.0033 7.99999 16.0033C8.7689 16.0033 9.51416 15.7375 10.1095 15.2509C10.7048 14.7643 11.1136 14.0869 11.2667 13.3333H4.73332Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_5202_5216">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Notification.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Notification.displayName = 'Notification';

export default Notification;
