import { graphql } from 'msw';

export const createInvoiceHandler = graphql.mutation(
  'IssuingInvoiceCreateInvoice',
  (req, res, ctx) => {
    return res(
      ctx.data({
        createInvoice: {
          errors: [
            { code: '200', path: 'test', message: 'asdasdasd asdas das' },
          ],
          success: false,
        },
      })
    );
  }
);
