import { graphql } from 'msw';

export const loginHandler = graphql.mutation('Login', (req, res, ctx) => {
  const { email, password } = req.variables;

  if (email.contains('non-existing')) {
    return res(
      ctx.errors([
        {
          message: 'User not found',
          extensions: {
            id: 'f79e82e8-c34a-4dc7-a49e-9fadc0979fda',
          },
        },
      ])
    );
  }

  return res(
    ctx.data({
      login: {
        token: '323232',
        twoFAEnabled: true,
        channels: [
          {
            type: 'phone',
            maskedTo: '+34*******789',
          },
        ],
      },
    })
  );
});
