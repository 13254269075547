import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ArrowDownLeft: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <path d="M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM18.8839 6.88388L9.01777 16.75H18C18.6904 16.75 19.25 17.3096 19.25 18C19.25 18.6904 18.6904 19.25 18 19.25H6.00077H6L5.99625 19.25C5.6776 19.249 5.35924 19.127 5.11612 18.8839C4.99627 18.764 4.90585 18.6259 4.84485 18.4785C4.78373 18.3311 4.75 18.1695 4.75 18V6C4.75 5.30964 5.30964 4.75 6 4.75C6.69036 4.75 7.25 5.30964 7.25 6V14.9822L17.1161 5.11612C17.6043 4.62796 18.3957 4.62796 18.8839 5.11612C19.372 5.60427 19.372 6.39573 18.8839 6.88388Z" />
      </svg>
    );
  }
);

ArrowDownLeft.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowDownLeft.displayName = 'ArrowDownLeft';

export default ArrowDownLeft;
