import { graphql } from 'msw';

export const receivablesOverdueAmountHandler = graphql.query(
  'ReceivablesOverdueAmount',
  (req, res, ctx) => {
    return res(
      ctx.data({
        receivablesOverdueAmount: {
          totalAmountCents: 3000000,
        },
      })
    );
  }
);
