import React from 'react';

import { StyledCol, StyledTable } from './Table.styles';

import type { TableProps } from './types';

export default function Table(props: TableProps) {
  const {
    children,
    stickyHeader,
    columnsWidth,
    isResponsive,
    ...otherProps
  } = props;

  return (
    <StyledTable
      stickyHeader={stickyHeader}
      fixedLayout={!!columnsWidth}
      isResponsive={isResponsive}
      {...otherProps}
    >
      <table>
        {columnsWidth && (
          <colgroup>
            {columnsWidth.map((width, i) => (
              <StyledCol
                width={+width}
                isResponsive={isResponsive}
                key={`${width}-${i}`}
              />
            ))}
          </colgroup>
        )}
        {children}
      </table>
    </StyledTable>
  );
}
