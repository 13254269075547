import { gql } from '@apollo/client';

import { BusinessFeatureFlag } from '@src/types/models';

export const LIST_BUSINESS_FEATURE_FLAGS = gql`
  query listBusinessFeatureFlags($businessId: ID!) {
    listBusinessFeatureFlags(businessId: $businessId) {
      name
      enabled
    }
  }
`;

export interface ListBusinessFeatureInputType {
  businessId?: string;
}

export interface ListBusinessFeatureInputTypeReturn {
  listBusinessFeatureFlags: BusinessFeatureFlag[];
}
