import { graphql } from 'msw';

export const clientOverviewHandler = graphql.query(
  'ClientOverview',
  (req, res, ctx) => {
    return res(
      ctx.data({
        clientPortal: {
          __typename: 'Client',
          id: '3',
          taxName: 'JOKR MEXICO SA DE CV',
          overdueAmountCents: 0,
          overdueCount: 0,
          pendingCollectionAmountCents: 116,
          allowCreditCardPayments: true,
          pendingCollectionCount: 1,
          business: {
            name: 'Concentradora Financiera Higo S de RL de CV',
            stpClabe: '646180236600023690',
            __typename: 'Business',
          },
          receivables: [
            {
              __typename: 'Receivable',
              id: '3',
              amountCents: 116,
              expiresAt: '2022-07-01',
              collectedAt: '2022-06-24T16:18:46-05:00',
              issueDate: '2022-06-01',
              invoice: {
                fiscalIdentifier: 'd19f8cb8-27ad-4f59-ae76-d9a9552e21ae',
                receiverCountryTaxPayerInfo: {
                  folio: '87',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
            {
              __typename: 'Receivable',
              id: '4',
              amountCents: 116,
              expiresAt: '2022-06-02',
              collectedAt: '2022-06-24T16:45:45-05:00',
              issueDate: '2022-05-03',
              invoice: {
                fiscalIdentifier: '8d19fd46-4e44-4b54-b399-904bf35d8504',
                receiverCountryTaxPayerInfo: {
                  folio: '83',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
            {
              __typename: 'Receivable',
              id: '9',
              amountCents: 116,
              expiresAt: '2022-08-01',
              collectedAt: null,
              issueDate: '2022-05-03',
              invoice: {
                fiscalIdentifier: '79814261-dee7-4643-8172-2846751e97eb',
                receiverCountryTaxPayerInfo: {
                  folio: '84',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
            {
              __typename: 'Receivable',
              id: '10',
              amountCents: 32233,
              expiresAt: '2022-08-01',
              collectedAt: null,
              issueDate: '2022-05-03',
              invoice: {
                fiscalIdentifier: '79814261-dee7-4643-8172-2846751e97eb',
                receiverCountryTaxPayerInfo: {
                  folio: '85',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
            {
              __typename: 'Receivable',
              id: '11',
              amountCents: 645223,
              expiresAt: '2022-08-01',
              collectedAt: null,
              issueDate: '2022-05-03',
              invoice: {
                fiscalIdentifier: '79814261-dee7-4643-8172-2846751e97eb',
                receiverCountryTaxPayerInfo: {
                  folio: '86',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
            {
              __typename: 'Receivable',
              id: '12',
              amountCents: 22334,
              expiresAt: '2022-08-01',
              collectedAt: null,
              issueDate: '2022-05-03',
              invoice: {
                fiscalIdentifier: '79814261-dee7-4643-8172-2846751e97eb',
                receiverCountryTaxPayerInfo: {
                  folio: '87',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
            {
              __typename: 'Receivable',
              id: '14',
              amountCents: 975455,
              expiresAt: '2022-08-01',
              collectedAt: null,
              issueDate: '2022-05-03',
              invoice: {
                fiscalIdentifier: '79814261-dee7-4643-8172-2846751e97eb',
                receiverCountryTaxPayerInfo: {
                  folio: '88',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
          ],
        },
      })
    );
  }
);
