import React, { forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Key: Icon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }, ref: any) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M15.989 12.7V10C15.989 9.73479 16.0943 9.48043 16.2819 9.2929C16.4694 9.10536 16.7238 9 16.989 9H17.989C18.2542 9 18.5085 8.89465 18.6961 8.70711C18.8836 8.51957 18.989 8.26522 18.989 8V7C18.989 6.73479 19.0943 6.48043 19.2819 6.2929C19.4694 6.10536 19.7238 6 19.989 6H22.674C22.869 5.99906 23.0597 5.94198 23.2232 5.83558C23.3867 5.72918 23.5161 5.57797 23.596 5.4C23.9337 4.65618 24.0637 3.83476 23.972 3.023C23.8577 2.17783 23.438 1.40372 22.7921 0.846795C22.1462 0.289868 21.3188 -0.0113484 20.466 4.42308e-06C19.2627 8.05212e-05 18.1088 0.478134 17.258 1.329L9.34998 9.235C8.07527 8.90386 6.7351 8.92043 5.46898 9.283C3.72703 9.77559 2.22203 10.8816 1.23168 12.3969C0.241329 13.9122 -0.167533 15.7346 0.0805405 17.5278C0.328614 19.321 1.21688 20.9639 2.58143 22.1534C3.94598 23.343 5.69473 23.9988 7.50498 24C7.76684 23.9998 8.02852 23.9861 8.28898 23.959C9.77913 23.7968 11.1869 23.1934 12.3321 22.2262C13.4773 21.259 14.3077 19.972 14.717 18.53C15.0807 17.2643 15.0969 15.9241 14.764 14.65L15.414 14C15.7596 13.6526 15.9645 13.1895 15.989 12.7ZM5.49998 20C5.20331 20 4.9133 19.912 4.66662 19.7472C4.41995 19.5824 4.22769 19.3481 4.11416 19.074C4.00063 18.7999 3.97092 18.4983 4.0288 18.2074C4.08668 17.9164 4.22954 17.6491 4.43932 17.4393C4.6491 17.2296 4.91637 17.0867 5.20734 17.0288C5.49831 16.9709 5.79991 17.0007 6.074 17.1142C6.34809 17.2277 6.58236 17.42 6.74718 17.6666C6.912 17.9133 6.99998 18.2033 6.99998 18.5C6.99998 18.8978 6.84194 19.2794 6.56064 19.5607C6.27933 19.842 5.8978 20 5.49998 20Z"
          fill={color}
        />
      </svg>
    );
  }
);

Key.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Key.displayName = 'Key';

export default Key;
