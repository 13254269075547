import { useEffect } from 'react';
import { useRouter } from 'next/router';

import useModal from '@src/hooks/useModal';
import useSettings from '@src/hooks/useSettings';
import SubscriptionWallModal from '@src/components/shared/modals/SubscriptionWallModal';

interface SubscriptionWallProps {
  children: JSX.Element;
}

function SubscriptionWall({ children }: SubscriptionWallProps) {
  const { business } = useSettings();
  const { open: openBlocker, isOpen } = useModal(SubscriptionWallModal);
  const router = useRouter();

  useEffect(() => {
    const invoices = business?.pendingSubscriptionInvoices;
    const invoiceIds = invoices?.map((invoice) => invoice.id).join(',');
    const isPaymentRoute = router.pathname === '/invoice-payments';

    if (business?.activeSubscription === false && !isPaymentRoute && !isOpen) {
      openBlocker({
        invoiceIds,
      });
    }
  }, [
    business?.activeSubscription,
    business?.pendingSubscriptionInvoices,
    openBlocker,
    isOpen,
    router.pathname,
  ]);

  return children;
}

export default SubscriptionWall;
