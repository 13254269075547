import React from 'react';

import * as S from './Alert.styles';

import { Info as InfoOutline, X as Close } from '@ui/Icon/outline';
import { AlertTriangle, CheckCircle, ErrorDiamond, Info } from '@ui/Icon/solid';

import { AlertProps } from './types';

const defaultIcons = {
  info: <Info size={20} />,
  primary: <InfoOutline size={20} />,
  neutral: <Info size={20} />,
  success: <CheckCircle size={20} />,
  danger: <ErrorDiamond size={20} />,
  warning: <AlertTriangle size={20} />,
};

export default function Alert({
  children,
  color = 'info',
  icon = true,
  onClose,
  ...otherProps
}: AlertProps) {
  return (
    <S.Alert role="alert" color={color} {...otherProps}>
      <S.Content>
        {icon && (
          <S.StartIcon color={color}>
            {typeof icon === 'boolean' ? defaultIcons[color] : icon}
          </S.StartIcon>
        )}
        <S.Children color={color}>{children}</S.Children>
      </S.Content>
      {onClose && (
        <S.Button role="button" onClick={onClose} color={color}>
          <Close size={16} />
        </S.Button>
      )}
    </S.Alert>
  );
}
