import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ContactBook: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M6.2228 13.7614C6.86271 13.1215 7.73061 12.762 8.63559 12.762H13.46C14.365 12.762 15.2329 13.1215 15.8728 13.7614C16.5127 14.4013 16.8722 15.2692 16.8722 16.1742V17.3803C16.8722 17.9325 16.4245 18.3803 15.8722 18.3803C15.3199 18.3803 14.8722 17.9325 14.8722 17.3803V16.1742C14.8722 15.7996 14.7234 15.4404 14.4586 15.1756C14.1937 14.9107 13.8345 14.762 13.46 14.762H8.63559C8.26105 14.762 7.90185 14.9107 7.63701 15.1756C7.37217 15.4404 7.22339 15.7996 7.22339 16.1742V17.3803C7.22339 17.9325 6.77567 18.3803 6.22339 18.3803C5.6711 18.3803 5.22339 17.9325 5.22339 17.3803V16.1742C5.22339 15.2692 5.58289 14.4013 6.2228 13.7614Z" />
        <path d="M11.0477 7.52393C10.2678 7.52393 9.6355 8.15619 9.6355 8.93612C9.6355 9.71606 10.2678 10.3483 11.0477 10.3483C11.8276 10.3483 12.4599 9.71606 12.4599 8.93612C12.4599 8.15619 11.8276 7.52393 11.0477 7.52393ZM7.6355 8.93612C7.6355 7.05162 9.16319 5.52393 11.0477 5.52393C12.9322 5.52393 14.4599 7.05162 14.4599 8.93612C14.4599 10.8206 12.9322 12.3483 11.0477 12.3483C9.16319 12.3483 7.6355 10.8206 7.6355 8.93612Z" />
        <path d="M17.0952 4H5C4.44772 4 4 4.44772 4 5V18.6032C4 19.1555 4.44772 19.6032 5 19.6032H17.0952C17.6475 19.6032 18.0952 19.1555 18.0952 18.6032V5C18.0952 4.44772 17.6475 4 17.0952 4ZM5 2C3.34315 2 2 3.34315 2 5V18.6032C2 20.26 3.34315 21.6032 5 21.6032H17.0952C18.7521 21.6032 20.0952 20.26 20.0952 18.6032V5C20.0952 3.34315 18.7521 2 17.0952 2H5Z" />
        <path d="M22 4.71436C22.5523 4.71436 23 5.16207 23 5.71436L23 7.3334C23 7.88569 22.5523 8.3334 22 8.3334C21.4477 8.3334 21 7.88569 21 7.3334L21 5.71436C21 5.16207 21.4477 4.71436 22 4.71436Z" />
        <path d="M22 10.1428C22.5523 10.1428 23 10.5905 23 11.1428L23 12.7619C23 13.3142 22.5523 13.7619 22 13.7619C21.4477 13.7619 21 13.3142 21 12.7619L21 11.1428C21 10.5905 21.4477 10.1428 22 10.1428Z" />
        <path d="M22 15.5715C22.5523 15.5715 23 16.0192 23 16.5715L23 18.1906C23 18.7429 22.5523 19.1906 22 19.1906C21.4477 19.1906 21 18.7429 21 18.1906L21 16.5715C21 16.0192 21.4477 15.5715 22 15.5715Z" />
      </svg>
    );
  }
);

ContactBook.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ContactBook.displayName = 'ContactBook';

export default ContactBook;
