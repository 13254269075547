import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

type TooltipProps = {
  width?: number;
};
export const Tooltip = styled(ReactTooltip)`
  &.tooltip {
    background-color: ${({ theme }) => theme.colors.neutral[900]};
    color: ${({ theme }) => theme.colors.neutral[50]};
    border-radius: ${({ theme }) => theme.spacing[2]};
    padding: ${({ theme }) => theme.spacing[2]};
    min-height: ${({ theme }) => theme.spacing[8]};
    width: ${({ width }: TooltipProps) => (width ? `${width}px` : 'auto')};
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    line-height: 1;
    z-index: 99999999;
  }

  /* No arrow */
  &.place-right:after,
  &.place-bottom:after,
  &.place-left:after,
  &.place-top:after {
    border: none;
  }
`;
