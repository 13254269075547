import { graphql } from 'msw';

export const disableTwoFaHandler = graphql.mutation(
  'DisableTwoFa',
  (req, res, ctx) => {
    const { channel } = req.variables;
    const channels = ['email', 'phone'].filter((c) => c !== channel);
    return res(
      ctx.data({
        disableTwoFa: {
          id: '47301',
          twoFaEnabled: Boolean(channel),
          twoFaVerificationChannels: channels,
          __typename: 'User',
        },
      }),
      ctx.delay(500)
    );
  }
);
