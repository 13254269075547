import { graphql } from 'msw';

export const createCreditCardChargeHandler = graphql.mutation(
  'CreateCreditCardCharge',
  (req, res, ctx) => {
    return res(
      ctx.data({
        __typename: 'Mutation',
        createCreditCardCharge: {
          threedsUrl:
            'https://sandbox-api.openpay.mx/v1/vpos/3dsecure-auth-simulator/tr0aueglorgtu3pmfq57',
          commissions: {
            amountToBeChargedCents: 163_342_25,
            amountCents: 160_000_00,
            userTotalAmountCentsWithCommission: 160_000_00,
            clientCommissionCents: 3_342_25,
            userCommissionCents: 3_342_25,
            creditCardConfig: {
              __typename: 'CreditCardConfig',
              maxTransactionAmountLimitCents: 200_000_00,
              totalCommissionRate: 0.5,
            },
          },
          creditCardCharge: {
            amountChargedCents: 163_342_25,
            clientCommissionCents: 3_342_25,
          },
        },
      })
    );
  }
);
