import { graphql } from 'msw';

export const sendClientsNotificationHandler = graphql.mutation(
  'SendClientsNotification',
  (req, res, ctx) =>
    res(
      ctx.data({
        sendClientNotificationHandler: {
          success: true,
        },
      })
    )
);
