import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const SliderTwo: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M8 13C8.55228 13 9 13.4477 9 14V21C9 21.5523 8.55228 22 8 22C7.44772 22 7 21.5523 7 21V14C7 13.4477 7.44772 13 8 13Z" />
        <path d="M8 2C8.55228 2 9 2.44772 9 3V10C9 10.5523 8.55228 11 8 11C7.44772 11 7 10.5523 7 10V3C7 2.44772 7.44772 2 8 2Z" />
        <path d="M16 11C16.5523 11 17 11.4477 17 12V21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21V12C15 11.4477 15.4477 11 16 11Z" />
        <path d="M16 2C16.5523 2 17 2.44772 17 3V8C17 8.55228 16.5523 9 16 9C15.4477 9 15 8.55228 15 8V3C15 2.44772 15.4477 2 16 2Z" />
        <path d="M4 14C4 13.4477 4.44772 13 5 13H11C11.5523 13 12 13.4477 12 14C12 14.5523 11.5523 15 11 15H5C4.44772 15 4 14.5523 4 14Z" />
        <path d="M12 8C12 7.44772 12.4477 7 13 7H19C19.5523 7 20 7.44772 20 8C20 8.55228 19.5523 9 19 9H13C12.4477 9 12 8.55228 12 8Z" />
      </svg>
    );
  }
);

SliderTwo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SliderTwo.displayName = 'SliderTwo';

export default SliderTwo;
