import { graphql } from 'msw';

export const continuationHandler = graphql.mutation(
  'Continuation',
  (req, res, ctx) => {
    return res(
      ctx.delay(3800),
      ctx.data({
        __typename: 'Mutation',
        continuation: {
          id: '43',
          amountChargedCents: 163_342_25,
          clientCommissionCents: 3_342_25,
          clientUuid: '86d10774-0a3e-4bd8-bfd6-880be86bbc0e',
          providerReceivableIds: ['43', '12'],
          providerReceivablesCount: 2,
          status: 'SUCCESSFUL',
          taxName: 'client',
        },
      })
    );
  }
);
