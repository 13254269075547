import React, { ForwardedRef, forwardRef } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { CurrencySpan, StyledInput } from './CurrencyInput.styles';

import Input from '@ui/Input';

import type { CurrencyInputProps } from './types';

export const parseCurrency = (value?: string | number | null) => {
  if (!value) return null;
  if (typeof value === 'number') return value;
  return parseFloat(value.replace(/(\$|,)/g, ''));
};

function CurrencyInput(
  props: CurrencyInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    currency,
    variant,
    allowNegative,
    decimalLimit,
    ...otherProps
  } = props;
  const numberMask = createNumberMask({
    prefix: '$',
    allowDecimal: true,
    allowNegative,
    allowLeadingZeroes: true,
    requiredDecimal: true,
    decimalLimit,
  });

  return variant === 'bold' ? (
    <StyledInput
      type="tel"
      mask={numberMask}
      endIcon={variant === 'bold' && <CurrencySpan>{currency}</CurrencySpan>}
      {...otherProps}
      ref={ref}
    />
  ) : (
    <Input type="tel" mask={numberMask} {...otherProps} ref={ref} />
  );
}

export default forwardRef(CurrencyInput);
