import React from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import * as S from './FeedbackForm.styles';

import useToast from '@src/hooks/useToast';
import { Button, Header, Text, Textarea } from '@src/components/ui';
import { trackEvent } from '@src/lib/analytics';

import { FlexContainer, Spacer } from '../../layouts';
import FeedbackOption from './FeedbackOption';

type Option = {
  emoji: string;
  value: number;
  label: string;
  color: 'neutral' | 'danger' | 'warning' | 'info' | 'success';
};

type FeedbackFormData = {
  rating?: number;
  message?: string;
};

interface FeedbackFormProps {
  onClose?: () => void;
}

function FeedbackForm({ onClose }: FeedbackFormProps) {
  const intl = useIntl();
  const { toast } = useToast();
  const router = useRouter();
  const {
    register,
    watch,
    setFocus,
    handleSubmit,
  } = useForm<FeedbackFormData>();

  const options: Option[] = [
    {
      emoji: '😡',
      value: 1,
      label: intl.formatMessage({
        defaultMessage: 'Pésima',
        id: 'KJqNT0',
      }),
      color: 'danger',
    },
    {
      emoji: '😟',
      value: 2,
      label: intl.formatMessage({
        defaultMessage: 'Mala',
        id: 'aaOcEW',
      }),
      color: 'warning',
    },
    {
      emoji: '😐',
      value: 3,
      label: intl.formatMessage({
        defaultMessage: 'Normal',
        id: 'myq2ZL',
      }),
      color: 'neutral',
    },
    {
      emoji: '🙂',
      value: 4,
      label: intl.formatMessage({
        defaultMessage: 'Buena',
        id: 'ik2mfd',
      }),
      color: 'info',
    },
    {
      emoji: '🤩',
      value: 5,
      label: intl.formatMessage({
        defaultMessage: 'Excelente',
        id: 'Qg5Jk9',
      }),
      color: 'success',
    },
  ];

  const selectedReaction = watch('rating');
  const textareaPlaceholder = selectedReaction
    ? intl.formatMessage(
        {
          defaultMessage: 'Cuéntanos por qué tu experiencia fue {response}',
          id: 'c5HlBO',
        },
        {
          response: options[selectedReaction - 1].label.toLowerCase(),
        }
      )
    : intl.formatMessage({
        defaultMessage: 'Cuéntanos tu experiencia',
        id: 'PqVHwe',
      });

  const sendFeedback = ({ rating, message }: FeedbackFormData) => {
    trackEvent('Feedback Sent', {
      rating,
      message,
      emoji: rating ? options[rating - 1].emoji : undefined,
      pathname: router.pathname,
    });
    toast.success(
      intl.formatMessage({
        defaultMessage: 'Gracias por dejarnos tus comentarios',
        id: '1u4GlT',
      }),
      intl.formatMessage({
        defaultMessage:
          'Tus comentarios nos servirán para seguir mejorando para ti.',
        id: 'TzcZRS',
      })
    );
    onClose?.();
  };

  return (
    <S.Form onSubmit={handleSubmit(sendFeedback)}>
      <Header as="h5">
        {intl.formatMessage({
          defaultMessage: 'Califica tu experiencia',
          id: 'aTuM2R',
        })}
      </Header>
      <Text color="neutral" colorStep="500">
        {intl.formatMessage({
          defaultMessage:
            '¿Te está gustando Higo? Cuéntanos qué tal ha sido tu experiencia y ayúdanos a mejorar.',
          id: 'CV+vTE',
        })}
      </Text>
      <Spacer />
      <FlexContainer justifyContent="space-between">
        {options.map((option) => (
          <FeedbackOption
            key={option.value}
            emoji={option.emoji}
            label={option.label}
            value={option.value}
            color={option.color}
            onClick={() => setFocus('message')}
            {...register('rating')}
          />
        ))}
      </FlexContainer>
      <Spacer />
      <Textarea
        rows={5}
        required
        {...register('message')}
        placeholder={textareaPlaceholder}
      />
      <Spacer />
      <FlexContainer justifyContent="flex-end">
        <Button variant="ghosted" onClick={onClose}>
          {intl.formatMessage({
            defaultMessage: 'Cancelar',
            id: 'nZLeaQ',
          })}
        </Button>
        <Spacer direction="horizontal" />
        <Button type="submit">
          {intl.formatMessage({
            defaultMessage: 'Enviar',
            id: 'hKJZJR',
          })}
        </Button>
      </FlexContainer>
    </S.Form>
  );
}

FeedbackForm.displayName = 'FeedbackForm';

export default FeedbackForm;
