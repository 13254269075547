import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import Storage from 'local-storage-fallback';
import { useRouter } from 'next/router';
import { useLazyQuery, useQuery } from '@apollo/client';

import Loader from '@shared/Loader';
import { CURRENT_BUSINESS_ID } from '@src/constants/storageKeys';
import CURRENT_USER_QUERY, {
  CurrentUserReturn,
} from '@src/graphql/queries/currentUser';
import {
  LIST_BUSINESS_FEATURE_FLAGS,
  ListBusinessFeatureInputType,
  ListBusinessFeatureInputTypeReturn,
} from '@src/graphql/queries/listBusinessFeatureFlags';
import { setUser, trackGroup } from '@src/lib/analytics';
import { loggedIn as isTokenStored } from '@src/lib/auth';
import { Business, BusinessFeatureFlag, CurrentUser } from '@src/types/models';

import { CustomWindow } from '../../../custom.window';
import { getCurrentBusiness, getCurrentUser } from './utils';

export type SettingsContext = {
  business?: Business;
  currentUser?: CurrentUser;
  invitationToken?: string;
  businessFeatureFlags?: BusinessFeatureFlag[];
  setBusinessFeatureFlags: (businessFeatureFlag: BusinessFeatureFlag[]) => void;
  setInvitationToken?: Dispatch<SetStateAction<string>>;
  refetch: () => void;
  reset: () => void;
  setCurrentBusinessId: (businessId: string) => void;
};

export const defaultContext = {
  business: undefined,
  currentUser: undefined,
  refetch: () => undefined,
  reset: () => undefined,
  setBusinessFeatureFlags: () => undefined,
  setCurrentBusinessId: (id: string) =>
    Storage.setItem(CURRENT_BUSINESS_ID, id),
};

export const SettingsProviderContext = React.createContext<SettingsContext>(
  defaultContext
);

interface SettingsProviderProps {
  business?: Business;
  currentUser?: CurrentUser;
  children: ReactNode;
}

declare const window: CustomWindow;

function SettingsProvider({ children }: SettingsProviderProps) {
  const router = useRouter();
  const [settings, setSettings] = useState<SettingsContext>(defaultContext);
  const [invitationToken, setInvitationToken] = useState<string>('');

  const setBusinessFeatureFlags = (
    businessFeatureFlag: BusinessFeatureFlag[]
  ) => {
    setSettings((prev) => {
      return { ...prev, businessFeatureFlags: businessFeatureFlag };
    });
  };

  const [getFeatureFlags] = useLazyQuery<
    ListBusinessFeatureInputTypeReturn,
    ListBusinessFeatureInputType
  >(LIST_BUSINESS_FEATURE_FLAGS, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setBusinessFeatureFlags(response.listBusinessFeatureFlags);
    },
  });

  const { loading, refetch } = useQuery<CurrentUserReturn>(CURRENT_USER_QUERY, {
    skip: !isTokenStored(),
    onCompleted: async ({ currentUser }) => {
      if (!currentUser) return;
      const storedBusinessId =
        Storage.getItem(CURRENT_BUSINESS_ID) || undefined;
      const storedBusiness = getCurrentBusiness(currentUser, storedBusinessId);

      if (!storedBusinessId && storedBusiness) {
        Storage.setItem(CURRENT_BUSINESS_ID, storedBusiness.id);
      }

      const reset = () => {
        setSettings(defaultContext);
      };

      const handleBusinessChange = (id: string) => {
        Storage.setItem(CURRENT_BUSINESS_ID, id);
        const business = getCurrentBusiness(currentUser, id);
        if (business) {
          setSettings({
            business,
            currentUser: getCurrentUser(currentUser, business),
            refetch,
            reset,
            setCurrentBusinessId: handleBusinessChange,
            setBusinessFeatureFlags,
          });
          getFeatureFlags({ variables: { businessId: id } });
        }
      };

      const setContext = () =>
        setSettings({
          business: storedBusiness,
          currentUser: getCurrentUser(currentUser, storedBusiness),
          refetch,
          reset,
          setCurrentBusinessId: handleBusinessChange,
          setBusinessFeatureFlags,
        });

      if (!storedBusiness) {
        await router.replace('/onboarding');
        setContext();
      } else {
        setContext();
        trackGroup(storedBusiness.id);
      }

      setUser({
        id: currentUser.id,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        email: currentUser.email,
        businessId: storedBusiness?.id,
        businessName: storedBusiness?.name,
      });

      if (window.Intercom) {
        window.Intercom('boot', {
          api_base: 'https://api-iam.intercom.io',
          app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
          name: currentUser.firstName,
          user_id: currentUser.id,
          email: currentUser.email,
          company: {
            name: storedBusiness?.name,
            company_id: storedBusiness?.id,
          },
        });
      }
      getFeatureFlags({ variables: { businessId: storedBusiness?.id } });
    },
    onError: () => {
      router.replace('/500');
    },
  });

  if ((isTokenStored() && !settings.currentUser) || loading) {
    return (
      <div>
        <div />
        <Loader fullWidth />
      </div>
    );
  }

  return (
    <SettingsProviderContext.Provider
      value={{ ...settings, invitationToken, setInvitationToken }}
    >
      {children}
    </SettingsProviderContext.Provider>
  );
}

export default SettingsProvider;
