import React, { ForwardedRef, forwardRef, useMemo, useState } from 'react';

import * as S from './Dropdown.styles';

import { ChevronDown, ChevronUp, Close, Info } from '@ui/Icon/outline';
import Menu from '@ui/Menu';
import Tooltip from '@ui/Tooltip';

import type { DropdownProps } from './types';

function Dropdown(
  {
    children: childrenProp,
    value,
    fullWidth,
    selectedNode,
    rows,
    label,
    disabled,
    error,
    color = 'neutral',
    variant = 'outlined',
    tooltipTitle,
    isbutton = true,
    placeholder = 'Seleccionar proveedor',
    showClear = true,
    setFilterProviders,
    onChange,
    onBlur,
  }: DropdownProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedChild, setSelectedChild] = useState<React.ReactNode>(null);
  const [anchor, setAnchor] = useState<HTMLElement>();
  const endIcon = useMemo(() => {
    if (tooltipTitle) {
      return (
        <Tooltip
          title={tooltipTitle}
          id="dropdown-tooltip"
          place="top"
          effect="solid"
          width={240}
        >
          <Info />
        </Tooltip>
      );
    }
    if (disabled) {
      return null;
    }
    return <ChevronDown />;
  }, [disabled, tooltipTitle]);

  const children = useMemo(
    () =>
      React.Children.map(childrenProp, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        const childValue = child.props.value;
        const selected =
          childValue && childValue !== 'new' && value && childValue === value;

        if (selected) {
          setSelectedChild(child);
        }

        const newOnClick = () => {
          child?.props?.onClick && child.props.onClick();
          setOpen(false);
        };

        return React.cloneElement(child, {
          // @ts-ignore
          onChange,
          selected,
          onClick: newOnClick,
        });
      }),
    [childrenProp, value]
  );

  const handleButtonFocus = () => {
    !disabled && setOpen(!open);
  };

  const handleMenuBlur = () => {
    setOpen(false);
    onBlur && onBlur();
  };

  const mergeRefs = (element: HTMLDivElement) => {
    setAnchor(element);
    if (element) {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        ref.current = element;
      }
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    if (setFilterProviders) {
      setFilterProviders(event.target.value);
    }
  };

  const clearSearch = () => {
    if (setFilterProviders && onChange) {
      setFilterProviders('');
      onChange(undefined);
      const input = document.getElementById('input-dropdown');
      // @ts-ignore
      if (input) input.value = '';
    }
  };

  return (
    <S.DropdownWrapper fullWidth={fullWidth}>
      {isbutton ? (
        <S.DropdownButton
          error={error}
          ref={mergeRefs}
          type="button"
          open={open}
          color={color}
          variant={variant}
          onClick={() => setOpen(true)}
          fullWidth={fullWidth}
          endIcon={endIcon}
          disabled={disabled}
        >
          {selectedNode || label || selectedChild}
        </S.DropdownButton>
      ) : (
        <S.InputWrapper>
          <S.DropdownInput
            id="input-dropdown"
            error={error}
            // @ts-ignore
            ref={mergeRefs}
            open={open}
            color={color}
            variant={variant}
            onFocus={handleButtonFocus}
            onClick={(event) => event.currentTarget.focus()}
            onChange={onInputChange}
            fullWidth={fullWidth}
            endIcon={endIcon}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            autoComplete="off"
          ></S.DropdownInput>

          {value && showClear && (
            <S.IconContainer onClick={clearSearch}>
              <Close size={'15'} />
            </S.IconContainer>
          )}
          <S.IconContainer onClick={() => setOpen((prev) => !prev)}>
            {open ? <ChevronUp size={'15'} /> : <ChevronDown size={'15'} />}
          </S.IconContainer>
        </S.InputWrapper>
      )}
      <Menu
        onBlur={handleMenuBlur}
        open={open}
        anchorEl={anchor}
        rows={rows}
        placement="bottom-start"
      >
        {children}
      </Menu>
    </S.DropdownWrapper>
  );
}

export default forwardRef(Dropdown);
