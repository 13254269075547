import { useContext } from 'react';

import { SettingsProviderContext } from '@src/components/SettingsProvider';
import { SettingsContext } from '@src/components/SettingsProvider/SettingsProvider';

function useSettings(): SettingsContext;
function useSettings<T extends keyof SettingsContext>(
  key: T
): SettingsContext[T];
function useSettings<T extends keyof SettingsContext>(
  key?: T
): SettingsContext | SettingsContext[T] {
  const settings = useContext(SettingsProviderContext);
  if (key && key in settings) {
    return settings[key];
  }

  return settings;
}

export default useSettings;
