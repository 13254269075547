import { gql } from '@apollo/client';

import { ClientRelationship } from '@src/types/models';

const CLIENT_RELATIONSHIPS_ATTRIBUTES = gql`
  fragment ClientRelationshipsAttributes on BusinessRelationship {
    id
    status
    clientId
    clientName
    clientContact {
      id
      email
    }
    paymentTerms
  }
`;

export type ClientRelationshipAttributes = Pick<
  ClientRelationship,
  'id' | 'status' | 'clientId' | 'clientName' | 'clientContact' | 'paymentTerms'
>;

export default CLIENT_RELATIONSHIPS_ATTRIBUTES;
