import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import * as S from './Drawer.styles';

import Backdrop from '@ui/Backdrop';
import { X as Close } from '@ui/Icon/outline';
import IconButton from '@ui/IconButton';

import type { DrawerProps } from './types';

export default function Drawer({
  children,
  open,
  container,
  CardProps,
  onClose,
  ...otherProps
}: DrawerProps) {
  useEffect(() => {
    const handleEscapeKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKeydown);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeydown);
    };
  }, []);

  if (typeof document === 'undefined') {
    return null;
  }

  const containerElement = container && document.querySelector(container);
  const portalContainer = containerElement || document.body;

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onClose) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <Backdrop open={open}>
      <CSSTransition
        in={open}
        timeout={{
          appear: 200,
          enter: 200,
          exit: 200,
        }}
        classNames="drawer"
        appear
        mountOnEnter
        unmountOnExit
      >
        <S.Container>
          <S.Card fullHeight fullWidth {...otherProps} {...CardProps}>
            {children}
            {onClose && (
              <S.CloseButton>
                <IconButton
                  onClick={handleClose}
                  variant="transparent"
                  color="neutral"
                  rounded
                >
                  <Close />
                </IconButton>
              </S.CloseButton>
            )}
          </S.Card>
        </S.Container>
      </CSSTransition>
    </Backdrop>,
    portalContainer
  );
}
