import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: {
      '900': '#050C57',
      '800': '#07127D',
      '700': '#0A17A4',
      '600': '#0C1DCA',
      '500': '#0E22F1',
      '400': '#3C4CF4',
      '300': '#6A76F6',
      '200': '#97A0F9',
      '100': '#D6D8FF',
      '50': '#F3F4FE',
    },
    secondary: {
      '900': '#004D34',
      '800': '#006E4A',
      '700': '#008E61',
      '600': '#00AC77',
      '500': '#00C88C',
      '400': '#01E3A0',
      '300': '#39F9BC',
      '200': '#82FFD9',
      '100': '#ACFFE8',
      '50': '#E6FFF9',
    },
    success: {
      '900': '#002217',
      '800': '#00432E',
      '700': '#006545',
      '600': '#00865C',
      '500': '#00A873',
      '400': '#33B98F',
      '300': '#66CBAB',
      '200': '#99DCC7',
      '100': '#CCEEE3',
      '50': '#E6F6F1',
    },
    warning: {
      '900': '#2E210D',
      '800': '#5D421A',
      '700': '#8B6226',
      '600': '#BA8333',
      '500': '#E8A440',
      '400': '#EDB666',
      '300': '#F1C88C',
      '200': '#F6DBB3',
      '100': '#FAEDD9',
      '50': '#FDF6EC',
    },
    danger: {
      '900': '#2D0D09',
      '800': '#5B1A12',
      '700': '#88271B',
      '600': '#B63424',
      '500': '#E3412D',
      '400': '#E96757',
      '300': '#EE8D81',
      '200': '#F4B3AB',
      '100': '#F9D9D5',
      '50': '#FCECEA',
    },
    info: {
      '900': '#0A182C',
      '800': '#153058',
      '700': '#1F4884',
      '600': '#2A60B0',
      '500': '#3478DC',
      '400': '#5D93E3',
      '300': '#85AEEA',
      '200': '#AEC9F1',
      '100': '#D6E4F8',
      '50': '#EBF2FC',
    },
    neutral: {
      '900': '#101121',
      '800': '#282937',
      '700': '#40414D',
      '600': '#64646F',
      '500': '#8D8E96',
      '400': '#ABACB1',
      '300': '#C3C3C7',
      '200': '#E9E9ED',
      '100': '#F6F7F9',
      '50': '#FFFFFF',
    },
  },
  spacing: {
    0: '0px',
    px: '1px',
    0.5: '0.125rem',
    1: '0.25rem',
    1.5: '0.375rem',
    2: '0.5rem',
    2.5: '0.625rem',
    3: '0.75rem',
    3.5: '0.875rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    11: '2.75rem',
    12: '3rem',
    14: '3.5rem',
    15: '3.75rem',
    16: '4rem',
    18: '4.5rem',
    19: '4.75rem',
    20: '5rem',
    24: '6rem',
    28: '7rem',
    32: '8rem',
    36: '9rem',
    40: '10rem',
    44: '11rem',
    48: '12rem',
    52: '13rem',
    56: '14rem',
    64: '16rem',
    72: '18rem',
    80: '20rem',
    96: '24rem',
    112: '28rem',
    128: '32rem',
  },
  screens: {
    sm: 480,
    md: 720,
    lg: 1024,
    xl: 1200,
  },
  fontFamily: {
    sans: 'Apercu, sans-serif',
  },
  fontSize: {
    '2xs': '0.625rem',
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
    '2xl': '1.875rem',
    '3xl': '2.2rem',
    '4xl': '3.75rem',
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  boxShadow: {
    sm: '5px 5px 10px rgba(38, 58, 167, 0.05)',
    md: '5px 5px 15px rgba(38, 58, 167, 0.1)',
    lg: '8px 8px 20px rgba(11, 7, 31, 0.08)',
  },
  opacity: {
    '5': 0.05,
    '10': 0.1,
    '20': 0.2,
    '40': 0.4,
    '80': 0.8,
  },
  opacityHex: {
    '5': '0D',
    '10': '1A',
    '20': '33',
    '40': '66',
    '80': 'CC',
    '100': 'FF',
  },
  zIndex: {
    '0': 0,
    '10': 10,
    '20': 20,
    '30': 30,
    '40': 40,
    '50': 50,
  },
};

export default theme;
