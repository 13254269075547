import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ShieldCheck: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <g clipPath="url(#clip0_1166_3076)">
          <path
            d="M13.296 1.76144L8.00004 0.00610352L2.70404 1.76144C2.30502 1.89371 1.95783 2.14836 1.71178 2.48919C1.46572 2.83003 1.33332 3.23973 1.33337 3.6601V8.0001C1.33337 13.0161 7.46671 15.7861 7.72937 15.9014L7.96537 16.0061L8.21071 15.9248C8.47471 15.8368 14.6667 13.7181 14.6667 8.0001V3.6601C14.6668 3.23973 14.5344 2.83003 14.2883 2.48919C14.0423 2.14836 13.6951 1.89371 13.296 1.76144ZM8.29604 9.3261C8.1795 9.44343 8.04081 9.53643 7.88802 9.59972C7.73524 9.66301 7.57141 9.69532 7.40604 9.69477H7.38404C7.2153 9.69219 7.04884 9.65541 6.89472 9.58665C6.7406 9.5179 6.60202 9.41861 6.48737 9.29477L4.95004 7.69477L5.91204 6.77344L7.40804 8.33344L10.862 4.87944L11.8047 5.8221L8.29604 9.3261Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_1166_3076">
            <rect width={size} height={size} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

ShieldCheck.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ShieldCheck.displayName = 'ShieldCheck';

export default ShieldCheck;
