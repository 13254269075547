import { addSeenFeatureAnnouncementHandler } from './addSeenFeatureAnnouncement';
import { addTwoFaChannelHandler } from './addTwoFaChannel';
import { createCreditCardChargeHandler } from './createCreditCharge';
import { createInvoiceHandler } from './createInvoice';
import { CreateReminderScheduleHandler } from './createReminderSchedule';
import { createTermLoanHandler } from './createTermLoan';
import { disableTwoFaHandler } from './disableTwoFa';
import { continuationHandler } from './holaCashContinuation';
import { loginHandler } from './login';
import { payInstallmentHandler } from './payInstallment';
import { saveIssuerHandler } from './saveIssuer';
import { sendClientsNotificationHandler } from './sendClientsNotification';
import { sendVerificationCodeHandler } from './sendVerificationCode';
import { UpdateReminderScheduleHandler } from './updateReminderSchedule';
import { updateUserEmailHandler } from './updateUserEmail';
import { updateUserPhoneHandler } from './updateUserPhone';
import { verifyLoginHandler } from './verifyLogin';

const mutations = [
  loginHandler,
  addTwoFaChannelHandler,
  sendVerificationCodeHandler,
  createCreditCardChargeHandler,
  updateUserEmailHandler,
  updateUserPhoneHandler,
  verifyLoginHandler,
  disableTwoFaHandler,
  continuationHandler,
  createTermLoanHandler,
  payInstallmentHandler,
  sendClientsNotificationHandler,
  addSeenFeatureAnnouncementHandler,
  CreateReminderScheduleHandler,
  UpdateReminderScheduleHandler,
  saveIssuerHandler,
  createInvoiceHandler,
];

export default mutations;
