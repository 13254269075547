import NextLink from 'next/link';
import styled from 'styled-components';

interface LinkProps {
  $block?: boolean;
}

export const Link = styled(NextLink)<LinkProps>`
  display: ${({ $block }) => $block && 'block'};
  width: ${({ $block }) => $block && '100%'};
`;
