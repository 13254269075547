import React from 'react';

import * as S from './ModalHeader.styles';

import { X as Close } from '@ui/Icon/outline';
import IconButton from '@ui/IconButton';

import type { ModalHeaderProps } from './types';

function ModalHeader({ title, onClose, actions, sticky }: ModalHeaderProps) {
  return (
    <S.ModalHeaderContainer sticky={sticky}>
      <S.HeaderInfo>
        <S.CloseButton>
          <IconButton
            onClick={onClose}
            variant="transparent"
            rounded
            color="neutral"
          >
            <Close />
          </IconButton>
        </S.CloseButton>
        {title && <S.Title>{title}</S.Title>}
      </S.HeaderInfo>
      {actions && actions}
    </S.ModalHeaderContainer>
  );
}

export default ModalHeader;
