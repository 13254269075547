import styled from 'styled-components';

import Box, { BaseBoxProps } from '../Box/Box';

const FlexRowContainer = styled(Box)<BaseBoxProps>`
  display: flex;
  flex-direction: row;
`;

export default FlexRowContainer;
