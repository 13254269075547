import { graphql } from 'msw';

export const receivablesPendingCollectionAmountHandler = graphql.query(
  'ReceivablesPendingCollectionAmount',
  (req, res, ctx) => {
    const { startDate, endDate } = req.variables;

    if (startDate && endDate) {
      return res(
        ctx.data({
          receivablesPendingCollectionAmount: {
            totalAmountCents: Math.round(Math.random() * 500000),
          },
        })
      );
    }

    return res(
      ctx.data({
        receivablesPendingCollectionAmount: {
          totalAmountCents: 10000,
        },
      })
    );
  }
);
