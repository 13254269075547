import React, { forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const FolderMinus: Icon = forwardRef(
  ({ color = 'currentColor', size = 18, ...rest }, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 18 18"
        fill={color}
        {...rest}
      >
        <path
          d="M6.5415 0.666641C7.31734 0.65845 8.0415 1.01678 8.5165 1.62512L9.24984 2.60012C9.48317 2.89178 9.83317 3.06678 10.2082 3.06678H12.7665C15.8415 3.06678 17.3415 4.70845 17.3332 8.07512V12.1334C17.3332 15.3501 15.3498 17.3334 12.1248 17.3334H5.8665C2.65817 17.3334 0.666504 15.3501 0.666504 12.1251V5.86678C0.666504 2.41678 2.19984 0.666641 5.22484 0.666641H6.5415ZM12.8582 10.4918H5.1415C4.7915 10.4918 4.5165 10.7668 4.5165 11.1168C4.5165 11.4584 4.7915 11.7418 5.1415 11.7418H12.8582C13.1998 11.7418 13.4748 11.4584 13.4748 11.1168C13.4748 10.7668 13.1998 10.4918 12.8582 10.4918Z"
          fill={color}
        />
      </svg>
    );
  }
);

FolderMinus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FolderMinus.displayName = 'FolderMinus';

export default FolderMinus;
