import { ApolloLink } from '@apollo/client';

import { get as getAuthToken } from '@src/lib/auth';

const authLink = new ApolloLink((operation, forward) => {
  const token = getAuthToken();

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});

export default authLink;
