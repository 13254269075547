import styled from 'styled-components';

import Button, { IconWrapper } from '../Button';
import { IconButtonProps } from './types';

export const StyledIconButton = styled(Button)<IconButtonProps>`
  height: ${({ theme, compact }) =>
    compact ? theme.spacing[8] : theme.spacing[11]};
  width: ${({ theme, compact }) =>
    compact ? theme.spacing[8] : theme.spacing[11]};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ rounded }) => rounded && '50%'};

  ${IconWrapper} {
    max-width: ${({ theme, compact }) =>
      compact ? theme.spacing[4] : theme.spacing[6]};
    max-height: ${({ theme, compact }) =>
      compact ? theme.spacing[4] : theme.spacing[6]};
  }
`;
