import styled, { css } from 'styled-components';

type StyledCheckboxProps = {
  variant?: string;
  buffer?: boolean;
  disabled?: boolean;
};

const position = css`
  position: relative;
  display: flex;
  align-items: center;
`;

const checkbox = css`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: ${({ theme }) => theme.spacing[4]};
  height: ${({ theme }) => theme.spacing[4]};

  &:indeterminate ~ .indeterminate {
    display: block;
    background-color: ${({ theme }) => theme.colors.primary[500]};
    border-color: ${({ theme }) => theme.colors.primary[500]};
    color: ${({ theme }) => theme.colors.neutral[50]};
  }

  &:indeterminate ~ .checkmark {
    display: none;
  }

  &:hover ~ .checkmark {
    background-color: #ccc;
    border-color: #ccc;
  }
  &:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.primary[500]};
    border-color: ${({ theme }) => theme.colors.primary[500]};
  }
`;

const pseudoCheckbox = css<StyledCheckboxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: ${({ theme }) => theme.spacing[4]};
  height: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.neutral[50]};
  background-color: ${({ theme }) => theme.colors.neutral[50]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[300]};
  border-radius: ${({ theme }) => theme.spacing[1]};
  font-size: ${({ theme }) => theme.spacing[2.5]};
  font-weight: bold;
  cursor: pointer;

  ${({ variant }) =>
    variant === 'lg' &&
    css`
      border-radius: ${({ theme }) => theme.spacing[2]};
      height: ${({ theme }) => theme.spacing[6]};
      width: ${({ theme }) => theme.spacing[6]};
      font-size: ${({ theme }) => theme.fontSize.sm};
    `}
`;

export const CheckboxOnly = styled.div<StyledCheckboxProps>`
  ${position}

  input[type='checkbox'] {
    ${checkbox}
  }

  .checkmark,
  .indeterminate {
    ${pseudoCheckbox}
  }

  .indeterminate {
    display: none;
  }

  label {
    margin: 0;
    line-height: 1;
    padding: ${({ theme, buffer }) => buffer && theme.spacing[3]};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const CheckboxLabeled = styled.div<StyledCheckboxProps>`
  ${position}

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  input[type='checkbox'] {
    ${checkbox}
  }

  .checkmark,
  .indeterminate {
    ${pseudoCheckbox}
  }

  .indeterminate {
    display: none;
  }

  label {
    font-variant: ${({ theme }) => theme.fontSize.sm};
    margin: 0 0 0 ${({ theme }) => theme.spacing[2.5]};
  }
`;
