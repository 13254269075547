import { graphql } from 'msw';

export const addTwoFaChannelHandler = graphql.mutation(
  'AddTwoFaChannel',
  (req, res, ctx) => {
    const { channel, verificationCode } = req.variables;

    return res(
      ctx.data({
        addTwoFaChannel: {
          email: 'hugo@higo.io',
          firstName: 'Hugo',
          id: '47301',
          initials: 'HL',
          lastName: 'Licon Valenzuela',
          phone: '+52 614 181 6618',
          twoFaEnabled: true,
          twoFaVerificationChannels: ['email', 'phone'],
          phoneVerified: channel === 'phone' ? new Date() : null,
          emailVerified: channel === 'email' ? new Date() : null,
          __typename: 'User',
        },
      }),
      ctx.delay(500)
    );
  }
);
