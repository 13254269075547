import { graphql } from 'msw';

export const updateUserEmailHandler = graphql.mutation(
  'UpdateUserEmail',
  (req, res, ctx) => {
    const { email } = req.variables;

    return res(
      ctx.data({
        updateUserEmail: {
          id: '47301',
          email,
          emailVerifiedAt: '2020-01-01T00:00:00.000Z',
          __typename: 'User',
        },
      })
    );
  }
);
