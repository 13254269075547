import styled from 'styled-components';

import Box, { BaseBoxProps } from '../Box/Box';

const FlexColumnContainer = styled(Box)<BaseBoxProps>`
  display: flex;
  flex-direction: column;
`;

export default FlexColumnContainer;
