import { graphql } from 'msw';

export const updateUserPhoneHandler = graphql.mutation(
  'UpdateUserPhone',
  (req, res, ctx) => {
    const { phone, verificationCode } = req.variables;

    return res(
      ctx.data({
        updateUserPhone: {
          id: '47301',
          phone,
          phoneVerifiedAt: '2020-01-01T00:00:00.000Z',
          __typename: 'User',
        },
      })
    );
  }
);
