import { CSSObject } from 'react-select/node_modules/@emotion/serialize/types/index';

import theme from '@src/styles/theme';

export const styles = ({ error }: { error: boolean }) => ({
  control: (
    provided: CSSObject,
    { isFocused, isHovered }: { isFocused: boolean; isHovered: boolean }
  ) => ({
    ...provided,
    backgroundColor: theme.colors.neutral[100],
    borderWidth: 0,
    borderColor: 'none',
    borderRadius: theme.spacing[1],
    boxShadow: `inset 0 0 0 1px ${
      error
        ? theme.colors.danger[500]
        : isFocused || isHovered
        ? theme.colors.primary[500]
        : 'transparent'
    }`,
    height: theme.spacing[9],
    boxSizing: 'border-box',
  }),
  placeholder: (provided: CSSObject) => ({
    ...provided,
    color: error ? theme.colors.danger[500] : theme.colors.neutral[300],
    fontWeight: theme.fontWeight.light,
  }),
  indicatorSeparator: (provided: CSSObject) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided: CSSObject) => ({
    ...provided,
    marginRight: theme.spacing[2],
  }),
  container: (provided: CSSObject) => ({
    ...provided,
    height: theme.spacing[9],
    width: '100%',
  }),
  menuPortal: (provided: CSSObject) => ({
    ...provided,
    zIndex: theme.zIndex[50],
  }),
  option: (
    provided: CSSObject,
    { isSelected, isDisabled }: { isSelected: boolean; isDisabled: boolean }
  ) => ({
    ...provided,
    color: isSelected ? theme.colors.neutral[50] : theme.colors.neutral[900],
    opacity: isDisabled ? 0.5 : 1,
    fontSize: theme.fontSize.sm,
    backgroundColor: isSelected
      ? theme.colors.primary[500]
      : theme.colors.neutral[50],
    '&:hover': {
      backgroundColor: theme.colors.primary[100],
      color: theme.colors.neutral[900],
    },
  }),
  valueContainer: (provided: CSSObject) => ({
    ...provided,
    flexWrap: 'no-wrap',
    overflowX: 'auto',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  }),
});
