import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';

import { MenuCard } from './Menu.styles';

import type { MenuProps } from './types';

function Menu(props: MenuProps) {
  const {
    children,
    placement = 'bottom-start',
    anchorEl,
    open,
    rows,
    container,
    onBlur,
    offset,
    width,
  } = props;
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(anchorEl, popperElement, {
    placement,
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        phase: 'read',
        options: {
          offset: offset || [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    const handleAnchorBlur = (event: FocusEvent) => {
      event.preventDefault();
      setTimeout(() => {
        onBlur && onBlur();
      }, 200);
    };

    if (anchorEl) {
      anchorEl?.addEventListener('blur', handleAnchorBlur);
    }

    return () => {
      anchorEl?.removeEventListener('blur', handleAnchorBlur);
    };
  }, [anchorEl, onBlur]);

  if (typeof document === 'undefined') {
    return null;
  }

  const containerElement = container && document.querySelector(container);
  const portalContainer = containerElement || document.body;

  const anchorElWidth = anchorEl?.offsetWidth && `${anchorEl?.offsetWidth}px`;

  return ReactDOM.createPortal(
    <MenuCard
      open={open}
      width={width ?? anchorElWidth}
      depth="lg"
      ref={setPopperElement as any}
      style={{
        ...styles.popper,
        zIndex: 60,
      }}
      rows={rows}
      {...attributes.popper}
    >
      {children}
    </MenuCard>,
    portalContainer
  );
}

export default Menu;
