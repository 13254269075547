import React, { ForwardedRef, forwardRef } from 'react';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { styles } from './Select.styles';

import Card from '@ui/Card';
import { ChevronDown, X as Close } from '@ui/Icon/outline';
import IconButton from '@ui/IconButton';
import Spinner from '@ui/Spinner';
import Tag from '@ui/Tag';
import theme from '@src/styles/theme';

import type { SelectProps } from './types';

interface MenuProps {
  innerProps: any;
  children: React.ReactNode;
}

interface SelectComponentProps {
  cx: string;
  data: any;
  selectProps: any;
  children: React.ReactNode;
  setValue: (value: any) => void;
  clearValue: () => void;
}

const LoadingIndicator = (props: any) => (
  <Spinner {...props} theme={theme} contrast />
);

const ClearIndicator = ({ cx, ...props }: SelectComponentProps) => (
  <IconButton
    {...props}
    onClick={props.clearValue}
    theme={theme}
    color="neutral"
    rounded
    compact
    variant="ghosted"
  >
    <Close />
  </IconButton>
);

const DropdownIndicator = ({ cx, ...props }: SelectComponentProps) => (
  <IconButton
    {...props}
    theme={theme}
    color="neutral"
    rounded
    compact
    variant="ghosted"
  >
    <ChevronDown />
  </IconButton>
);

const MultiValue = ({ cx, ...props }: SelectComponentProps) => (
  <Tag
    {...props}
    compact
    color="light"
    label={props.children}
    onClick={() => {
      const value = Array.isArray(props.selectProps.value)
        ? props.selectProps.value
        : [props.selectProps.value].filter(Boolean);
      props.setValue(value.filter((option: any) => option !== props.data));
    }}
    endIcon={<Close />}
    theme={theme}
    style={{
      marginRight: 4,
    }}
  />
);

const Menu = ({ innerProps, children }: MenuProps) => (
  <Card {...innerProps} theme={theme} depth="sm" verticalPadding="2">
    {children}
  </Card>
);

const selectTheme = (reactSelectTheme: any) => ({
  ...reactSelectTheme,
  borderRadius: 0,
  colors: {
    primary: theme.colors.primary[500],
    primary75: theme.colors.primary[300],
    primary50: theme.colors.primary[100],
    primary25: theme.colors.primary[50],
    danger: theme.colors.danger[500],
    dangerLight: theme.colors.danger[200],
    neutral0: theme.colors.neutral[50],
    neutral5: theme.colors.neutral[50],
    neutral10: theme.colors.neutral[100],
    neutral20: theme.colors.neutral[200],
    neutral30: theme.colors.neutral[300],
    neutral40: theme.colors.neutral[400],
    neutral50: theme.colors.neutral[500],
    neutral60: theme.colors.neutral[600],
    neutral70: theme.colors.neutral[700],
    neutral80: theme.colors.neutral[800],
    neutral90: theme.colors.neutral[900],
  },
});

const SelectComponent = forwardRef(
  ({ isCreateable, ...otherProps }: SelectProps, ref) =>
    isCreateable ? (
      <CreatableSelect ref={ref as any} {...otherProps} />
    ) : (
      <ReactSelect ref={ref as any} {...otherProps} />
    )
);

const Select = (
  { customStyles, components, error, id, ...otherProps }: SelectProps,
  ref: ForwardedRef<HTMLSelectElement>
) => (
  <SelectComponent
    ref={ref}
    inputId={id}
    {...otherProps}
    styles={{ ...styles({ error }), ...customStyles }}
    components={{
      ...components,
      LoadingIndicator,
      ClearIndicator,
      DropdownIndicator,
      MultiValue,
      Menu,
    }}
    theme={selectTheme}
    menuPortalTarget={
      typeof document !== 'undefined' ? document.body : undefined
    }
    menuPlacement="bottom"
  />
);
export default forwardRef(Select);
