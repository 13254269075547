import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const CreditCard: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <g clipPath="url(#clip0_4888_1579)">
          <path
            d="M15.8333 2.5H4.16667C3.062 2.50132 2.00296 2.94073 1.22185 3.72185C0.440735 4.50296 0.00132321 5.562 0 6.66667H20C19.9987 5.562 19.5593 4.50296 18.7782 3.72185C17.997 2.94073 16.938 2.50132 15.8333 2.5V2.5Z"
            fill={color}
          />
          <path
            d="M0 13.333C0.00132321 14.4377 0.440735 15.4967 1.22185 16.2778C2.00296 17.0589 3.062 17.4984 4.16667 17.4997H15.8333C16.938 17.4984 17.997 17.0589 18.7782 16.2778C19.5593 15.4967 19.9987 14.4377 20 13.333V8.33301H0V13.333ZM5.83333 12.9163C5.83333 13.1636 5.76002 13.4052 5.62267 13.6108C5.48532 13.8164 5.2901 13.9766 5.06169 14.0712C4.83328 14.1658 4.58195 14.1906 4.33947 14.1423C4.09699 14.0941 3.87427 13.975 3.69945 13.8002C3.52463 13.6254 3.40558 13.4027 3.35735 13.1602C3.30912 12.9177 3.33387 12.6664 3.42848 12.438C3.52309 12.2096 3.68331 12.0144 3.88887 11.877C4.09443 11.7397 4.33611 11.6663 4.58333 11.6663C4.91485 11.6663 5.2328 11.798 5.46722 12.0325C5.70164 12.2669 5.83333 12.5848 5.83333 12.9163"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_4888_1579">
            <rect width={size} height={size} fill={color} />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

CreditCard.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CreditCard.displayName = 'CreditCard';

export default CreditCard;
