import React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import { AlertTriangle, CheckCircle, ErrorDiamond, Info } from '@ui/Icon/solid';
import { Content } from '@ui/Toast/Toast';

export type ToastFunction = (
  message: string | React.ReactNode,
  description?: string | React.ReactNode,
  options?: ToastOptions
) => void;

const error: ToastFunction = (message, description = '', options = {}) =>
  toast.error(<Content message={message} description={description} />, {
    ...options,
    theme: 'colored',
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    icon: <ErrorDiamond size={20} color="white" />,
    containerId: 'toast-container',
  });

const info: ToastFunction = (message, description = '', options = {}) =>
  toast.info(<Content message={message} description={description} />, {
    ...options,
    theme: 'colored',
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    icon: <Info size={20} color="white" />,
    containerId: 'toast-container',
  });

const warning: ToastFunction = (message, description = '', options = {}) =>
  toast.warning(<Content message={message} description={description} />, {
    ...options,
    theme: 'colored',
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    icon: <AlertTriangle size={20} color="white" />,
    containerId: 'toast-container',
  });

const success: ToastFunction = (message, description = '', options = {}) =>
  toast.success(<Content message={message} description={description} />, {
    ...options,
    theme: 'colored',
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    icon: <CheckCircle size={20} color="white" />,
    containerId: 'toast-container',
  });

export const toastr = {
  error,
  info,
  warning,
  success,
};

export default toastr;
