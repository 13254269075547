import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Mail: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <g clipPath="url(#clip0_2054_9009)">
          <path
            d="M23.954 5.54395L15.536 13.9619C14.5974 14.8982 13.3257 15.424 12 15.424C10.6743 15.424 9.40263 14.8982 8.464 13.9619L0.046 5.54395C0.032 5.70195 0 5.84495 0 6.00195V18.0019C0.00158786 19.3275 0.528882 20.5984 1.46622 21.5357C2.40356 22.4731 3.67441 23.0004 5 23.0019H19C20.3256 23.0004 21.5964 22.4731 22.5338 21.5357C23.4711 20.5984 23.9984 19.3275 24 18.0019V6.00195C24 5.84495 23.968 5.70195 23.954 5.54395Z"
            fill={color}
          />
          <path
            d="M14.1236 12.546L23.2576 3.411C22.8151 2.67732 22.1911 2.07004 21.4456 1.64773C20.7001 1.22542 19.8584 1.00234 19.0016 1H5.00161C4.14483 1.00234 3.30311 1.22542 2.55763 1.64773C1.81216 2.07004 1.18808 2.67732 0.745605 3.411L9.8796 12.546C10.4432 13.1073 11.2062 13.4225 12.0016 13.4225C12.797 13.4225 13.56 13.1073 14.1236 12.546Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_2054_9009">
            <rect width="24" height="24" fill={color} />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Mail.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Mail.displayName = 'Mail';

export default Mail;
