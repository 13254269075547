import React from 'react';

import { Container } from './CircledIcon.styles';

import { CircledIconProps } from './types';

function CircledIcon({
  children,
  size,
  backgroundColor,
  backgroundColorStep,
  backgroundOpacity,
}: CircledIconProps) {
  return (
    <Container
      size={size}
      backgroundColor={backgroundColor}
      backgroundColorStep={backgroundColorStep}
      backgroundOpacity={backgroundOpacity}
    >
      {children}
    </Container>
  );
}

export default CircledIcon;
