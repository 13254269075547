import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Download: Icon = forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.4 14.1999C2.95229 14.1999 3.4 14.6476 3.4 15.1999V19.4666C3.4 19.7672 3.51941 20.0554 3.73195 20.268C3.94449 20.4805 4.23276 20.5999 4.53333 20.5999H19.4667C19.7672 20.5999 20.0555 20.4805 20.2681 20.268C20.4806 20.0554 20.6 19.7672 20.6 19.4666V15.1999C20.6 14.6476 21.0477 14.1999 21.6 14.1999C22.1523 14.1999 22.6 14.6476 22.6 15.1999V19.4666C22.6 20.2976 22.2699 21.0946 21.6823 21.6822C21.0947 22.2698 20.2977 22.5999 19.4667 22.5999H4.53333C3.70232 22.5999 2.90535 22.2698 2.31773 21.6822C1.73012 21.0946 1.4 20.2976 1.4 19.4666V15.1999C1.4 14.6476 1.84772 14.1999 2.4 14.1999Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.95994 9.15955C6.35047 8.76902 6.98363 8.76902 7.37416 9.15955L12.0004 13.7858L16.6266 9.15955C17.0171 8.76902 17.6503 8.76902 18.0408 9.15955C18.4313 9.55007 18.4313 10.1832 18.0408 10.5738L12.7075 15.9071C12.52 16.0946 12.2656 16.2 12.0004 16.2C11.7352 16.2 11.4808 16.0946 11.2933 15.9071L5.95994 10.5738C5.56942 10.1832 5.56942 9.55007 5.95994 9.15955Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1.40002C12.5523 1.40002 13 1.84774 13 2.40002V15.2C13 15.7523 12.5527 16.2 12.0004 16.2C11.4481 16.2 11 15.7523 11 15.2V2.40002C11 1.84774 11.4477 1.40002 12 1.40002Z"
          fill={color}
        />
      </svg>
    );
  }
);

Download.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Download.displayName = 'Download';

export default Download;
