import { gql } from '@apollo/client';

import { Business } from '@src/types/models';

export const CREATE_BUSINESS = gql`
  mutation CreateBusiness(
    $business: BusinessInput!
    $taxPayerInfo: TaxPayerInfoInput!
  ) {
    createBusiness(business: $business, taxPayerInfo: $taxPayerInfo) {
      business {
        id
      }
    }
  }
`;

export type CreateBusinessVariables = {
  business: { name: string };
  taxPayerInfo: {
    taxpayerIdentifier: string;
    satPassword: string;
  };
};

export type CreateBusinessReturn = {
  createBusiness: {
    business: Business;
  };
  createOnboardedBusiness?: {
    business: Business;
  };
};

export default CREATE_BUSINESS;
