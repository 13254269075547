import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const DollarSign: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...rest}
      >
        <path
          d="M15.2256 4.75C14.4773 3.36364 13.3418 2.45455 11.7676 2.06818V0H8.61929V2.02273C6.47738 2.54545 5.08386 4.18182 5.08386 6.18182C5.03224 9.11364 7.8193 10.3182 9.9612 11.1136C11.4838 11.6818 12.645 12.4091 12.645 13.3409C12.645 14.7273 11.8709 15.4091 10.2967 15.4091C8.59348 15.4091 7.4064 14.3409 6.70964 12.1818L4 13.6818C4.49032 15.75 6.21932 17.4318 8.61929 17.9545V20H11.7676V18C14.374 17.5227 15.9998 15.7955 15.9998 13.1818C16.0256 10.4318 13.1612 9.25 10.9934 8.40909C9.5225 7.79545 8.28381 7.04545 8.28381 6.04545C8.28381 5.20455 9.00638 4.59091 10.0128 4.59091C11.0193 4.59091 11.8709 5.15909 12.516 6.27273L15.2256 4.75Z"
          fill={color}
        />
      </svg>
    );
  }
);

DollarSign.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DollarSign.displayName = 'DollarSign';

export default DollarSign;
