import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Bank: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        fill={color}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path d="M0.387219 11.4012C0.0999154 10.8683 -0.0331881 10.2659 0.00284627 9.66158C0.0388806 9.05724 0.242627 8.4749 0.591219 7.97991C1.1609 7.14407 1.92381 6.45799 2.81522 5.97991L12.8166 0.774578C13.7996 0.26342 14.8912 -0.00344849 15.9992 -0.00344849C17.1072 -0.00344849 18.1989 0.26342 19.1819 0.774578L29.1819 5.98391C30.0733 6.46199 30.8362 7.14807 31.4059 7.98391C31.7545 8.4789 31.9582 9.06124 31.9943 9.66558C32.0303 10.2699 31.8972 10.8723 31.6099 11.4052C31.3006 11.9904 30.8367 12.4794 30.2687 12.8191C29.7007 13.1588 29.0504 13.3362 28.3886 13.3319H3.60989C2.94743 13.3361 2.29656 13.1582 1.72827 12.8177C1.15998 12.4773 0.696097 11.9873 0.387219 11.4012V11.4012ZM30.6659 29.3319C30.6659 28.6247 30.3849 27.9464 29.8848 27.4463C29.3847 26.9462 28.7065 26.6652 27.9992 26.6652V15.9986H25.3326V26.6652H21.3326V15.9986H18.6659V26.6652H13.3326V15.9986H10.6659V26.6652H6.66589V15.9986H3.99922V26.6652C3.29197 26.6652 2.6137 26.9462 2.1136 27.4463C1.6135 27.9464 1.33255 28.6247 1.33255 29.3319C0.97893 29.3319 0.639792 29.4724 0.389743 29.7224C0.139695 29.9725 -0.000780971 30.3116 -0.000780971 30.6652C-0.000780971 31.0189 0.139695 31.358 0.389743 31.6081C0.639792 31.8581 0.97893 31.9986 1.33255 31.9986H30.6659C31.0195 31.9986 31.3586 31.8581 31.6087 31.6081C31.8587 31.358 31.9992 31.0189 31.9992 30.6652C31.9992 30.3116 31.8587 29.9725 31.6087 29.7224C31.3586 29.4724 31.0195 29.3319 30.6659 29.3319Z" />
        <clipPath id="clip0_1956_9136">
          <rect width="32" height="32" />
        </clipPath>
      </svg>
    );
  }
);

Bank.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Bank.displayName = 'Bank';

export default Bank;
