import { graphql } from 'msw';

export const privateClientOverviewHandler = graphql.query(
  'PrivateClientOverview',
  (req, res, ctx) => {
    return res(
      ctx.data({
        client: {
          __typename: 'Client',
          id: '1153',
          uuid: '2a2c030f-437f-4a7d-af6e-f0de5ad70b67',
          taxName: 'TRM ENLACE EMPRESARIAL S.A. DE C.V.',
          taxId: 'TEE150925FW7',
          paymentTermsDays: 15,
          overdueAmountCents: 0,
          allowCreditCardPayments: true,
          pendingCollectionAmountCents: 0,
          contacts: [],
          receivables: [
            {
              __typename: 'Receivable',
              id: '3919',
              amountCents: 0,
              issueDate: '2022-08-03',
              expiresAt: '2022-08-18',
              collectedAt: null,
              invoice: {
                id: '5555897',
                fiscalIdentifier: '2021277a-23fe-9f44-a42b-9c100c658461',
                receiverCountryTaxPayerInfo: {
                  folio: '960',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
            {
              __typename: 'Receivable',
              id: '3920',
              amountCents: 0,
              issueDate: '2022-08-03',
              expiresAt: '2022-08-18',
              collectedAt: null,
              invoice: {
                id: '5555899',
                fiscalIdentifier: '094af8fe-acf6-9b45-9597-08e3df75c9b7',
                receiverCountryTaxPayerInfo: {
                  folio: '959',
                  __typename: 'MexicanTaxPayerInfo',
                },
                __typename: 'Invoice',
              },
            },
          ],
          reminderSchedule: null,
          lastReminderNotification: null,
        },
      })
    );
  }
);
