import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Security: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M11.6438 0.0654553C11.873 -0.0218184 12.1263 -0.0218184 12.3555 0.0654553L21.0222 3.36546C21.41 3.51313 21.6663 3.885 21.6663 4.3V12C21.6663 15.7401 19.2255 18.7428 16.9972 20.7226C15.8632 21.7301 14.7337 22.518 13.8897 23.0537C13.4666 23.3222 13.1126 23.5289 12.8621 23.6698C12.7367 23.7402 12.6371 23.7943 12.5674 23.8314C12.5326 23.85 12.5052 23.8643 12.4859 23.8744L12.4629 23.8863L12.4561 23.8898L12.4538 23.8909L12.453 23.8913C12.4527 23.8915 12.4524 23.8917 11.9997 23C11.547 23.8917 11.5467 23.8915 11.5464 23.8913L11.5455 23.8909L11.5433 23.8898L11.5364 23.8863L11.5135 23.8744C11.4941 23.8643 11.4668 23.85 11.4319 23.8314C11.3623 23.7943 11.2626 23.7402 11.1373 23.6698C10.8867 23.5289 10.5327 23.3222 10.1097 23.0537C9.26561 22.518 8.13617 21.7301 7.00216 20.7226C4.77388 18.7428 2.33301 15.7401 2.33301 12V4.3C2.33301 3.885 2.58933 3.51313 2.97716 3.36546L11.6438 0.0654553ZM11.9997 23L11.547 23.8917C11.8315 24.0361 12.1679 24.0361 12.4524 23.8917L11.9997 23ZM11.9997 21.8596C12.2131 21.7374 12.4923 21.5717 12.818 21.365C13.5989 20.8695 14.6362 20.1449 15.6688 19.2274C17.7739 17.3572 19.6663 14.8599 19.6663 12V4.98927L11.9997 2.07004L4.33301 4.98927V12C4.33301 14.8599 6.22547 17.3572 8.33053 19.2274C9.36318 20.1449 10.4004 20.8695 11.1813 21.365C11.507 21.5717 11.7863 21.7374 11.9997 21.8596Z" />
        <path d="M9.50391 11.4028V13.5694H14.4968V11.4028H9.50391ZM7.50391 11.1604C7.50391 10.166 8.32351 9.40283 9.28089 9.40283H14.7198C15.6772 9.40283 16.4968 10.166 16.4968 11.1604V13.8119C16.4968 14.8062 15.6772 15.5694 14.7198 15.5694H9.28089C8.32351 15.5694 7.50391 14.8062 7.50391 13.8119V11.1604Z" />
        <path d="M12.0001 8C11.7421 8 11.4996 8.10017 11.3247 8.27071C11.1506 8.44041 11.0576 8.66511 11.0576 8.8939V10.409C11.0576 10.9613 10.6099 11.409 10.0576 11.409C9.50533 11.409 9.05762 10.9613 9.05762 10.409V8.8939C9.05762 8.11811 9.37394 7.37936 9.92845 6.83871C10.4821 6.2989 11.2277 6 12.0001 6C12.7724 6 13.5181 6.2989 14.0717 6.83871C14.6262 7.37936 14.9425 8.11811 14.9425 8.8939V10.409C14.9425 10.9613 14.4948 11.409 13.9425 11.409C13.3903 11.409 12.9425 10.9613 12.9425 10.409V8.8939C12.9425 8.66511 12.8496 8.44041 12.6755 8.27071C12.5006 8.10017 12.2581 8 12.0001 8Z" />
      </svg>
    );
  }
);

Security.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Security.displayName = 'Security';

export default Security;
