import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const DepositMoney: Icon = forwardRef(
  ({ color = '#030730', ...rest }, ref: ForwardedRef<SVGSVGElement>) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="95"
        height="72"
        viewBox="0 0 95 72"
        fill="none"
        ref={ref}
        {...rest}
      >
        <circle
          cx="35.7926"
          cy="35.7926"
          r="34.2926"
          fill="white"
          stroke={color}
          strokeWidth="3"
        />
        <path
          d="M35.5645 15.249V57.8132"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.2391 22.9929H30.7286C28.9327 22.9929 27.2103 23.7064 25.9404 24.9763C24.6705 26.2462 23.957 27.9686 23.957 29.7645C23.957 31.5604 24.6705 33.2828 25.9404 34.5527C27.2103 35.8226 28.9327 36.5361 30.7286 36.5361H40.4023C42.1982 36.5361 43.9206 37.2495 45.1905 38.5194C46.4604 39.7893 47.1738 41.5117 47.1738 43.3076C47.1738 45.1036 46.4604 46.8259 45.1905 48.0959C43.9206 49.3658 42.1982 50.0792 40.4023 50.0792H23.957"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.5173 56.1068C84.2025 56.1068 92.8646 47.4447 92.8646 36.7595C92.8646 26.0742 84.2025 17.4121 73.5173 17.4121C62.832 17.4121 54.1699 26.0742 54.1699 36.7595C54.1699 47.4447 62.832 56.1068 73.5173 56.1068Z"
          fill="white"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M73.5163 29.0203L65.7773 36.7592L73.5163 44.4981"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.2552 36.7561H65.7773"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

DepositMoney.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DepositMoney.displayName = 'DepositMoney';

export default DepositMoney;
