import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { Surface } from './FullScreenModal.styles';

import type { FullScreenModalProps } from './types';

export const FullScreenModal = ({
  children,
  open,
  container,
  onClose,
  ...otherProps
}: FullScreenModalProps) => {
  useEffect(() => {
    const handleEscapeKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKeydown);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeydown);
    };
  }, []);

  if (typeof document === 'undefined') {
    return null;
  }

  const containerElement = container && document.querySelector(container);
  const portalContainer = containerElement || document.body;

  const blockScroll = () => {
    document.body.style.overflowY = 'scroll';
    document.body.style.position = 'fixed';
  };
  const enableScroll = () => {
    document.body.style.overflowY = 'auto';
    document.body.style.position = 'static';
  };

  return ReactDOM.createPortal(
    <CSSTransition
      in={open}
      timeout={300}
      classNames="full-screen-modal"
      appear
      mountOnEnter
      unmountOnExit
      onEnter={blockScroll}
      onExit={enableScroll}
    >
      <Surface {...otherProps}>{children}</Surface>
    </CSSTransition>,
    portalContainer
  );
};

export default FullScreenModal;
