import styled, { Color, ColorStep, css } from 'styled-components';
export interface LabelProps extends React.HTMLAttributes<HTMLParagraphElement> {
  size?: 'sm' | 'md' | 'lg';
  strong?: boolean;
  underline?: boolean;
  colorStep?: ColorStep;
  color?: Color;
}

const Label = styled.p<LabelProps>`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme, strong }) =>
    strong ? theme.fontWeight.medium : theme.fontWeight.regular};
  text-decoration: ${({ underline }) => underline && 'underline'};
  color: ${({ theme, color = 'neutral', colorStep = '900' }) =>
    theme.colors[color][colorStep]};
  margin: 0;

  ${({ size }) =>
    size === 'md' &&
    css`
      font-size: ${({ theme }) => theme.fontSize.sm};
    `}

  ${({ size }) =>
    size === 'lg' &&
    css`
      font-size: ${({ theme }) => theme.fontSize.md};
    `}
`;

export default Label;
