import { graphql } from 'msw';

export const collectableReceivablesTableHandler = graphql.query(
  'CollectableReceivablesTable',
  (req, res, ctx) =>
    res(
      ctx.data({
        receivables: {
          edges: [
            {
              node: {
                id: '1',
                client: {
                  id: '87634234',
                  taxName: 'Socoda SA de CV',
                  contacts: [
                    {
                      name: 'Test',
                      email: 'socoda@higo.io',
                    },
                  ],
                },
                invoice: {
                  id: '09387423',
                },
                folio: '02312',
                emittedAt: '2022-03-12T00:00:00',
                collectedAt: undefined,
                pendingTotalCents: 32492,
                totalCents: 32492,
                status: 'pending',
                __typename: 'Receivable',
              },
            },
            {
              node: {
                id: '2',
                client: {
                  id: '5552623',
                  taxName: 'Higo SA de CV',
                  contacts: [
                    {
                      name: 'Test',
                      email: 'higo@higo.io',
                    },
                  ],
                },
                invoice: {
                  id: '09387422',
                },
                folio: '02312',
                emittedAt: '2022-03-12T00:00:00',
                collectedAt: undefined,
                pendingTotalCents: 32492,
                totalCents: 32492,
                status: 'pending',
                __typename: 'Receivable',
              },
            },
            {
              node: {
                id: '3',
                client: {
                  id: '87626892',
                  taxName: 'Concentradora SA de CV',
                  contacts: [
                    {
                      name: 'Test',
                      email: 'concentradora@higo.io',
                    },
                  ],
                },
                invoice: {
                  id: '09387421',
                },
                folio: '02312',
                emittedAt: '2022-03-12T00:00:00',
                collectedAt: undefined,
                pendingTotalCents: 32492,
                totalCents: 32492,
                status: 'pending',
                __typename: 'Receivable',
              },
            },
            {
              node: {
                id: '4',
                client: {
                  id: '667626892',
                  taxName: 'SAPI SA de CV',
                  contacts: [],
                },
                invoice: {
                  id: '09387421',
                },
                folio: '02312',
                emittedAt: '2022-03-12T00:00:00',
                collectedAt: undefined,
                pendingTotalCents: 32492,
                totalCents: 32492,
                status: 'pending',
                __typename: 'Receivable',
              },
            },
          ],
          pageInfo: {
            startCursor: 'mw',
            endCursor: 'MQ',
            hasNextPage: false,
            hasPreviousPage: false,
          },
          totalCount: 1,
        },
      })
    )
);
