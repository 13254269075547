import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ArrowUpRight: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        {...rest}
      >
        <g clipPath="url(#clip0)">
          <path d="M20 24C22.2091 24 24 22.2091 24 20L24 4C24 1.79086 22.2091 -1.56562e-07 20 -3.49691e-07L4 -1.74846e-06C1.79086 -1.94158e-06 1.94158e-06 1.79086 1.74846e-06 4L3.49691e-07 20C1.56562e-07 22.2091 1.79086 24 4 24L20 24ZM5.11612 17.1161L14.9822 7.25L6 7.25C5.30964 7.25 4.75 6.69035 4.75 6C4.75 5.30964 5.30964 4.75 6 4.75L17.9992 4.75L18 4.75L18.0038 4.75001C18.3224 4.75096 18.6408 4.87299 18.8839 5.11612C19.0037 5.23596 19.0942 5.37409 19.1552 5.52152C19.2163 5.6689 19.25 5.83051 19.25 6L19.25 18C19.25 18.6904 18.6904 19.25 18 19.25C17.3096 19.25 16.75 18.6904 16.75 18L16.75 9.01777L6.88388 18.8839C6.39573 19.372 5.60427 19.372 5.11612 18.8839C4.62796 18.3957 4.62796 17.6043 5.11612 17.1161Z" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

ArrowUpRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowUpRight.displayName = 'ArrowUpRight';

export default ArrowUpRight;
