import { graphql } from 'msw';

export const pendingInvoiceIssueIntentsListHandler = graphql.query(
  'PendingInvoiceIssueIntentsTable',
  (req, res, ctx) => {
    return res(
      ctx.data({
        invoiceIssueIntents: {
          edges: [
            {
              node: {
                __typename: 'InvoiceIssueIntent',
                client: {
                  __typename: 'Client',
                  taxName: 'MASA OPERATIONS LLC',
                },
                id: '2',
                invoiceUuid: '132',
                status: 'PENDING',
                totalCents: 116,
              },
              __typename: 'InvoiceIssueIntentEdge',
            },
            {
              node: {
                __typename: 'InvoiceIssueIntent',
                client: {
                  __typename: 'Client',
                  taxName: 'PIZZA LLC',
                },
                id: '3',
                invoiceUuid: '123',
                status: 'ERROR',
                totalCents: 12200,
                issuingErrors: ['sss'],
              },
              __typename: 'InvoiceIssueIntentEdge',
            },
          ],
          pageInfo: {
            startCursor: 'MQ',
            endCursor: 'MQ',
            hasNextPage: false,
            hasPreviousPage: false,
            __typename: 'PageInfo',
          },
          totalCount: 1,
          __typename: 'InvoiceIssueIntentConnection',
        },
      })
    );
  }
);
