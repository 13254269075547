import React, { ReactNode } from 'react';

import * as S from './LinkButton.styles';

import { Button } from '@ui';
import { ButtonProps } from '@ui/Button/types';

interface LinkButtonProps extends ButtonProps {
  href: string;
  children: ReactNode;
  block?: boolean;
  target?: string;
}

const LinkButton = ({
  href,
  children,
  block,
  target,
  ...buttonProps
}: LinkButtonProps) => {
  return (
    <S.Link target={target} href={href} $block={block}>
      <Button block={block} {...buttonProps}>
        {children}
      </Button>
    </S.Link>
  );
};

export default LinkButton;
