import { gql } from '@apollo/client';

import { CreditAccount, FinancingRequest } from '@src/types/models';

export const CREDIT_ACCOUNT_QUERY = gql`
  query CreditAccountData($businessId: ID!) {
    creditAccount(businessId: $businessId) {
      id
      active
      activationRequestedAt
      contractSignedAt
      creditLimitCents
      creditTermsJson
      ivaTax
      owedAmountCents
      overdueAmountCents
      owedInterestCents
      annualInterestRate
      owedTotal
      nextFinancingRequest {
        id
        owedAmountCents
        owedTotalCents
        interestAmountCents
        ivaInterestAmountCents
        moratoryInterestAmountCents
        totalInterestAmountCents
        annualInterestRate
        annualMoratoryRate
        overdue
        dueDate
      }
    }
  }
`;

export type CreditAccountQueryVariables = {
  businessId?: string;
};

type NextFinancingRequest = Pick<
  FinancingRequest,
  | 'id'
  | 'owedTotalCents'
  | 'owedAmountCents'
  | 'interestAmountCents'
  | 'ivaInterestAmountCents'
  | 'moratoryInterestAmountCents'
  | 'totalInterestAmountCents'
  | 'annualInterestRate'
  | 'annualMoratoryRate'
  | 'overdue'
  | 'dueDate'
>;

export type CreditAccountQueryReturn = {
  creditAccount: Pick<
    CreditAccount,
    | 'id'
    | 'active'
    | 'activationRequestedAt'
    | 'contractSignedAt'
    | 'creditLimitCents'
    | 'owedAmountCents'
    | 'overdueAmountCents'
    | 'owedInterestCents'
    | 'owedTotal'
  > & {
    nextFinancingRequest?: NextFinancingRequest;
  };
};

export default CREDIT_ACCOUNT_QUERY;
