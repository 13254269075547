import styled, { css } from 'styled-components';

interface SpacerProps {
  direction?: 'vertical' | 'horizontal';
  margin?:
    | '1'
    | '2'
    | '3'
    | '4'
    | '5'
    | '6'
    | '7'
    | '8'
    | '10'
    | '12'
    | '16'
    | '18'
    | '19'
    | '20'
    | '24';
}

const Spacer = styled.div<SpacerProps>`
  ${({ theme, direction = 'column', margin = '4' }) =>
    direction === 'horizontal'
      ? css`
          width: ${theme.spacing[margin]};
          display: inline-block;
        `
      : css`
          height: ${theme.spacing[margin]};
        `};
`;

export default Spacer;
