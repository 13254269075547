export const BASE: string = process.env.NEXT_PUBLIC_NAME || 'tandem-gastown';

const createStorageKey = (key: string) => `${BASE}:${key}`;

export const AUTH_TOKEN = createStorageKey('authToken');
export const NAV_BAR_OPEN = createStorageKey('navBarOpen');
export const CURRENT_BUSINESS_ID = createStorageKey('currentBusinessId');
export const RESOURCE_LIST_FILTERS_OPEN = createStorageKey(
  'resourceListFiltersOpen'
);
