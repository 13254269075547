import { graphql } from 'msw';

export const UpdateReminderScheduleHandler = graphql.mutation(
  'UpdateReminderSchedule',
  (req, res, ctx) => {
    return res(
      ctx.data({
        id: '1',
      })
    );
  }
);
