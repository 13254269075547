import React, { useEffect, useState } from 'react';
import posthog from 'posthog-js';

import { isPosthogEnabled } from '@src/lib/analytics';
import { loggedIn } from '@src/lib/auth';

export enum FeatureFlag {
  AUTOMATIC_FINANCING_FUND = 'automatic-financing-fund',
  TERM_LOANS = 'term-loans',
  TWO_FA = 'two-fa',
  FEATURE_ANNOUNCEMENT_MODAL = 'feature-announcement-modal',
  CREDIT_CARD_PAYMENT = 'credit-card-payment',
  ISSUING_INVOICES = 'issuing-invoices',
  WHATSAPP_AUTOREMINDERS = 'whatsapp-autoreminders',
}

const useFeatureFlag = (name: FeatureFlag) => {
  const [enabled, setEnabled] = useState(
    loggedIn() &&
      isPosthogEnabled &&
      typeof window !== 'undefined' &&
      posthog.isFeatureEnabled(name)
  );

  useEffect(() => {
    if (isPosthogEnabled) {
      posthog.onFeatureFlags((_, flags) => {
        setEnabled(Boolean(flags[name]));
      });
    }
  }, [name]);

  return enabled;
};

export default useFeatureFlag;
