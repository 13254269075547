import React from 'react';

import { CancelButton } from './Search.styles';

import { Spinner } from '@ui';
import { Search as SearchIcon, X as Close } from '@ui/Icon/outline';
import Input from '@ui/Input';

import type { InputProps } from '../Input/types';

interface SearchProps extends InputProps {
  onClear?: (event: React.SyntheticEvent) => void;
  loading?: boolean;
}

export default function Search(props: SearchProps) {
  const { value, onClear, loading } = props;
  let endIcon;
  if (value && onClear) {
    endIcon = (
      <CancelButton>
        <Close onClick={onClear} />
      </CancelButton>
    );
  }
  if (loading) {
    endIcon = <Spinner />;
  }
  return (
    <Input
      value={value}
      {...props}
      startIcon={<SearchIcon size={18} />}
      endIcon={endIcon}
    />
  );
}
