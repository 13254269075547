import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Diploma: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 14 18"
        fill={color}
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2278 9.69191C10.6183 9.68981 10.0252 9.8893 9.54095 10.2593C9.05666 10.6294 8.70833 11.1491 8.55019 11.7377C8.39205 12.3263 8.43298 12.9507 8.66662 13.5136C8.90025 14.0765 9.31347 14.5464 9.84193 14.85V16.813C9.84188 16.9119 9.87116 17.0085 9.92605 17.0907C9.98094 17.1729 10.059 17.237 10.1503 17.2748C10.2416 17.3127 10.3421 17.3226 10.4391 17.3033C10.536 17.284 10.625 17.2364 10.6949 17.1664L11.2278 16.6343L11.7606 17.1664C11.8305 17.2364 11.9195 17.284 12.0165 17.3033C12.1134 17.3226 12.2139 17.3127 12.3052 17.2748C12.3965 17.237 12.4746 17.1729 12.5295 17.0907C12.5844 17.0085 12.6136 16.9119 12.6136 16.813V14.85C13.1421 14.5464 13.5553 14.0765 13.7889 13.5136C14.0225 12.9507 14.0635 12.3263 13.9053 11.7377C13.7472 11.1491 13.3989 10.6294 12.9146 10.2593C12.4303 9.8893 11.8372 9.68981 11.2278 9.69191Z"
          fill={color}
        />
        <path
          d="M7.07026 12.4647C7.06941 11.7346 7.26104 11.0172 7.62584 10.3848C7.99064 9.75244 8.51572 9.2274 9.14813 8.86264C9.78055 8.49788 10.4979 8.30629 11.228 8.30718C11.9581 8.30808 12.675 8.50143 13.3065 8.86774V4.14965C13.3054 3.23112 12.9401 2.35053 12.2906 1.70103C11.6411 1.05153 10.7605 0.686159 9.84193 0.685059L4.29858 0.685059C3.38005 0.686159 2.49946 1.05153 1.84996 1.70103C1.20046 2.35053 0.835085 3.23112 0.833984 4.14965V12.4647C0.835085 13.3832 1.20046 14.2638 1.84996 14.9133C2.49946 15.5628 3.38005 15.9282 4.29858 15.9293H8.4561V15.5551C8.0203 15.1668 7.67154 14.6907 7.4327 14.1581C7.19387 13.6255 7.07035 13.0484 7.07026 12.4647ZM4.29858 3.45674H9.84193C10.0257 3.45674 10.202 3.52974 10.3319 3.65969C10.4619 3.78963 10.5349 3.96588 10.5349 4.14965C10.5349 4.33343 10.4619 4.50968 10.3319 4.63962C10.202 4.76957 10.0257 4.84257 9.84193 4.84257H4.29858C4.11481 4.84257 3.93856 4.76957 3.80861 4.63962C3.67867 4.50968 3.60566 4.33343 3.60566 4.14965C3.60566 3.96588 3.67867 3.78963 3.80861 3.65969C3.93856 3.52974 4.11481 3.45674 4.29858 3.45674ZM4.29858 6.22841H9.84193C10.0257 6.22841 10.202 6.30142 10.3319 6.43136C10.4619 6.56131 10.5349 6.73756 10.5349 6.92133C10.5349 7.10511 10.4619 7.28135 10.3319 7.4113C10.202 7.54125 10.0257 7.61425 9.84193 7.61425H4.29858C4.11481 7.61425 3.93856 7.54125 3.80861 7.4113C3.67867 7.28135 3.60566 7.10511 3.60566 6.92133C3.60566 6.73756 3.67867 6.56131 3.80861 6.43136C3.93856 6.30142 4.11481 6.22841 4.29858 6.22841ZM6.37734 10.3859H4.29858C4.11481 10.3859 3.93856 10.3129 3.80861 10.183C3.67867 10.053 3.60566 9.87678 3.60566 9.69301C3.60566 9.50924 3.67867 9.33299 3.80861 9.20304C3.93856 9.07309 4.11481 9.00009 4.29858 9.00009H6.37734C6.56111 9.00009 6.73736 9.07309 6.86731 9.20304C6.99725 9.33299 7.07026 9.50924 7.07026 9.69301C7.07026 9.87678 6.99725 10.053 6.86731 10.183C6.73736 10.3129 6.56111 10.3859 6.37734 10.3859Z"
          fill={color}
        />
      </svg>
    );
  }
);

Diploma.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Diploma.displayName = 'Diploma';

export default Diploma;
