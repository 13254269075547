import React from 'react';
import { CloseButtonProps } from 'react-toastify';

import { X as Close } from '@ui/Icon/outline';
import { Button, StyledContainer } from '@ui/Snackbar/Snackbar.styles';

import { ContentProps } from './types';

function CloseButton({ closeToast }: CloseButtonProps) {
  return (
    <button className="Toastify__close-button" onClick={closeToast}>
      <Close size={20} />
    </button>
  );
}

function Content({ message, action }: ContentProps) {
  if (action) {
    return (
      <div>
        <span>{message}</span>
        <Button onClick={action?.onClick}>{action?.label}</Button>
      </div>
    );
  }

  return <p>{message}</p>;
}

function Container() {
  return (
    <StyledContainer
      autoClose={5000}
      draggable={false}
      closeOnClick={true}
      closeButton={CloseButton}
      enableMultiContainer
      containerId="snackbar-container"
    />
  );
}

export { CloseButton, Content, Container };
