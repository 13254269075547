import { graphql } from 'msw';

export const receivablesCollectedAmountHandler = graphql.query(
  'ReceivablesCollectedAmount',
  (req, res, ctx) => {
    const { startDate, endDate } = req.variables;

    if (startDate && endDate) {
      return res(
        ctx.data({
          receivablesCollectedAmount: {
            totalAmountCents: Math.round(Math.random() * 600000),
          },
        })
      );
    }

    return res(
      ctx.data({
        receivablesCollectedAmount: {
          totalAmountCents: 20000,
        },
      })
    );
  }
);
