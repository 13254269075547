import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Feedback: Icon = forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        ref={ref}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        {...rest}
      >
        <g clipPath="url(#clip0_1655_998)">
          <path
            d="M6 7.3333C5.632 7.3333 5.33333 7.0353 5.33333 6.66663C5.33333 5.79463 6.02533 5.41397 6.32067 5.2513C6.514 5.1453 6.71733 4.89463 6.65533 4.54063C6.60933 4.2793 6.38733 4.0573 6.12667 4.01197C5.92267 3.97463 5.72533 4.02663 5.572 4.15597C5.42 4.28263 5.33333 4.4693 5.33333 4.6673C5.33333 5.03597 5.03467 5.33397 4.66667 5.33397C4.29867 5.33397 4 5.03597 4 4.6673C4 4.07463 4.26067 3.51597 4.71467 3.13463C5.16867 2.7533 5.766 2.59197 6.35667 2.6993C7.16267 2.83997 7.826 3.50263 7.968 4.31063C8.11667 5.15863 7.71333 6.00597 6.964 6.41997C6.666 6.58397 6.666 6.63197 6.666 6.6673C6.666 7.03597 6.36733 7.33397 5.99933 7.33397L6 7.3333ZM6 7.99997C5.54 7.99997 5.16667 8.3733 5.16667 8.8333C5.16667 9.2933 5.54 9.66663 6 9.66663C6.46 9.66663 6.83333 9.2933 6.83333 8.8333C6.83333 8.3733 6.46 7.99997 6 7.99997ZM13.3307 6.0533C13.3267 6.56597 13.27 7.0653 13.1653 7.54797C14.0793 8.28197 14.6667 9.40597 14.6667 10.6666V14C14.6667 14.368 14.368 14.6666 14 14.6666H10.6667C9.406 14.6666 8.282 14.0793 7.548 13.1653C7.06533 13.2693 6.566 13.3266 6.05333 13.3306C6.97667 14.9233 8.69667 16 10.6667 16H14C15.1047 16 16 15.1046 16 14V10.6666C16 8.69663 14.9233 6.97663 13.3307 6.0533ZM11.9847 6.43397C12.108 4.69197 11.4727 2.9873 10.2427 1.75663C9.01267 0.525967 7.30933 -0.111367 5.566 0.0152997C2.54867 0.228633 0 3.0593 0 6.1973V9.55597C0 10.9033 1.09467 12 2.44067 12H5.42533C8.88333 12 11.7647 9.5553 11.9847 6.43397ZM9.3 2.69997C10.2573 3.65663 10.7507 4.9833 10.6553 6.33997C10.4867 8.7253 8.14067 10.666 5.42533 10.666H2.44067C1.83 10.666 1.33333 10.168 1.33333 9.5553V6.19663C1.33333 3.73263 3.31467 1.51063 5.66 1.34463C5.77133 1.33663 5.882 1.33263 5.99267 1.33263C7.23133 1.33263 8.422 1.82063 9.3 2.69997Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_1655_998">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Feedback.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Feedback.displayName = 'Feedback';

export default Feedback;
