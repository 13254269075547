import styled, { css } from 'styled-components';

export type BaseBoxProps = Pick<
  React.CSSProperties,
  'display' | 'position' | 'maxHeight' | 'maxWidth' | 'minHeight' | 'minWidth'
> & {
  padding?: '0' | '2' | '4' | '5' | '6' | '7' | '8' | '9' | '10';
  width?: '24' | '36' | '52' | '64' | '72' | '96' | '112' | '128';
  height?: '24' | '36' | '52' | '64' | '72' | '96' | '112' | '128';
};

type BoxProps = BaseBoxProps & {
  /**
   * Occupy the full width of the parent container
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Occupy the full height of the parent container
   * @default false
   */
  fullHeight?: boolean;
};

const Box = styled.div<BoxProps>`
  ${({ theme, padding }) =>
    padding ? `padding: ${theme.spacing[padding]}` : ''};
  ${({ theme, width }) => (width ? `width: ${theme.spacing[width]}` : '')};
  ${({ theme, height }) => (height ? `height: ${theme.spacing[height]}` : '')};
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  ${({ display }) => (display ? `display: ${display}` : '')};
  ${({ position }) => (position ? `position: ${position}` : '')};
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}` : '')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}` : '')};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}` : '')};
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}` : '')};
`;

export default Box;
