import { graphql } from 'msw';

export const sendVerificationCodeHandler = graphql.mutation(
  'sendVerificationCode',
  (req, res, ctx) => {
    const { channel, to } = req.variables;

    return res(
      ctx.data({
        sendVerificationCode: {
          nextRetryInSeconds: 10,
          attempts: 1,
        },
      }),
      ctx.delay(500)
    );
  }
);
