import React, { useEffect } from 'react';

import FullScreenModal from '@ui/FullScreenModal';
import ModalUI from '@ui/Modal';
import useModal from '@src/hooks/useModal';
import { ButtonVariant } from '@src/components/ui/Button/types';
import Drawer from '@src/components/ui/Drawer';

interface ModalProps {
  component: React.FC<any>;
  padding?: '0' | '2' | '4' | '6' | '8';
  type?: 'dialog' | 'fullscreen' | 'drawer';
  zIndex?: 40 | 50;
  closable?: boolean;
  closeType?: ButtonVariant;
}

export interface ModalChildrenProps {
  onClose?: () => void;
}

function Modal({
  component: Component,
  type = 'dialog',
  zIndex = 40,
  padding = '8',
  closable = true,
  closeType = 'transparent',
}: ModalProps) {
  const { props, isOpen, close } = useModal(Component, zIndex);

  // Closes modal when unmounted. Works for unmounting when navigation.
  useEffect(() => close, []);

  if (type === 'fullscreen') {
    return (
      <FullScreenModal open={isOpen} onClose={close}>
        <Component {...props} onClose={close} />
      </FullScreenModal>
    );
  }

  if (type === 'drawer') {
    return (
      <Drawer
        open={isOpen}
        onClose={close}
        CardProps={{
          padding,
        }}
      >
        <Component {...props} onClose={close} />
      </Drawer>
    );
  }

  return (
    <ModalUI
      open={isOpen}
      onClose={closable ? close : undefined}
      closeType={closeType}
      CardProps={{
        padding,
      }}
    >
      <Component {...props} onClose={close} />
    </ModalUI>
  );
}

export default Modal;
