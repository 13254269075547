import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Eye: Icon = forwardRef(
  (
    { color = 'currentColor', size = 16, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
        <path
          d="M12 15.9985C14.2083 15.9985 15.9985 14.2083 15.9985 12C15.9985 9.79169 14.2083 8.0015 12 8.0015C9.7917 8.0015 8.00151 9.79169 8.00151 12C8.00151 14.2083 9.7917 15.9985 12 15.9985Z"
          fill={color}
        />
        <path
          d="M23.2668 9.41998C21.7164 6.89491 18.1897 2.65847 12 2.65847C5.8103 2.65847 2.28361 6.89491 0.733188 9.41998C-0.244355 11.0011 -0.244355 12.9989 0.733188 14.5801C2.28361 17.1051 5.8103 21.3416 12 21.3416C18.1897 21.3416 21.7164 17.1051 23.2668 14.5801C24.2444 12.9989 24.2444 11.0011 23.2668 9.41998ZM12 17.9978C8.68755 17.9978 6.00223 15.3125 6.00223 12C6.00223 8.68755 8.68755 6.00223 12 6.00223C15.3125 6.00223 17.9978 8.68755 17.9978 12C17.9944 15.3111 15.3111 17.9944 12 17.9978Z"
          fill={color}
        />
      </svg>
    );
  }
);

Eye.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Eye.displayName = 'Eye';

export default Eye;
