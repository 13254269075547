import { graphql } from 'msw';

export const clientListClientsHandler = graphql.query(
  'ClientListClients',
  (req, res, ctx) => {
    return res(
      ctx.data({
        clients: {
          edges: [
            {
              node: {
                id: 1,
                taxName: 'Sebastian Michele',
                taxId: 'GOM0809114P5',
                pendingAmountCents: 938423,
                overdueAmountCents: 309723423,
              },
            },
            {
              node: {
                id: 2,
                taxName: 'Daniel Polo',
                taxId: 'POMO931023ML3',
                pendingAmountCents: 938423,
                overdueAmountCents: 0,
              },
            },
          ],
          pageInfo: {
            startCursor: 'mw',
            endCursor: 'MQ',
            hasNextPage: false,
            hasPreviousPage: false,
          },
          totalCount: 1,
        },
      })
    );
  }
);
