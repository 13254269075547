import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const InvoiceToPay: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M4.05545 2.05545C4.57118 1.53973 5.27065 1.25 5.99999 1.25H14C14.1989 1.25 14.3896 1.32902 14.5303 1.46967L20.5303 7.46965C20.6709 7.6103 20.75 7.80107 20.75 7.99998V19.9999C20.75 20.7293 20.4602 21.4288 19.9445 21.9445C19.4288 22.4602 18.7293 22.7499 18 22.7499H5.99999C5.27065 22.7499 4.57118 22.4602 4.05545 21.9445C3.53973 21.4288 3.25 20.7293 3.25 19.9999V3.99999C3.25 3.27065 3.53973 2.57118 4.05545 2.05545ZM5.99999 2.75C5.66847 2.75 5.35053 2.8817 5.11611 3.11611C4.8817 3.35053 4.75 3.66847 4.75 3.99999V19.9999C4.75 20.3315 4.8817 20.6494 5.11611 20.8838C5.35053 21.1182 5.66848 21.2499 5.99999 21.2499H18C18.3315 21.2499 18.6494 21.1182 18.8838 20.8838C19.1183 20.6494 19.25 20.3315 19.25 19.9999V8.31064L13.6893 2.75H5.99999Z" />
        <path d="M15.25 13.0002C15.25 13.4145 14.9142 13.7502 14.5 13.7502L4.00002 13.7502C3.58581 13.7502 3.25002 13.4145 3.25002 13.0002C3.25002 12.586 3.58581 12.2502 4.00002 12.2502L14.5 12.2502C14.9142 12.2502 15.25 12.586 15.25 13.0002Z" />
        <path d="M15.5302 12.4697C15.8231 12.7626 15.8231 13.2374 15.5302 13.5303L11.5302 17.5303C11.2373 17.8232 10.7624 17.8232 10.4696 17.5303C10.1767 17.2374 10.1767 16.7626 10.4696 16.4697L14.4696 12.4697C14.7624 12.1768 15.2373 12.1768 15.5302 12.4697Z" />
        <path d="M15.5302 13.5303C15.8231 13.2374 15.8231 12.7626 15.5302 12.4697L11.5302 8.46967C11.2373 8.17678 10.7625 8.17678 10.4696 8.46967C10.1767 8.76256 10.1767 9.23744 10.4696 9.53033L14.4696 13.5303C14.7624 13.8232 15.2373 13.8232 15.5302 13.5303Z" />
      </svg>
    );
  }
);

InvoiceToPay.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InvoiceToPay.displayName = 'InvoiceToPay';

export default InvoiceToPay;
