import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ArrowRightLine: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        fillRule="evenodd"
        clipRule="evenodd"
        {...rest}
      >
        <path d="M6 12C6 11.4477 6.44772 11 7 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H7C6.44772 13 6 12.5523 6 12Z" />
        <path d="M13.2929 4.29289C13.6834 3.90237 14.3166 3.90237 14.7071 4.29289L21.7071 11.2929C22.0976 11.6834 22.0976 12.3166 21.7071 12.7071L14.7071 19.7071C14.3166 20.0976 13.6834 20.0976 13.2929 19.7071C12.9024 19.3166 12.9024 18.6834 13.2929 18.2929L19.5858 12L13.2929 5.70711C12.9024 5.31658 12.9024 4.68342 13.2929 4.29289Z" />
        <path d="M2 20C1.44772 20 1 19.5523 1 19L0.999999 5C0.999999 4.44771 1.44771 4 2 4C2.55228 4 3 4.44771 3 5L3 19C3 19.5523 2.55228 20 2 20Z" />
      </svg>
    );
  }
);

ArrowRightLine.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowRightLine.displayName = 'ArrowRightLine';

export default ArrowRightLine;
