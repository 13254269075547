import { graphql } from 'msw';

export const saveIssuerHandler = graphql.mutation(
  'SaveIssuer',
  (req, res, ctx) => {
    return res(
      ctx.data({
        saveIssuer: { errors: [], success: true },
      })
    );
  }
);
