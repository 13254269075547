import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const Send: Icon = forwardRef(
  (
    { color = 'currentColor', size = 24, ...rest },
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill={color}
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.296 1.55342C15.3806 1.3117 15.3192 1.04288 15.1382 0.861785C14.9571 0.680693 14.6882 0.619347 14.4465 0.70395L1.11318 5.37062C0.855716 5.46073 0.679121 5.69861 0.667371 5.97114C0.65562 6.24367 0.811085 6.49586 1.05984 6.6078L5.05984 8.4078C5.26539 8.5003 5.50375 8.48267 5.69345 8.36094L11.1229 4.87706L7.639 10.3065C7.51727 10.4962 7.49964 10.7346 7.59214 10.9401L9.39214 14.9401C9.50408 15.1889 9.75627 15.3443 10.0288 15.3326C10.3013 15.3208 10.5392 15.1442 10.6293 14.8868L15.296 1.55342Z"
          fill={color}
        />
      </svg>
    );
  }
);

Send.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Send.displayName = 'Send';

export default Send;
