import { gql } from '@apollo/client';

import { User } from '@src/types/models';

export const USER_PROFILE_ATTRIBUTES = gql`
  fragment UserProfile on User {
    id
    email
    firstName
    lastName
    locale
    profileImage
    createdAt
    timeZone
    phone
    twoFaEnabled
    initials
    emailVerified
    phoneVerified
    seenFeatureAnnouncements
    twoFaVerificationChannels
    signedUpFor
    businessUserValidation {
      id
      status
      business {
        id
        name
        hasTaxPayerInfo
        bankingInfos {
          id
          bankName
          clabe
          recipient
          validationStatus
        }
        taxPayerInfos {
          id
          taxpayerIdentifier
        }
        bankingInfoValidation {
          id
          clabe
          validationStatus
          bankingInfo {
            id
            bankName
            clabe
          }
        }
      }
    }
    businesses {
      id
      name
      phone
      websiteUrl
      imageUrl
      mailboxEmail
      activeSubscription
      pendingSubscriptionInvoices {
        id
      }
      wallet {
        id
      }
      taxPayerInfos {
        id
        taxpayerName
        taxpayerIdentifier
        fiscalRegime
        issuerStatus
        csdCerFilename
        fielCerFilename
        csdValidFrom
        csdValidTo
        address {
          postalCode
          street
          state
          colony
          city
          number
        }
      }
      creditAccount {
        active
        creditLimitCents
      }
      defaultBankingInfo {
        id
        bankName
        clabe
        recipient
        validationStatus
      }
      bankingInfos {
        id
        bankName
        clabe
        recipient
        validationStatus
      }
      bankingInfoValidation {
        clabe
        validationStatus
        bankingInfo {
          bankName
          clabe
        }
      }
      businessGroups {
        edges {
          node {
            id
            group {
              id
              name
            }
          }
        }
      }
      businessFeatures {
        feature {
          id
          name
        }
      }
      setting {
        id
        currencyCode
      }
      actsAs
      hasTaxPayerInfo
      hasActiveProviders
    }
    businessUsers {
      id
      role
      business {
        id
        name
      }
      features {
        id
        name
      }
      permissions {
        id
        name
        taxPayerInfo {
          id
          taxpayerName
        }
      }
    }
  }
`;

export type UserProfileAttributes = Pick<
  User,
  | 'id'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'locale'
  | 'profileImage'
  | 'createdAt'
  | 'timeZone'
  | 'phone'
  | 'initials'
  | 'emailVerified'
  | 'phoneVerified'
  | 'twoFaVerificationChannels'
  | 'twoFaEnabled'
  | 'businessUserValidation'
  | 'businesses'
  | 'businessUsers'
  | 'seenFeatureAnnouncements'
>;
export default USER_PROFILE_ATTRIBUTES;
