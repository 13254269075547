import React, { ForwardedRef, forwardRef } from 'react';
import { Icon } from 'react-feather';
import PropTypes from 'prop-types';

const ToChargeSuccess: Icon = forwardRef(
  ({ ...rest }, ref: ForwardedRef<SVGSVGElement>) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        ref={ref}
        {...rest}
      >
        <rect
          x="24.5"
          y="22.75"
          width="28"
          height="28"
          rx="14"
          fill="#82FFD9"
        />
        <path
          d="M19.0703 11.4583V34.0408"
          stroke="#030730"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.2982 15.5674H16.4579C14.4311 15.5674 12.8043 17.1767 12.8043 19.1523C12.8043 21.128 14.4444 22.7373 16.4579 22.7373H21.6848C23.7115 22.7373 25.3383 24.3466 25.3383 26.3222C25.3383 28.3109 23.6982 29.9072 21.6848 29.9072H12.791"
          stroke="#030730"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.3524 35.5852C34.3524 38.9608 31.5523 41.7084 28.1121 41.7084H10.0313C6.59114 41.7084 3.79102 38.9608 3.79102 35.5852V9.91494C3.79102 6.53933 6.59114 3.79175 10.0313 3.79175H28.1121C31.5523 3.79175 34.3524 6.53933 34.3524 9.91494"
          stroke="#030730"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.7084 23.5605L31.668 23.5605"
          stroke="#030730"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.6949 28.4946L31.668 23.5621L36.6949 18.6426"
          stroke="#030730"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

ToChargeSuccess.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ToChargeSuccess.displayName = 'ToChargeSuccess';

export default ToChargeSuccess;
