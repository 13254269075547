import styled, { css } from 'styled-components';

import Box, { BaseBoxProps } from '../Box/Box';

type FlexContainerProps = BaseBoxProps &
  Pick<
    React.CSSProperties,
    | 'justifyContent'
    | 'alignItems'
    | 'flexDirection'
    | 'flexWrap'
    | 'flexFlow'
    | 'alignContent'
    | 'gap'
    | 'flexGrow'
    | 'width'
  > & {
    /**
     * Occupy the full width of the container
     * @default false
     */
    fullWidth?: boolean;
    /**
     * Occupy the full height of the container
     * @default false
     */
    fullHeight?: boolean;
  };

const FlexContainer = styled(Box)<FlexContainerProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? ''};
  align-items: ${({ alignItems }) => alignItems ?? ''};
  flex-direction: ${({ flexDirection }) => flexDirection ?? ''};
  flex-wrap: ${({ flexWrap }) => flexWrap ?? ''};
  flex-flow: ${({ flexFlow }) => flexFlow ?? ''};
  align-content: ${({ alignContent }) => alignContent ?? ''};
  gap: ${({ gap }) => gap ?? ''};
`;

export default FlexContainer;
