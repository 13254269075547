import Router from 'next/router';
import qs from 'qs';
import { onError } from '@apollo/client/link/error';

import { remove as logOut } from '@src/lib/auth';
import { log as logError, logMessage } from '@src/lib/errorReporter';

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (
    networkError &&
    'response' in networkError &&
    typeof window !== 'undefined'
  ) {
    switch (networkError?.response.status) {
      case 401:
        logOut();
        Router.replace('/p/login');
        break;
      case 403:
        logError(networkError);
        Router.replace('/403');
        break;
      case 500:
        logError(networkError);
        const querystring = qs.stringify(
          {
            error: networkError.message,
          },
          {
            encode: false,
            arrayFormat: 'brackets',
          }
        );
        Router.replace(`/500/${querystring}`);
        break;
      default:
        break;
    }
  }

  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    graphQLErrors.forEach((error) => {
      logMessage(error.message);
    });
  }
});

export default errorLink;
