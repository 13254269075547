import { gql } from '@apollo/client';

export const WALLET_ENTRY_ATTRIBUTES = gql`
  fragment WalletEntryViewAttributes on WalletEntry {
    id
    hashId
    amount
    claimed
    createdAt
    receiptUrl
    providerContacts {
      id
      contactable {
        id
        email
      }
    }
    user {
      id
      firstName
      lastName
    }
    recipient {
      id
      clabe
      bankName
      recipient
    }
    entryable {
      __typename
      ... on DepositEntry {
        id
        trackingKey
        senderName
        senderBank
        senderNumberAccount
      }
      ... on RefundEntry {
        id
        refundable {
          __typename
          ... on InvoicePayment {
            id
            paymentType
            invoice {
              id
              businessReceiver {
                id
                name
                hashId
              }
              receiverCountryTaxPayerInfo {
                ... on MexicanTaxPayerInfo {
                  fiscalUuid
                  folio
                }
              }
            }
          }

          ... on STPPayment {
            id
            errorMessage
          }

          ... on BankingTransaction {
            id
          }
        }
      }
      ... on HigoPaymentSentEntry {
        id
        paymentConcept
        lastBusinessUserValidation {
          email
        }
        invoicePayment {
          paymentType
          id
          invoice {
            id
            fiscalIdentifier
            emitterTaxpayerIdentifier
            businessReceiver {
              id
              name
              hashId
            }
            businessEmitter {
              id
              name
              hashId
            }
            receiverCountryTaxPayerInfo {
              __typename
              ... on MexicanTaxPayerInfo {
                fiscalUuid
                folio
              }
            }
          }
        }
        financingRequestRepayment {
          id
        }
      }
      ... on HigoPaymentReceivedEntry {
        id
        paymentConcept
        invoicePayment {
          paymentType
          id
          invoice {
            id
            fiscalIdentifier
            emitterTaxpayerIdentifier
            emitterTaxpayerName
            receiverTaxpayerIdentifier
            receiverTaxpayerName
            businessReceiver {
              id
              name
              hashId
            }
            businessEmitter {
              id
              name
              hashId
            }
            receiverCountryTaxPayerInfo {
              __typename
              ... on MexicanTaxPayerInfo {
                fiscalUuid
                folio
              }
            }
          }
        }
      }
      ... on InvoicePaymentEntry {
        id
        trackingKey
        stpPayment {
          id
          errorMessage
          paymentConcept
          beneficiaryAccount
          beneficiaryBank {
            id
            name
          }
          beneficiaryName
        }
        invoicePayment {
          paymentType
          id
          invoice {
            id
            fiscalIdentifier
            businessReceiver {
              id
              name
              hashId
            }
            businessEmitter {
              id
              name
              hashId
            }
            receiverCountryTaxPayerInfo {
              __typename
              ... on MexicanTaxPayerInfo {
                fiscalUuid
                folio
              }
            }
          }
        }
      }
      ... on WithdrawalEntry {
        id
        trackingKey
      }
      ... on BankingTransaction {
        id
        status
        paymentConcept
        amountCents
        metadata
        trackingKey
        receivablePayments {
          id
          receivable {
            invoice {
              id
            }
          }
        }
        invoicePayments {
          id
          invoice {
            id
            businessReceiver {
              hashId
              name
            }
            businessEmitter {
              id
              name
              hashId
            }
            receiverCountryTaxPayerInfo {
              __typename
              ... on MexicanTaxPayerInfo {
                fiscalUuid
                folio
              }
            }
          }
        }
        receiverAccount {
          __typename
          id
          clabe
          taxId
          accountType
          bank {
            id
            clabe
            logoUrl
            name
          }
        }
        senderAccount {
          __typename
          id
          taxId
          clabe
          accountType
          bank {
            id
            clabe
            logoUrl
            name
          }
        }
      }
    }
    status
  }
`;
